/* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.right {
    /* background-color: rebeccapurple; */
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
}

/* .right>p{
    margin: 0;
}
.order_right{
    background-color: lightcoral;
} */

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter:hover {
    border-color: #4164E3;
}

.order_filter_dark {
    border-color: #1B1D21;
}

.order_filter_dark:hover {
    border-color: #656565;
}

.order_filter_active_dark {
    border-color: #656565;
}

.order_filter_active {
    border-color: #4164E3;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.order_data_list_row,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

.order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
}

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

.order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.loader_con {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 36vh;
}

.inp_div {
    position: relative;
    /* background-color: lightblue; */
}

.item_inp {
    border: none;
    outline: none;
    /* background-color: lightcoral; */
    background-color: transparent;
    color: #000;
    font-size: 12px;
    width: auto;
}

.item_inp_dark {
    color: #FFFFFF;
}

.desc,
.desc_mob {
    font-size: 12px;
    width: 100%;
    padding: 0 0.5rem;
}

.p_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.p_table_header_con {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.p_table_header,
.p_table_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.p_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.p_table_header_dark {
    color: #FFFFFF;
}

.p_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.p_table_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entry_2 {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item_no {
    flex: 6;
    overflow: visible;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0.5rem;
}

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_right {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.right_btns {
    display: flex;
    gap: 0.5rem;
}

.so_amount_info {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 340px;
}

.so_amount_info_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_amount_info>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.so_amount_info>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_check>p {
    margin: 0;
    line-height: 1;
}

.so_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}

.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.element_2 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.element_2 p {
    margin: 0;
    font-size: 14px;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}

.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item  */
.search_item {
    position: absolute;
    top: 150%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    color: #000;
    padding: 0.3rem 0;
    z-index: 2;
    width: 480px;
}

.search_item_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.search_item_header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 10px;
    border-bottom: 1px solid #E4E7EB;
}

.search_item_header>p {
    margin: 0;
    cursor: pointer;
}

.search_item_header>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.item_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.i_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.i_table_header_con {
    display: flex;
    align-items: center;
}

.i_table_row,
.i_table_header {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    width: 100%;
}

.i_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.i_table_header_dark {
    color: #FFFFFF;
}

.i_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.i_table_list {
    display: flex;
    flex-direction: column;
    max-height: 160px;
    overflow: hidden;
    overflow-y: auto;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
    color: #FFFFFF;
}

/* shipping charges modal  */
.shipping_charges_modal {
    padding: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 0px 4px 50px 0px #00000026;
}

.shipping_charges_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.shipping_charges_modal>img {
    margin: 1rem auto;
}

.shipping_charges_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

.shipping_charges_modal>div {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
}

.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* refund receipt */
.refund_receipt {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
}

.add_reciept_modal>.refund_receipt>p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #646b88;
}

.refund_receipt>.textarea {
    font-size: 12px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
}

.refund_receipt>.textarea::placeholder {
    color: #9f9f9f;
}

.refund_receipt>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

/* iocn action text */
.icon_action_text {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.icon_action_text>svg {
    height: 18px;
    width: 18px;
}

.icon_action_text>p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1;
}

/* responsive */
.order_dd_mob {
    display: none;
    width: 8rem;
}

.show_760px {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #E4E7EB;
}

.show_760px_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
    border-color: #1B1D21;
}

.show_760px_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_1>p {
    margin: 0;
    font-size: 12px;
    color: #646B88;
}

.show_760px_1>span {
    font-weight: 700;
}

.show_760px_2 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.show_760px_2>div>p {
    margin: 0;
    font-size: 12px;
}

.show_760px_2,
.show_760px_3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_3 {
    gap: 10%;
}

.show_760px_3>.element {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.show_760px>.show_760px_3>.element>div {
    background-color: #F8F9FC;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
}

.show_760px_3>.element>p,
.show_760px_3>.element>div>p {
    margin: 0;
}

.show_760px_3>.element>div>p {
    font-size: 12px;
}

.show_760px_3>.element>p {
    font-size: 12px;
}

.show_760px_4 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    font-size: 12px;
}

.show_1150px,
.show_760px {
    display: none;
}

/* all order responsive */
.sale_order_mob {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 0 0.5rem;
    border-radius: 8px;
    display: none;
}

.sale_order_mob_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F3F6F8;
    padding: 0.5rem 0;
}

.sale_order_mob_div_dark {
    border-color: #2C2E33;
}

@media (width<1350px) {
    .so_footer {
        padding: 4px;
    }

    .so_footer_left {
        gap: 1px;
    }

    .so_receipt_data {
        width: 340px;
    }

    .add_receipt_btn {
        padding: 0.1rem;
    }

    .so_footer_right,
    .right_btns {
        gap: 2px;
    }

    .so_amount_info {
        width: 280px;
    }
}

@media (width<1150px) {
    .right_btns {
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
    }

    .so_footer_right {
        gap: 0.5rem;
    }

    .right_btns>.hide_1150px {
        display: none;
    }

    .right_btns>.show_1150px {
        display: block;
    }
}

@media (width<1200px) {
    .order_sf {
        display: none;
    }

    .order_dd_mob {
        display: block;
    }
}

@media (width<890px) {
    .so_form {
        gap: 10px;
        flex-direction: column;
    }

    .so_footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .so_footer_left {
        width: 100%;
        justify-content: center;
    }

    .so_footer_right {
        width: 100%;
        justify-content: center;
    }

    .p_table_header,
    .hide_760px {
        display: none;
    }

    .show_760px {
        display: flex;
    }

    .so_instc {
        display: none;
    }

    .search_item {
        left: -7%;
        top: -1000%;
    }

    .i_table_list {
        max-height: 100px;
    }

    .dealer_address_modal {
        max-width: 340px;
    }

    .address_Pop_Up>img {
        height: 10rem;
    }

    .dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
        font-size: 14px;
        width: 90%;
        font-weight: 400;
    }

    .dealer_address_modal>.address_Pop_Up>p {
        font-weight: 300;
        font-size: 12px;
    }

    .btn_text {
        display: none;
    }

    .so_scroll_wrapper {
        overflow: auto;
        padding-bottom: 0;
    }

    .desc {
        display: none;
    }
}

@media (width<700px) {
    .order {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        background: #f8f9fc;
        overflow: auto;
    }

    .order_top_left {
        display: none;
    }

    .order_right {
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
    }

    .so_footer {
        position: fixed;
    }
}

@media (width<400px) {
    .so_footer_right {
        flex-direction: column;
        align-items: center;
    }

    .right_btns {
        flex-direction: row;
        gap: 1rem;
    }

    .so_amount_info {
        width: 100%;
    }

    .next_btn {
        width: 100%;
    }
}

/* all orders */
@media (width<1000px) {

    .order_data_list_row_header,
    .order_data_list_row {
        display: none;
    }

    .sale_order_mob {
        display: flex;
    }

    .order_data_list_container {
        gap: 10px;
    }
}