.create_tem_footer_btn_conitner {
    width: 100%;
    margin-top: 30px;
}

.create_tem_footer_btn {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
}

.create_tem_footer_btn_head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid; */
    padding: 8px 0px;

}

.create_tem_footer_btn_TextAndSelect {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 3%;
    margin-top: 10px;
    margin-bottom: 10px;
    /* border: 1px solid; */
}
.can{
    width: 100%;
}

.create_tem_footer_btn_Textcon {
    width: 50%;
}

.continer_dropdown {
    width: 200px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99;
    top: 25px;
    left: 10px;
    /* text-align: center; */
    border-radius: 6px;
    padding-left: 14px;
  
}

.continer_dropdown span {
    cursor: pointer;
    padding-top: 10px;
    font-size: 14px;
}

.spanContiner:hover {
    color: #4164E3;
}

.coll_continer {
    width: 100%;
    /* border: 1px solid; */
    padding: 8px;
    margin-top: 20px;
}

.dropdownVisible {
    max-height: 200px;
    opacity: 1;
}

.create_tem_footer_btn_dropdown {
    position: relative;
  
}

.create_tem_footer_btn_drown_togle {
    cursor: pointer;
    padding-left: 10px;
    padding: 5px 0px;
    margin: 0px 20px;
 
}
.deleteBtn{
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #F93E3E;
    color: #e0e0e0;
    cursor: pointer;
}
.addBtn{
    width: 110px;
    float: right;
    margin: 8px 20px ;
    border-radius: 6px;
    background-color: #4164E3;
    padding: 6px 10px;
    color: #e0e0e0;
    cursor: pointer;
}
.countText{
    float: right;
    font-size: 12px;
    font-weight: 600;
}
.error{
    float: right;
    font-size: 13px;
    color: #F93E3E;
}
.addNewButton{
    width: 140px;
    height: 35px;
    /* border: 1px solid; */
    border-radius: 5px;
    text-align: center;
    background-color: #4164E3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    margin: 10px 10px 30px 10px;
    float: right;
}