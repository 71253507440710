.bg_white {
    background-color: #fff;
    width: 100%;
    font-size: 11px;
}

.bg_dark {
    background-color: #000000;
    color: #fff;
    width: 100%;
}

.marketingMain {
    padding: 10px;
    /* max-width: 599px; */
    margin: 50px auto;

}

.main_heading {
    font-weight: 500;
    /* font-size: 24px; */
    line-height: 30px;
    margin-bottom: 20px;
}

.flexmaindiv {
    display: flex;
    justify-content: space-between;
}

.img {
    padding-right: 30px;
}

.maintext {
    margin: 5px;
}

.card_div {
    border: 0.608031px solid #E5E5E5;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-top: 10px;
}

.dispatchModalBtm {
    position: 'sticky';
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #E5E5E5;
}

.dispatchModalBtm button {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.courierSlip {
    position: relative;
    border: 1px dashed #cbd5e1;
    padding: 5px;
    border-radius: 5px;
    border-width: 2px;
}

.courierSlip label {
    position: absolute;
    right: 5px;
    top: 5px;
    color: red;
    font-weight: bold;
    border: 1px solid;
    border-radius: 50%;
    padding: 0px 7px;
    background: #fff;
    z-index: 1099;
    cursor: pointer;
}

.flexdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    /* font-size: 15px; */
    margin-top: 8px;
}

.flexdiv1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-size: 13px; */
}

.flexdiv img {
    width: 50px;
    height: 60px;
    margin-right: 10px;
}

.flexdivprint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3px;
}

.card_heading {
    font-weight: 500;
    /* font-size: 20px; */
    line-height: 20px;
    margin: 0;
}

.subtext {
    font-weight: 400;
    /* font-size: 14px; */
    line-height: 20px;
    margin: 0;
}

/* ---------------tab--------------------- */
.tabs_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0 1rem 0;
    /* width: 100%; */
    /* height: 25px; */
    background: rgba(0, 0, 0, 0.02);
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    padding: 5px;
}

.tab {
    width: 90px;
    font-style: normal;
    font-weight: 500;
    /* font-size: 13px; */
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 10px 0;
    border-radius: 30px;
    cursor: pointer;
}

.tab_active {
    background-color: #000000;
    color: #FFFFFF;
}

.slipDetailsDataSelect {
    width: 50%;
}

.cencelbtn {
    font-size: 11px;
    padding: 12px 4px;
    max-width: 60px !important;
    min-width: 104px !important;
}

.greenOutbtn {
    font-size: 11px;
    padding: 12px 4px;
    max-width: 60px !important;
    min-width: 104px !important;
}

/* ------------------------order-------------------------- */

.marketing {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    /* border-radius: 3px; */
    /* padding: 10px; */
    /* max-width: 768px; */
    position: relative;
}

.status_height {
    height: 14px;
}

.dispatch_status {
    min-width: 90px;
}

.order_heading {
    /* font-size: 20px; */
    height: 32px;
    margin: 0;
    margin-top: 12px;
}

.printicon {
    text-align: center;
    cursor: pointer;
}

.print {
    font-weight: 300;
    /* font-size: 10px; */
    line-height: 5px;
    text-align: center;
    text-decoration-line: underline;
    margin: 5px 0 0 0;
}

.text {
    font-weight: 300;
    /* font-size: 16px; */
    line-height: 20px;
    letter-spacing: 0.05em;
    margin: 2px 0;
}

.order_text {
    min-width: 112px;
    line-height: 15px;
}

.details_text {
    font-weight: 500;
    /* font-size: 14px; */
    line-height: 26px;
    text-decoration-line: underline;
    /* margin: 5px 0; */
    color: #000;
}

.details_heading {
    font-weight: 300;
    /* font-size: 14px; */
    line-height: 14px;
    margin: 0;
}

.textright {
    float: right;
    width: 55%;
}

.order_details {
    margin: 5px 0;
    /* font-size: 14px; */
    font-weight: 300;
    line-height: 14px;
}

.order_flexdiv {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.flex {
    display: flex;
    margin-top: 10px;
    padding: 5px 6px;
    border-radius: 2px;
    align-items: center;
    background: #F3F6F8;
    /* justify-content: space-between; */
    border: 0.600604px solid #D3D3D3;
}

/* .flex img {
    width: 70px;
    height: 82px;
    margin-right: 10px;
} */

.flex p {
    margin: 0;
}

.flexMA {
    margin-right: auto;
    margin-left: 10px;
}


.flex_dispatch_detail {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 5px 6px;
    border-radius: 2px;
    align-items: center;
    background: #F3F6F8;
    /* justify-content: space-between; */
    border: 0.600604px solid #D3D3D3;
}

.text {
    /* font-size: 14px; */
    color: #3A3A3A;
    font-weight: 500;
    line-height: 18px;
}

.heading {
    margin: 5px 0;
    /* font-size: 14px; */
    color: #3A3A3A;
    font-weight: 400;
    line-height: 18px;

}

.input {
    width: 16%;
    height: 26px;
    outline: none;
    font-weight: bold;
    text-align: center;
    border: 0.5px solid #7A7A7A;
    border-radius: 3px;
}

.inputdiv {
    width: 100%;
    height: 40px;
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    outline: none;
    margin: 0 0 20px 0;
    color: #000;
    text-align: left;
    border: 1px solid #807f7f;
    padding: 0 10px;
    line-height: 40px;
}

.inputdiv option {
    color: #000;
    text-align: left;
}

.inputLabel {
    position: absolute;
    top: -12px;
    left: 5px;
    background: #F3F6F8;
    font-size: 14px;
    padding: 0 5px;
    color: #535252;
    border-radius: 5px;
}

.fileimg {
    /* Width: 100%; */
    Height: 100%;
    object-fit: cover;
    /* text-align: center; */
}

.fileimg_single {
    /* Width: ; */
    Height: 100%;
    object-fit: cover;
    /* text-align: center; */
    display: flex;
    margin: auto;
}

.crossIcon {
    position: absolute;
    right: 0;
    padding-right: 10px;
}

.crossIcon_css {
    /* position: absolute; */
    right: 0;
    /* padding-right: 10px; */
}


.btn1 {
    width: 40%;
    height: 40px;
    margin-top: 15px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    cursor: pointer;
}

.btn2 {
    width: 40%;
    height: 40px;
    border: none;
    margin-top: 15px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.main_div {
    /* font-size: 15px; */
    text-align: left;
}

.dragImage_div {
    width: 100%;
    height: 117px;
}



.label {
    width: 100%;
    cursor: pointer;
    /* font-size: 13px; */
    padding: 5px 2px;
    text-align: center;
    border-radius: 5px;
    border: 2px dashed #000000;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 117.89px;
    background: #D6ECFF;
}

.deleteicon {
    margin: 0;
    color: #F93E3E;
    font-weight: 400;
    /* font-size: 12px; */
}

.deleteicon_css {
    margin: 0;
    color: #F93E3E;
    font-weight: 400;
    font-size: 12px;
}

.deletediv {
    width: 40px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    border: 0.364683px solid #F93E3E;
}

.checkbox {
    width: 15px;
    height: 15px;
}

.modal_heading {
    text-align: center;
    /* font-size: 22px; */
    /* font-weight: 600; */
}

.modaltext {
    text-align: center;
    /* font-size: 15px; */
}

/*-------------------------------modalcss---------------------------- */
.leadlost {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    color: #000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
}

.lost_content {
    max-width: 540px;
    width: 100%;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 10px 10px 20px 10px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal_content {
    width: 100%;
    margin: auto;
    max-width: 540px;
    height: 250px;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_center {
    text-align: center;
}

/* @media screen and (max-width:425px) {
    .order_flexdiv {
        width: 85%;
        display: flex;
        justify-content: space-between;
    }

    .leadlost {
        margin-left: 0;
    }
} */
/* @media screen and (max-width:350px) {
    .packingDetails_txt {
        font-size: 11px;
    }
    .flexdiv1 {
font-size: 11px;
    }
} */

/* -----------------------------packingsilp------------------------------ */

.slipheading_div {
    font-weight: 500;
    line-height: 18px;
    color: #000;
    margin: 0;
    padding-left: 5px;
}

.slipmain_div {
    padding: 16px;
    border-top: 0;
    font-size: 17px;
}

.slip_input {
    width: 25%;
    height: 40px;
    outline: none;
    border-radius: 4px;
    border: 0.5px solid;

}

.slip_inputsecond {
    width: 60%;
    height: 45px;
    outline: none;
    border-radius: 4px;
    border: 0.5px solid;
}

.slipflexdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.page {
    background: var(--white);
    display: block;
    box-shadow: var(--pageShadow);
    width: 21cm;
    height: 31cm;
    overflow: hidden;
    position: relative;
    border-radius: 19.9728px;
    z-index: -9;
    padding: 0 20px;
}

.h10 {
    height: 10px !important;
}

.tw_center {
    text-align: -webkit-center;
}

.textstart {
    text-align: left;
    padding: 0 5px;
}

.text_center {
    /* font-size: 25px; */
    margin-top: 20px;
    text-align: center;
    margin-bottom: 10px;
    /* text-decoration-line: underline; */
}

.border_dark {
    width: 92%;
    border: black;
    margin-bottom: 0;
    border-collapse: collapse;
    border-bottom: none !important;
}

.padding {
    padding: 5px 10px;
    border-bottom: 0;
}

.border_table {
    width: 92%;
    height: 620px !important;
    border: #000;
    border-top: 0 !important;
    margin-bottom: 0;
    border-collapse: collapse;
}

.width {
    height: 10cm;
}

.border_top {
    border-top: none !important;
    text-align: center;
    height: 10px;
}

.padding_width {
    padding: 5px;
    width: 20px;
}

.border_none {
    border-top: none;
    border-bottom: none;
    text-align: -webkit-center;
    height: 10px;
}

.border_lasttable {
    /* border: 0 !important; */
    border-collapse: collapse;
    /* border-top: none !important; */
    width: 92%;
    height: 300px;
    /* margin-top: 20px; */
}

.labeltext_left {
    padding: 5px 10px;
}

.labelcenter {
    text-align: center;
    padding: 20px;
}

.name {
    margin: 0;
    /* font-size: 15px; */
}

.packingIcon {
    padding: 0 20px 0 10px;
}

.packingDetailDiv {
    width: 100%;
}

/* .payment_pending {
    border: 0;
    font-weight: 500;
    font-size: 70px;
    line-height: 70px;
    padding-left: 5rem;
    color: #000000;
    position: absolute;
    opacity: 0.2;
    transform: rotate(-26.56deg);
} */

/* @page {
    size: A4;
    margin: 0;
} */

.packingImage {
    width: 64px;
    height: 36px;
    border: 0.441703px solid #D6D6D6;
    padding: 3px;
}

.crossIcon {
    line-height: 36px;
    cursor: pointer;
}

.packTxt {
    /* font-size: 14px; */
    font-weight: 500;
}

.flexPacksdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;
}

.packingOrder {
    padding: 8px 10px 10px 10px;

}

.packingDetailsHeading {
    font-size: 15px;
    font-weight: 600;
    /* margin-top: 5px; */
    margin-bottom: 2px;
}

.modalMaindiv {
    text-align: center;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    /* width: 400px; */
    margin: auto;
    padding-bottom: 30px;
}

.message {
    text-align: center;
    margin: 30px 0;
    /* font-size: 24px; */
}

/* .packingDetails_div {
    font-size: 13px;
} */

.packingDetails_txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

.contactsDiv {
    display: flex;
    width: 44%;
    justify-content: flex-end;
    /* justify-content: space-between; */
    /* align-items: center; */
}

.detailsContact {
    /* line-height: 26px; */
    text-decoration-line: underline;
    color: #000;
}

.whatsappIcon {
    height: 13px;
    padding-bottom: 5px;
    padding-left: 2px;
}

.phoneIcon {
    display: inline;
}

.ordermanagerName {

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 1em !important;
    max-width: 180px;
}

.singleOrderStatus {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 5px;
    /* font-size: 13px; */
}

.status {
    color: #DDE100;
}

.status_blue {
    color: #2196F3;
}

.statuscolor1 {
    color: #FF0D0D;
}

.statuscolor3 {
    color: #05FF00;
}

.itemsScroll {
    position: relative;
    overflow-y: scroll;
    width: 100%;
    /* min-height: calc(100vh - 484px);
    max-height: calc(100vh - 484px); */
    height: 230px;
    border-bottom: 0.5px solid #D3D3D3;
    margin-top: 16px;
}

.itemsScroll::-webkit-scrollbar {
    display: none;
}

.ModalItemsScroll {
    position: relative;
    overflow-y: scroll;
    width: 100%;
    min-height: 255px;
    max-height: 255px;
}

.ModalItemsScroll::-webkit-scrollbar {
    display: none;
}

.packedAlert {
    text-align: center;
    font-weight: 600;
}

.loadmoreIocon {
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
}



.selectDispatchManager {
    text-align: right;
}

.selectManager {
    width: 40%;
    border: 1px solid #000000 !important;
    color: #000;
    height: 36px;
    border-radius: 4px;
}

.selectManager option {
    background-color: #000000;
    text-align: left;
    color: #FFFFFF;
}

.progressStatus_heading {
    font-weight: 500;
    /* font-size: 20px; */
    line-height: 30px;
    margin-bottom: 16px;
}



.select_packer {
    color: #000;
    text-align: left;
    border: 0.826924px solid #606060;
    border-radius: 2px;
    padding: 4px;
    /* font-size: 15px; */
    width: 56%;
    height: fit-content;
}

.progressStatus {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.progressStatus_tab {
    box-sizing: border-box;
    width: 76px;
    height: 76px;
    background: #FFFFFF;
    /* border: 0.306238px solid #D3D3D3; */
    border-radius: 3.15007px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.status_num {
    font-weight: 500;
    /* font-size: 30px; */
    line-height: 50px;
}

.status_txt {
    font-weight: bold;
    /* font-size: 12px; */
    line-height: 15px;
    text-transform: capitalize;
    color: #3A3A3A;
}

.progressStatus_yellow {
    color: #FFC776;
}

.progressStatus_red {
    color: #F56221;
}

.progressStatus_green {
    color: #1FFC33;
}

.checkBox_addImage {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 5px;
}

.NoImageIcon {
    box-sizing: border-box;
    background: #F3F6F8;
    border: 0.5px solid #D3D3D3;
    border-radius: 1px;
    height: 50px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.courierSlip_image {
    object-fit: cover;
}

.flex_dispatch_details {
    display: flex;
    /* margin-top: 10px; */
    padding: 5px 6px;
    border-radius: 2px;
    align-items: center;
    /* background: #F3F6F8;
    border: 0.600604px solid #D3D3D3; */
}

.dispatch_item_detail {
    display: flex;
    flex-direction: column;
    background: #F3F6F8;
    /* justify-content: space-between; */
    border: 0.600604px solid #D3D3D3;
    /* margin-top: 10px; */
    padding: 5px 6px;
    border-radius: 2px;
    margin-bottom: 10px;
}

.dispatchItemBox_image {
    max-height: 120px;
    object-fit: cover;
    border-radius: 7px;
}

.Delivery_details_slip {
    background: #F3F6F8;
    border: 0.600604px solid #D3D3D3;
    max-height: 120px;
    object-fit: cover;
    border-radius: 7px;
    margin: 16px 0 0 0;
    padding: 10px;
    width: 100%;
}

.main_pack_image {
    max-height: 120px;
    object-fit: cover;
    border-radius: 7px;
    margin: 16px 0 0 0;
}

.dispatch_update_btn {
    background: #000000;
    border: 1px solid #000000;
    color: #FFFFFF;
    line-height: 36px;
    padding: 0 32px;
    border-radius: 4px;
    font-weight: 600;
}

.dispatch_back_btn {
    background: #FFFFFF;
    border: 1px solid #000000;
    color: #000000;
    line-height: 36px;
    padding: 0 32px;
    border-radius: 4px;
    font-weight: 600;
}

.viewDetail_btn {
    font-size: 14px;
    color: #3482CA;
    font-weight: 600;
}

.bannerAddBtn {
    /* border: 1px solid #1FFC33; */
    width: 'fit-content';
    padding: 11px 2px;
    text-align: center;
    border-radius: 5px;
    font-size: 10px;
}

.itemImg {
    width: 100px !important;
    height: 64px !important;
    margin-right: 0 !important;
}

.bannerImgInput {
    display: none;
}

.thumbnail_itemImage {
    width: 55px;
    min-width: 50px;
    max-width: 50px;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    border: 0.5px solid #D3D3D3;
    border-radius: 6px;
}

.dispatch_main {
    position: relative;
    height: calc(100vh - 108px);
    padding: 5px 8px;
}

.dispatch_scroll {
    overflow-y: scroll;
    min-height: calc(100vh - 210px);
    max-height: calc(100vh - 210px);
    padding-bottom: 10px;
}

.dispatch_scroll::-webkit-scrollbar {
    display: none;
}

.header_main {
    font-size: 12px;
    display: flex;
    justify-content: space-between;

}

.orderId_date {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 8px;
}

.items_div {
    position: relative;
    /* font-size: 12px; */
    /* overflow-y: scroll; */
    width: 100%;
    /* min-height: calc(100vh - 484px);
    max-height: calc(100vh - 484px); */
    /* height: 230px; */
    /* border-bottom: 0.5px solid #D3D3D3; */
    /* margin-top: 16px; */
}

.itemCard {
    display: flex;
    margin-top: 8px;
    padding: 5px 6px;
    border-radius: 6px;
    align-items: center;
    font-size: 14px;
    /* background: #F3F6F8; */
    /* justify-content: space-between; */
    border: 0.8px solid rgba(211, 211, 211, 1)
}

.productName {
    color: rgba(181, 181, 181, 1);
    margin-left: 10px;
    font-weight: 600;
}

.warehouseName {
    color: rgba(181, 181, 181, 1);
}

.contactCard {
    border-radius: 6px;
    border: 0.8px solid rgba(211, 211, 211, 1);
    margin-top: 8px;
    padding: 5px 6px;
}

.diliveryCard {
    border-radius: 6px;
    border: 0.8px solid rgba(211, 211, 211, 1);
    margin-top: 8px;
    padding: 5px 6px;
    font-size: 13px;
}

.contact_details {
    font-size: 14px;
    padding-top: 2px;
}

.contact_details_icon {
    min-width: 36px;
    max-width: 36px;
    text-align: center;
}

.dispatchFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 8px 12px;
    box-shadow: 0px 0px 1.74558px 0.872789px rgba(0, 0, 0, 0.16);
}

.dispatchFooter_button {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.reqIssue {
    background-color: transparent;
    border: 0.5px solid #000;
    width: 85%;
    padding: 0px 5px;
    border-radius: 3px;
    color: #000;
    margin: 0px auto 20px;
    display: flex;
    margin-bottom: 20px;
    outline: none;
}
.itemCard_clip_price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}
.accessories_heading {
    font-size: 13px;
    margin: 5px 0;
    text-align: left;
    width: 100%;
    font-weight: 400;
}

@media (prefers-reduced-motion: no-preference) {
    .loadmoreIocon {
        animation: loadmoreIocon-spin infinite 1s linear;
    }
}

@keyframes loadmoreIocon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}