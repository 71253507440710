.Card_main {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    gap: 5px;
}

.Image_card_continer {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.Image_card_continer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;

}

.Image_card_continer:hover {
    transform: scale(1.05);
}
.card_Status{
    /* border: 1px solid; */
    display: flex;
    /* padding: 10px 0px; */
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 500;
}
.Card_text{
    font-size: 16px;
    font-weight: 600;
}
.Icon_continer{
    display: flex;
    /* padding: 10px 0px; */
    gap: 10px;
}
.Icon_continer div {
    cursor: pointer;
}
.icon_ContinerIs{
    border-radius: 5px;
    padding: 5px;
}
