.dispatch {
  height: calc(100vh - 80px);
  width: calc(100% - 20px);
  margin: 10px auto;
  background-color: #f8f9fc;
  border: 1px solid #e4e7eb;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dispatch_dark {
  background-color: #2C2E33;
  border-color: #2C2E33;
}

.dispatch_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dispatch_top p {
  margin: 0;
}

.dispatch_top_left {
  display: flex;
  align-items: center;
  justify-content:  space-around;
  gap: 10px;
  position: relative;
  top: -10px;
  margin-top: 15px;
  padding-top: 0;
  padding: 2px;
}
.mgx_top_left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
}
.mgx_top_left_go_back_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 0.3rem;
}
.mgx_top_left_path_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin-top: 5px;
}
.dispatch_top_left_go_back {
  display: flex;
  cursor: pointer;
}
.dispatch_go_back_text.lightMode {
  color: #4164E3;
}

.dispatch_go_back_text.darkMode {
  color: #749AFF;
}
.mgx_top_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dispatch_top_left_path_text {
  /* Add any common styles here if needed */
}

.dispatch_top_left_path_text.lightMode {
  color: #646B88;
}

.dispatch_top_left_path_text.darkMode {
  color: #9C9797;
}
.iconLightMode {
  color: #4164E3;
}

.iconDarkMode {
  color: #749AFF;
}

.mgx_top_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dispatch_go_back_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  margin-top: 4px;
  /* padding-top: 0.21rem; */
}

.dispatch_top_left_path_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  margin: 0;
  color: #646B88;
  /* margin-top: 9px; */
}

.dispatch_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dispatch_filters {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #e4e7eb;
  width: 100%;
}

.dispatch_filters_dark {
  background-color: #232529;
  border-color: #232529;
}

.dispatch_filter_card {
  min-width: 150px;
  height: 110px;
}

.dispatch_data {
  background-color: transparent;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #e4e7eb;
  width: 100%;
  overflow: hidden;
}

.dispatch_data_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dispatch_data_filters>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dispatch_data_search {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid #E4E7EB;
  border-radius: 10px;
}

.dispatch_data_search_dark {
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.dispatch_data_list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.dispatch_item_header {
  font-weight: 500;
  background-color: #D9DCE5;
  color: #646B88;
}

.dispatch_item_header_dark {
  background-color: #232329;
  color: #fafafa;
}

.dispatch_data_list_item {
  letter-spacing: 0.03em;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 5px;
}

.dispatch_item_value {
  background-color: #fff;
  color: #000;
}

.dispatch_item_value_dark {
  background-color: #1b1b1b;
  color: #fafafa;
}

.dispatch_data_list_container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.col {
  flex: 1;
}

.test_top p {
  margin: 0;
}

.test_middle {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #e4e7eb;
}

.test_bottom {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #e4e7eb;
}

.test_bottom_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test_bottom_top_right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.test_bottom_bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
  max-height: 460px;
  width: 100%;
  justify-content: center;
}

.test_bottom_bottom::-webkit-scrollbar {
  display: none;
}


@media (max-width: 700px) {
  .test {
    height: 100vh;
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 0;
    background: #f8f9fc;
    overflow: auto;
    padding: 0;
  }

  .test_top,
  .test_middle {
    border-radius: 0;
    border: none;
  }
}











/* Hide the .dispatch_top_left div for screens between 350px and 700px */
@media (min-width: 350px) and (max-width: 700px) {
  .dispatch_top_left {
    display: none; /* Hide the div within this screen width range */
  }
}