/* .toast {
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 16px;
    color: white;
    background-color: #333;
    border-radius: 8px;
    font-size: 14px;
    z-index: 999;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .success {
    background-color: #28a745;
  }
  
  .error {
    background-color: #dc3545;
  }
  
  .info {
    background-color: #17a2b8;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  




.toast1 {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.5s ease; 
    opacity: 1; 
    margin-top: 10px; 

}

.toast1.hide {
    opacity: 0; 
}

.toast {
    position: fixed;
    top: 20px;
    right: 20px; 
    left: 20px;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.5s ease;
    height: 40px;
    width: auto;
    min-width: 100px;
    width: auto;
    width: 100%;
    align-self: center;
    flex-direction: column;
    opacity: 1;
    margin: auto;
    margin-top: 10px; 
   
}

.success {
    background-color: #4caf50;
}

.error {
    background-color: #ff1100;
}

.info {
    background-color: #17a2b8; 
}
.warning {
    background-color: rgb(255, 255, 0);
}

.hide {
    opacity: 0;
    pointer-events: none;
}

   */



.toastContainer {
    position: absolute;
    top: 5%;
    /* right: 20px;  */
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* width: 100%; */
    /* margin: 0 auto; */
    /* background-color: rebeccapurple; */


    left: 50%;
    /* top: 50%; */
    transform: translate(-50%, -50%);
}

.toast {
    padding: 10px;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.5s ease;
    opacity: 1;
    min-width: 100px;
    min-width: 100px;
    width: auto;
    animation: fadeIn 0.3s ease forwards;
}

/* Toast types */
.success {
    background-color: #4caf50;
    /* Green for success */
}

.error {
    background-color: #ff1100;
    /* Red for error */
}

.info {
    background-color: #4164E3;
    /* Blue for info */
}

.warning {
    background-color: #ff960c
        /* Yellow for warning */
}

.hide {
    opacity: 0;
    pointer-events: none;
    /* Prevent interaction when hidden */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
        /* Slide up effect */
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}