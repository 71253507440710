                          .mainCintner {
                             width: 100%;
                             height: auto;
                             padding: 10px;
                             height: calc(100vh - 60px) !important;
                         }

                         .Continer {
                             width: 100%;
                             height: 100%;
                             /* padding: 10px 10px 0px 10px; */
                             padding: 10px;
                             position: relative;
                             display: flex;
                             flex-direction: column;
                         }

                         .goBackCon {
                             width: 100%;
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                             margin: 0px;
                         }

                         .gobacFristChlid {
                             width: 100%;
                         }

                         .gobacFristChlid1 {
                             width: 100%;
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                             /* flex-direction: column; */
                         }

                         .goBackScandChild {
                             width: 100%;
                             display: flex;
                             gap: 10px;
                             justify-content: flex-end;
                         }

                         .goBackBtnCon {
                             max-width: 120px;
                         }

                         .sarchBarConinnter {
                             width: 100%;
                             margin-top: 20px;
                             display: flex;
                             justify-content: center;
                             align-items: center;
                             /* height: 7%; */
                             /* border: 1px solid; */
                         }

                         .sarchBarConinnter1 {
                             width: 100%;
                             /* display: flex; */
                             flex-direction: column;
                             justify-content: center;
                             align-items: center;
                             overflow-y: scroll;
                             /* border: 1px solid green; */
                         }

                         .sarchBarConinnterFristChild {
                             width: 100%;
                             display: flex;
                             gap: 10px;
                             height: 100%;
                             align-items: center;
                             /* border: 1px solid; */

                         }

                         .sarchBarConinnterFristChild1 {
                             width: 100%;
                             display: flex;
                             gap: 10px;
                             align-items: center;
                             flex-direction: column;
                             /* justify-content: center; */
                             /* border: 1px solid blue; */

                         }

                         .sarchBarConinnterSecandtChild {
                             width: 40%;
                             display: flex;
                             justify-content: flex-end;
                         }

                         .sarchcon {
                             width: 40%;
                             height: 100%;
                             margin-top: 5px;
                         }

                         .sarchcon1 {
                             width: 20%;
                             height: 100%;
                             /* border: 1px solid; */
                         }

                         .sarchconGrop {
                             width: 100%;
                             display: flex;
                             align-items: center;
                             /* justify-content: flex-start; */
                             gap: 10px;
                             /* justify-content: flex-start; */
                             /* border: 1px solid; */
                         }

                         .sarchconGrop2 {
                             width: 100%;
                             display: flex;
                             gap: 1.9vh;

                         }

                         .sacrchGroup {
                             /* width: 100%; */
                             display: flex;
                             justify-content: space-between;
                         }

                         .groupFilter {
                             width: 100%;
                             display: flex;
                             gap: 6px;
                             justify-content: flex-end;
                         }



                         .AroBtn {
                             font-size: 20px;
                             width: 3%;
                             border-radius: 5px;
                             padding: 7px;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                         }

                         .sarchcon3 {
                             width: 40%;
                             height: 100%;
                             display: flex;
                             justify-content: flex-end;
                             gap: 10px;
                             /* border: "1px solid"; */
                         }

                         .container {
                             width: 100%;
                             /* overflow-x: scroll; */
                             margin-top: 20px;
                             /* border: 1px solid; */
                         }

                         .tableContiner {
                             width: 100%;
                             height: 80%;
                             overflow: hidden;
                         }

                         .tableContiner2 {
                             width: 100%;
                             height: 100%;
                             overflow: hidden;
                         }

                         .tableContinerTable {
                             width: 100%;
                             height: 100%;
                             overflow: hidden;
                         }

                         .tableContiner1 {
                             width: 100%;
                             height: 71.5%;
                             /* overflow-y: scroll; */
                             margin-top: 5px;
                         }

                         .responsiveTable {
                             list-style-type: none;
                             padding: 0;

                             .tableHeader {
                                 font-size: 14px;
                                 text-transform: uppercase;
                                 letter-spacing: 0.03em;
                                 display: flex;
                                 border-radius: 3px;
                                 padding: 13px 9px;
                                 margin-bottom: 10px;
                             }

                             .tablerow {
                                 background-color: #ffffff;
                                 box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
                                 display: flex;
                                 border-radius: 3px;
                                 padding: 10px;
                                 margin-bottom: 10px;
                                 align-items: center;
                                 /* cursor: pointer; */
                                 position: relative;
                                 /* overflow-y: scroll */

                             }

                             .col {
                                 flex: 1;
                                 padding: 0;
                                 margin: 0;
                                 font-size: 12px;
                                 font-weight: 400;
                                 line-height: 1;
                                 align-items: center;
                                 display: flex;
                                 /* border: 1px solid; */
                             }

                             .col0 {
                                 flex: 1/4;
                                 padding: 0;
                                 margin: 0;
                                 font-size: 12px;
                                 font-weight: 400;
                                 line-height: 1;
                                 align-items: center;
                                 display: flex;
                                 /* border: 1px solid; */
                             }

                             .coljustyfy {
                                 flex: 1;
                                 /* align-items: center;
                 justify-content: center;
                 display: flex; */
                             }

                             .large {
                                 flex: 2;
                                 /* pehle box ko bada karne ke liye */
                             }

                             .uperCase {
                                 text-transform: uppercase;
                             }

                             @media all and (max-width: 700px) {
                                 .tableHeader {
                                     display: none;
                                 }

                                 .TableHeadChackBox {
                                     width: 20px;
                                     height: 20px;
                                     border-radius: 5px;
                                 }

                                 .tablerow {
                                     display: block;
                                     display: flex;
                                     flex-direction: column;
                                     width: 100%;
                                     justify-content: center;
                                     border-radius: 5px;
                                 }

                                 .col {
                                     /* display: flex; */
                                     /* justify-content: space-between; */
                                     /* padding: 10px 0; */
                                     display: flex;
                                     justify-content: space-between;
                                     padding: 10px 0;

                                     &:before {
                                         color: #6C7A89;
                                         padding-right: 10px;
                                         content: attr(data-label);
                                         flex-basis: 50%;
                                         text-align: left;
                                     }
                                 }
                             }

                         }


                         .threeDot {
                             /* margin-left: 3vw; */
                             cursor: pointer;
                             align-self: center;
                             /* padding: 2px; */
                             width: 25%;
                             /* border: 1px solid; */
                             display: flex;
                             justify-content: center;
                             position: relative;
                         }

                         /* .threedotSpan{
    position: absolute;
    border: 1px solid;
    z-index: 999;
    background: #000;
} */


                         .subBox {
                             width: 10.5rem;
                             z-index: 10;
                             /* right: 95px; */
                             border-radius: 4px;
                             opacity: 0;
                             transform: translateY(-10px);
                             transition: opacity 0.5s ease transform 0.3s ease;
                             display: flex;
                             flex-direction: column;
                             align-items: flex-start;
                             justify-content: center;
                         }

                         .subBox1 {
                             /* width: 10.5rem; */
                             z-index: 10;
                             padding: 10px;
                             /* right: 95px; */
                             border-radius: 4px;
                             opacity: 0;
                             transform: translateY(-10px);
                             transition: opacity 0.5s ease transform 0.3s ease;
                             display: flex;
                             flex-direction: column;
                             align-items: flex-start;
                             justify-content: center;
                             box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
                         }

                         @keyframes slideIn {
                             0% {
                                 opacity: 0;
                                 transform: translateY(-20px);
                             }

                             100% {
                                 opacity: 1;
                                 transform: translateY(0);
                             }
                         }

                         /* When the subBox is active */
                         .subBox.active {
                             animation: slideIn 0.5s forwards;
                             /* Animation on active */
                         }

                         .subBox1.active {
                             animation: slideIn 0.5s forwards;
                             /* Animation on active */
                         }

                         /* Arrow Styles */
                         .subBox::before {
                             content: '';
                             position: absolute;
                             top: 37%;
                             right: -22px;
                             border-width: 10px;
                             border-style: solid;
                             rotate: 90deg;
                             border-width: 11px;
                             border-color: transparent transparent rgb(85, 85, 82) transparent;

                         }

                         /* .subBox1::before {
       content: '';
       position: absolute;
       top: 37%;
       right: -22px;
       border-width: 10px;
       border-style: solid;
       rotate: 90deg;
       border-width: 11px;
       border-color: transparent transparent rgb(85, 85, 82) transparent;
   } */

                         .subBox.active.dark {
                             background-color: #000000;
                             border: 1px solid #ffffff;
                         }

                         .subBox1.active.dark {
                             background-color: #000000;
                             border: 1px solid #ffffff;
                         }

                         .subBox.active.dark::before {
                             transform: rotate(150deg);
                             border-color: transparent transparent #000000 transparent;
                         }

                         .subBox1.active.dark::before {
                             transform: rotate(150deg);
                             border-color: transparent transparent #000000 transparent;
                         }

                         .sub_box_tab {
                             width: 100%;
                             padding-left: 8px;
                             /* box-sizing: border-box; */
                             cursor: pointer;
                             height: 2.5vh;
                             /* border: 1px solid; */
                         }

                         .sub_box_tab1 {
                             width: 100%;
                             /* margin: 10px 0 0 10px; */
                             /* box-sizing: border-box; */
                             cursor: pointer;
                             /* border: 1px solid; */
                             /* margin-top: 5px; */
                             padding: 10px;

                         }

                         .sub_box_tab1_light {
                             width: 100%;
                             /* margin: 10px 0 0 10px; */
                             /* box-sizing: border-box; */
                             cursor: pointer;
                             /* border: 1px solid; */
                             /* margin-top: 5px; */
                             padding: 10px;
                         }

                         .sub_box_tab span {
                             font-size: 14px;
                             line-height: 0px;
                             margin-top: 0px;
                             /* margin-left: 5px; */
                         }

                         .sub_box_tab1 span {
                             font-size: 14px;
                             line-height: 0px;
                             margin-top: 0px;
                             /* margin-left: 5px; */
                         }

                         .sub_box_tab1_light span {
                             font-size: 14px;
                             line-height: 0px;
                             margin-top: 0px;
                             margin-left: 10px;
                         }

                         .sub_box_tab:hover {
                             color: #2196F3;
                         }

                         .sub_box_tab:hover span {
                             color: #2196F3;
                         }

                         .sub_box_tab1:hover {
                             /* color: #2196F3; */
                             background-color: #000;
                             /* padding: 3px; */
                             border-radius: 6px;

                         }

                         .sub_box_tab1_light:hover {
                             /* color: #2196F3; */
                             background-color: #F8F9FC;
                             /* padding: 3px; */
                             border-radius: 6px;

                         }


                         .containermode {
                             width: 90%;
                         }

                         .TableHeadChackBox {
                             width: 25px;
                             height: 25px;
                             /* border: 1px solid; */
                             border-radius: 5px;
                             float: left;
                             margin-right: 9px;
                         }

                         .cl_checkbox {
                             margin-left: 5px;
                         }

                         .inputTypeckack {
                             width: 100%;
                             height: 100%;
                             border: 1px solid #ccc;
                             outline: none;
                             cursor: pointer;
                             border-radius: 8px;
                         }





                         /* From Uiverse.io by lenin55 */
                         .cl_checkbox {
                             position: relative;
                             display: inline-block;
                         }

                         /* Input */
                         .cl_checkbox>input {
                             appearance: none;
                             -moz-appearance: none;
                             -webkit-appearance: none;
                             z-index: -1;
                             position: absolute;
                             left: -10px;
                             top: -8px;
                             display: block;
                             margin: 0;
                             border-radius: 50%;
                             width: 40px;
                             height: 40px;
                             /* background-color: rgba(0, 0, 0, 0.6); */
                             box-shadow: none;
                             outline: none;
                             opacity: 0;
                             transform: scale(1);
                             pointer-events: none;
                             transition: opacity 0.5s, transform 0.2s;
                         }

                         /* Span */
                         .cl_checkbox>span {
                             display: inline-block;
                             width: 100%;
                             cursor: pointer;
                         }

                         /* Box */
                         .cl_checkbox>span::before {
                             content: "";
                             display: inline-block;
                             box-sizing: border-box;
                             margin: 2px 11px 3px 0px;
                             border: solid 2px;
                             /* Safari */
                             border-color: #0065FF;
                             border-radius: 5px;
                             width: 18px;
                             height: 18px;
                             vertical-align: top;
                             transition: border-color 0.2s, background-color 0.2s;
                         }

                         /* Checkmark */
                         .cl_checkbox>span::after {
                             content: "";
                             display: block;
                             position: absolute;
                             top: 3px;
                             left: 1px;
                             width: 10px;
                             height: 5px;
                             border: solid 2px transparent;
                             border-right: none;
                             border-top: none;
                             transform: translate(3px, 4px) rotate(-45deg);
                         }

                         /* Checked, Indeterminate */
                         .cl_checkbox>input:checked,
                         .cl_checkbox>input:indeterminate {
                             background-color: #0065FF;
                         }

                         .cl_checkbox>input:checked+span::before,
                         .cl_checkbox>input:indeterminate+span::before {
                             border-color: #0065FF;
                             background-color: #0065FF;
                         }

                         .cl_checkbox>input:checked+span::after,
                         .cl_checkbox>input:indeterminate+span::after {
                             border-color: #fff;
                         }

                         .cl_checkbox>input:indeterminate+span::after {
                             border-left: none;
                             transform: translate(4px, 3px);
                         }

                         /* Hover, Focus */
                         .cl_checkbox:hover>input {
                             opacity: 0.04;
                         }

                         .cl_checkbox>input:focus {
                             opacity: 0.12;
                         }

                         .cl_checkbox:hover>input:focus {
                             opacity: 0.16;
                         }

                         /* Active */
                         .cl_checkbox>input:active {
                             opacity: 1;
                             transform: scale(0);
                             transition: transform 0s, opacity 0s;
                         }

                         .cl_checkbox>input:active+span::before {
                             border-color: #0065FF;
                         }

                         .cl_checkbox>input:checked:active+span::before {
                             border-color: transparent;
                             background-color: rgba(0, 0, 0, 0.6);
                         }

                         /* Disabled */
                         .cl_checkbox>input:disabled {
                             opacity: 0;
                         }

                         .cl_checkbox>input:disabled+span {
                             color: rgba(0, 0, 0, 0.38);
                             cursor: initial;
                         }

                         .cl_checkbox>input:disabled+span::before {
                             border-color: currentColor;
                         }

                         .cl_checkbox>input:checked:disabled+span::before,
                         .cl_checkbox>input:indeterminate:disabled+span::before {
                             border-color: transparent;
                             background-color: currentColor;
                         }












                         .pagnationConintner {
                             /* height: 6vh; */
                             padding: 10px;
                             position: absolute;
                             bottom: 5px;
                             width: calc(100% - 20px);
                             display: flex;
                             align-items: center;
                             justify-content: space-between;
                             font-size: 12px;
                             /* border: 1px solid; */
                         }

                         .gotToPage1 {
                             width: 100%;
                         }

                         .gotToPage2 {
                             width: 100%;
                             display: flex;
                             justify-content: flex-end;
                         }

                         .colOneConintner {
                             display: flex;
                             flex-direction: column;
                             gap: 4px;
                         }

                         .blueText {
                             color: #4164E3;
                             font-size: 14px;
                             font-weight: 400;
                         }

                         .boxText {
                             display: flex;
                             align-items: center;
                             gap: 10px;
                         }

                         .boxtextCon {
                             width: 40px;
                             height: 31px;
                             /* border: 1px solid; */
                             border-radius: 5px;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             background-color: #D9DCE5;
                             color: #646B88;
                         }

                         .inputWidth {
                             width: 40%;
                         }



                         .DrawerWrapperContinetr {
                             width: 100%;
                             padding: 20px;
                             z-index: 9999;
                             background: rgba(44, 46, 51, 0.85);
                             box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
                             backdrop-filter: blur(10.5px);
                             -webkit-backdrop-filter: blur(10.5px);
                             border-radius: 10px;
                         }

                         .DrawerWrapperContinetr_light {
                             width: 100%;
                             padding: 20px;
                             z-index: 9999;
                             background: rgba(255, 255, 255, 0.7);
                             box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
                             backdrop-filter: blur(7px);
                             -webkit-backdrop-filter: blur(7px);
                             border-radius: 10px;
                             display: flex;
                             flex-direction: column;
                         }


                         .DrawerWrapperText {
                             color: #0065FF;
                         }

                         .DrawerWrapperMainCointner {
                             width: 100%;
                             /* border: 1px solid; */
                             display: flex;
                             flex-wrap: nowrap;
                             margin-top: 20px;
                         }

                         .DrawerWrapperInputConintner1 {
                             width: 100%;
                             display: flex;
                             gap: 20px;
                         }

                         .DrawerWrapperInputConintner {
                             width: 100%;
                             display: flex;
                             align-items: flex-end;
                             justify-content: flex-end;
                             gap: 20px;
                         }

                         .btnwidth {
                             width: 20%;
                         }


                         .AccordionTriggerContiner {
                             width: 100%;
                             display: flex;
                             align-items: center;
                             gap: 1vw;
                             padding: 0.7vw;
                             /* border-radius: 10px 10px 0px 0px; */
                             border-radius: 10px;
                             /* border-radius: 10px ; */
                             height: 70px;
                         }

                         .Accordioncolor {
                             width: 2.5rem;
                             height: 2.5rem;
                             border-radius: 50%;
                         }

                         .AccordionTextFrist {
                             font-size: 1rem;
                             font-weight: 400;
                         }

                         .AccordionTextSecand {
                             font-size: 1rem;
                             font-weight: 300;
                         }

                         .Start_content_conintner {
                             width: 100%;
                             height: auto;
                             padding: 10px;
                             overflow: hidden;
                             /* border: 1px solid; */
                             position: relative;
                         }

                         .Start_content_conintner_header {
                             width: 100%;
                             height: 3.5rem;
                             display: flex;
                             align-items: center;
                             padding-left: 20px;
                             gap: 0.7vw;
                             border-radius: 10px 10px 0px 0px;
                         }

                         .headIcon {
                             width: 20px;
                             height: 20px;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             background-color: #1E89C833;
                         }

                         .headIcon div {
                             width: 14px;
                             height: 14px;
                             background-color: #1E89C8;
                         }

                         .startContantContiner {
                             display: flex;
                             flex-direction: column;
                             padding: 10px;
                         }

                         .fristLIne {
                             font-size: 14px;
                             font-weight: 500;
                         }

                         .sandLine {
                             font-size: 12px;
                             font-weight: 400;
                         }

                         .startContant {
                             width: 100%;
                             height: auto;
                             box-sizing: border-box;
                             display: flex;
                             border-radius: 0px 0px 10px 10px;
                             justify-content: space-between;
                             flex-direction: row;
                             padding: 10px;
                             gap: 10px;
                         }

                         .startContant_justSent {
                             width: auto;
                             border-radius: 5px;
                         }

                         .Start_content_conintnerMain {
                             width: 100%;
                             border-radius: 10px;
                             height: auto;
                             overflow: hidden;
                             /* border: 1px solid; */
                             position: relative;
                             z-index: 999;
                         }

                         .whou_content_conintnerMain {
                             width: 100%;
                             border-radius: 7px;
                             height: auto;
                             overflow: hidden;
                             /* border: 1px solid; */
                             position: relative;
                             z-index: 999;
                         }

                         .Who_SelectUser {
                             width: 100%;
                             display: flex;
                             align-items: center;
                             gap: 10px;
                         }

                         .Who_Select {
                             width: 140px;
                             height: 40px;
                             border-radius: 6px;
                             padding: 8px;
                             box-sizing: border-box;
                             display: flex;
                             justify-content: space-around;
                             cursor: pointer;
                             position: relative;
                         }

                         .cont {
                             /* width: 180px; */
                             display: flex;
                             align-items: center;
                             gap: 5px;
                             /* border: 1px solid; */
                         }

                         .cont1 {
                             max-width: 180px;
                             display: flex;
                             align-items: center;
                             gap: 5px;
                             /* border: 1px solid; */
                         }

                         .Who_Select3 {
                             width: 140px;
                             height: 50px;
                             border-radius: 6px;
                             padding: 10px;
                             box-sizing: border-box;
                             display: flex;
                             justify-content: space-around;
                             cursor: pointer;
                             position: relative;
                         }

                         .Who_Select1 {
                             width: 300px;
                             height: 50px;
                             border-radius: 6px;
                             padding: 8px;
                             box-sizing: border-box;
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                             cursor: pointer;
                             position: relative;
                             margin-top: 10px;
                         }

                         .Who_selectBox {
                             position: absolute;
                             /* right: -179px; */
                             left: 0%;
                             top: 100%;
                             /* border: 1px solid; */
                             box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                             width: 180px;
                             border-radius: 6px;
                             /* padding: 20px 20px; */
                             z-index: 9999;
                             display: flex;
                             flex-direction: column;
                             gap: 8px;
                         }

                         .Who_selectBoxWho {
                             position: absolute;
                             /* right: ; */
                             left: 0%;
                             /* border: 1px solid; */
                             box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                             width: 300px;
                             border-radius: 6px;
                             padding: 10px;
                             z-index: 9999;
                             top: 100%;
                             display: flex;
                             flex-direction: column;
                             gap: 1px;
                         }

                         .Who_selectBoxhover {
                             padding: 15px;
                             border-radius: 4px;
                             /* transition: transform 0.3s ease; */
                             font-size: 12px;
                             font-weight: 400;
                             line-height: 0.5;
                         }

                         .Who_selectBoxhover:hover {
                             background: #F8F9FC;
                             /* transform: scale(1.05); */
                         }

                         .Who_selectBoxhover_dark {
                             padding: 15px;
                             border-radius: 4px;
                             /* transition: transform 0.3s ease; */
                             font-size: 12px;
                             font-weight: 400;
                             line-height: 0.5;

                         }

                         .Who_selectBoxhover_dark:hover {
                             background: #232529;
                             /* transform: scale(1.05); */
                         }

                         .Who_SecandSection {
                             width: 100%;
                             /* border: 1px solid; */
                             margin-top: 10px;
                             height: 64px;
                             border-radius: 10px;
                             display: flex;
                             align-items: center;
                             gap: 10px;
                             padding-left: 10px;
                         }

                         .Who_dateContiner {
                             width: 100%;
                             /* border: 1px solid red; */
                             margin-top: 10px;
                             display: flex;
                             position: relative;
                             margin-bottom: -15px;
                         }

                         .Who_dateContiner1 {
                             width: 100%;
                             display: flex;
                             gap: 20px;
                             margin-left: 10px;
                             /* border: 1px solid; */
                         }

                         .Who_dateContiner2 {
                             width: 100%;
                             /* display: flex; */
                             justify-content: flex-end;
                             align-items: center;
                             /* border: 1px solid; */
                         }

                         .Who_User {
                             width: 100%;
                             /* border: 1px solid; */
                             border-radius: 7px;
                             height: 50px;
                             padding: 0px 20px;
                             display: flex;
                             align-items: center;
                             justify-content: space-between;
                         }

                         .whatMainConitner {

                             width: 100%;
                             display: flex;
                             justify-content: space-between;
                             align-items: center;
                         }

                         .what_MessgeType {
                             font-size: 12px;
                             font-weight: 400;
                             width: 100%;
                         }

                         .redioBtnContiner {
                             width: 100%;
                             /* border: 1px solid; */
                             display: flex;
                             gap: 10px;
                             margin-top: 10px;
                         }

                         .redioBtnContiner1 {
                             width: 100%;
                             /* border: 1px solid; */
                             display: flex;
                             flex-direction: column;
                             gap: 10px;
                             /* margin-top: 20px; */
                             padding: 20px 0px 20px 30px;
                             overflow-y: scroll;
                         }








                         /* -------------------------------------- */




                         .stateFilter {
                             position: absolute;
                             width: 200px;
                             max-height: 200px;
                             border: 1px solid;
                             overflow-y: scroll;
                             left: 100%;
                             top: 0;
                             padding: 10px;
                             z-index: 10;
                         }

                         .DisticFilter {
                             position: absolute;
                             width: 200px;
                             max-height: 200px;
                             border: 1px solid;
                             overflow-y: scroll;
                             left: 100%;
                             top: 0;
                             padding: 10px;
                             /* z-index: 99; */

                         }

                         .stateFilterContiner {
                             display: flex;
                             width: 100%;
                             border: 1px solid;
                             flex-direction: column;
                             gap: 5px;
                             position: relative;
                             z-index: 15;
                         }




                         .stateFilterHead {
                             width: 100%;
                             display: flex;
                             flex-direction: column;
                             gap: 10px;
                             line-height: 5px;
                             margin-top: 10px;
                         }

                         .stateFilterHead input {
                             width: 100%;
                             border: 1px solid;
                             outline: none;
                             height: 30px;
                             padding: 5px;
                             line-height: 10px;
                         }












                         .stateFilter1 {
                             position: absolute;
                             width: 200px;
                             max-height: 200px;
                             border: 1px solid;
                             overflow-y: scroll;
                             left: 100%;
                             top: 0;
                             padding: 10px;
                             /* z-index: 999; */

                         }


                         .DisticFilter1 {
                             position: absolute;
                             width: 200px;
                             max-height: 200px;
                             border: 1px solid;
                             overflow-y: scroll;
                             left: 100%;
                             background-color: yellow;
                             top: 0;
                             padding: 10px;
                             z-index: 999;
                             /* Increase this to bring it forward */
                         }

                         .stateFilterContiner1 {
                             display: flex;
                             width: 100%;
                             border: 1px solid;
                             flex-direction: column;
                             gap: 5px;
                         }

                         .stateFilterHead1 {
                             width: 100%;
                             display: flex;
                             flex-direction: column;
                             gap: 10px;
                             line-height: 5px;
                             margin-top: 10px;
                         }

                         .stateFilterHead1 input {
                             width: 100%;
                             border: 1px solid;
                             outline: none;
                             height: 30px;
                             padding: 5px;
                             line-height: 10px;
                         }

                         .iconWrapper0 {
                             position: relative;
                             display: flex;
                             align-items: center;
                             gap: 5px;
                             /* border: 1px solid; */
                         }

                         .AroBtn0 {
                             width: 50px;
                             height: 50px;
                             border-radius: 8px;
                             display: flex;
                             align-items: center;
                             justify-content: center;
                             cursor: pointer;
                             position: relative;
                             border: 1px solid;
                         }

                         .mainMenu0 {
                             display: flex;
                             flex-direction: column;
                             position: absolute;
                             top: 4%;
                             /* left: 100%; */
                             right: 70%;
                             background-color: white;
                             box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                             border-radius: 8px;
                             z-index: 10;
                             /* height: 300px;  */
                             height: auto;
                             max-height: 300px;
                         }

                         .menuItem_dark0,
                         .menuItem0 {
                             cursor: pointer;
                             position: relative;
                             height: auto;
                             max-height: 100%;
                             width: 170px;
                         }

                         .menuItem_dark0:hover {
                             /* background-color: #1E2022; */
                             border-radius: 5px;
                         }



                         .menuItem0:hover {
                             /* background-color: #F8F9FC; */
                             border-radius: 5px;
                         }

                         .subMenudark0,
                         .subMenu0 {
                             background-color: white;
                             box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                             border-radius: 8px;
                             height: 300px;
                             width: 170px;
                             padding: 8px;
                             margin-top: 10px;
                         }

                         .subMenudark0 div:hover {
                             /* background-color: #1E2022; */
                             border-radius: 5px;
                         }


                         .subMenudark {
                             position: fixed;
                             top: 50px;
                             right: 50px;
                             z-index: 999;
                         }


                         .subMenu0 div:hover {
                             /* background-color: #F8F9FC; */
                             border-radius: 5px;
                         }

                         .subMenu0 div {
                             cursor: pointer;
                         }

                         .scrollDiv {
                             height: 80%;
                             overflow-y: scroll;
                             width: 100%;
                             font-size: 12px;
                             font-weight: 300;
                             display: flex;
                             flex-direction: column;
                             gap: 5px;
                         }

                         .sarchCon {
                             height: 20%;
                             padding: 0 5px;
                             font-size: 12px;
                             font-weight: 500;
                         }

                         .sarchCon input {
                             width: 100%;
                             margin-top: 4px;
                         }

                         .sarchCon input::placeholder {
                             color: #646B88;
                         }


                         .lightHover,
                         .darkHover {
                             width: 100%;
                             /* border: 1px solid; */
                             display: flex;
                             align-items: center;
                             justify-content: flex-start;
                             padding: 5px;
                             cursor: pointer;
                             border-radius: 5px;
                         }

                         .lightHover:hover {
                             background-color: #D9DCE5;
                         }

                         .darkHover:hover {
                             background-color: #2C2E33;
                         }



                         @media (max-width:700px) {
                             .gotToPage1 {
                                 display: none;
                             }

                             .gotToPage2 {
                                 justify-content: center;
                             }

                             .mainCintner {
                                 padding: 0px;
                             }

                             .DrawerWrapperMainCointner {
                                 flex-wrap: wrap;
                                 margin-top: 5px;
                             }

                             .DrawerWrapperInputConintner {
                                 margin-top: 10px;
                             }

                             .btnwidth {
                                 width: 50%;
                             }

                             .inputWidth {
                                 width: 50%;
                             }
                         }


                         .modelCon {
                             min-width: 350px;
                             width: 550px;
                             /* border: 1px solid; */
                             padding: 20px;
                             height: 500px;
                             border-radius: 8px;
                         }

                         .SacandContiner {
                             width: 100%;
                             height: 100%;
                             /* border: 1px solid; */
                         }

                         .headre {
                             width: 100%;
                             display: flex;
                             justify-content: space-between;
                             padding: 10px;
                             font-size: 12px;
                             font-weight: 500;
                             height: 40px;
                         }

                         .bodyContiner {
                             width: 100%;
                             /* border: 1px solid; */
                             font-size: 12px;
                             font-weight: 500;
                             display: flex;
                             flex-direction: column;
                             gap: 5px;
                             max-height: 380px;
                             overflow-y: scroll;
                             ;
                         }

                         .bodyContiner2 {
                             width: 100%;
                             border-bottom: 1px solid;
                             display: flex;
                             justify-content: space-between;
                             font-size: 12px;
                             padding: 5px 10px;
                             font-weight: 500;
                         }

                         .btnCon {
                             width: 100%;
                         }

                         .report_mainCon {
                             width: 100%;
                         }

                         .templetReport1 {
                             width: 100%;
                             /* border: 1px solid; */
                         }

                         .timing {
                             display: flex;
                             gap: 10px;
                             margin-top: 10px;
                         }

                         .suduleTime {
                             width: 200px;
                             /* border: 1px solid; */
                             display: flex;
                             border-radius: 5px;
                             flex-direction: column;
                             /* align-items: center; */
                             justify-content: center;
                             padding: 8px 0px 0px 0px;
                         }

                         .suduleTime2 {
                             border-bottom: 2px solid #4164E3;
                         }

                         .suduleTime1 {
                             display: flex;
                             justify-content: center;
                         }

                         .suduleTimeCart {
                             width: 100%;
                             /* border: 1px solid; */
                             margin-top: 10px;
                             padding: 18px;
                             display: flex;
                             gap: 10px;
                             border-radius: 10px;
                         }

                         .cartwidth {
                             width: 207px;
                             height: 146px;
                             border-radius: 10px;
                             padding: 20px;
                             box-sizing: border-box;
                             display: flex;
                             flex-direction: column;
                             justify-content: center;
                         }

                         .NewLocatiionFilter {
                             width: 100%;
                             position: absolute;
                             z-index: 998;
                             width: 180px;
                             box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                             border-radius: 8px;
                         }

                         .AccordionContentCss {
                             width: 100%;
                             border: 1px solid;
                             display: flex;
                             justify-content: space-between;
                         }

                         .AccordionContentCss div {
                             width: 100%;
                             border: 1px solid;
                             display: flex;
                             justify-content: center;
                         }





                         /* table shuer  */
                         .tabContainer {
                             width: 100%;
                             margin: 20px auto;
                             border: 1px solid #ccc;
                             border-radius: 8px;
                             overflow: hidden;
                         }

                         .tabHeader {
                             display: flex;
                             background-color: #f1f1f1;
                         }

                         .tabButton {
                             flex: 1;
                             padding: 10px;
                             font-size: 16px;
                             cursor: pointer;
                             border: none;
                             background-color: transparent;
                             transition: background-color 0.3s;
                         }

                         .tabButton:hover {
                             background-color: #ddd;
                         }

                         .active {
                             background-color: #007bff;
                             color: #fff;
                         }

                         .tabContent {
                             padding: 20px;
                             font-size: 18px;
                             background-color: #fff;
                         }

                         .tabPanel {
                             animation: fadeIn 0.3s ease-in-out;
                         }

                         @keyframes fadeIn {
                             from {
                                 opacity: 0;
                             }

                             to {
                                 opacity: 1;
                             }
                         }

                         .containermode {
                             width: 90%;
                         }

                         .model_text {
                             font-size: 20px;
                             font-weight: 600;
                             margin-top: 20px;
                         }

                         .btn_Continer {
                             width: 100%;
                             /* border: 1px solid; */
                             display: flex;
                             justify-content: center;
                             gap: 10px;
                             margin-top: 10px;
                         }

                         .mode_btn {
                             width: 49%;
                         }