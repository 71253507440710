.right_main {
    width: 100%;
    /* background-color: transparent; */
    background-color: white;
    color: #D9DCE5;
    padding: 0 15px;
}
.reloadTextWrapper {
  margin-right: 10px;
  font-size: 11px;
}

/* Style for the Reload Icon */
.reloadIcon2 {
  stroke-width: 2;
  stroke: white;
  fill: white;
}
/* styles.module.css */
.container3 {
    color: #E4E7EB;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
    position: relative;
  }
  
  .lightMode {
    background-color: #F8F9FC;
  }
  
  .darkMode {
    background-color: #2C2E33;
  }
  
/* General flex layout for dispatch top container */
.dispatchTopContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* Add this to your CSS or styles module */

/* Styles for dispatch top left container */
.dispatchTopLeft {
  position: relative;
  top: -10px;
  margin-top: 15px;
  padding-top: 0;
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Styling the go-back button text */
.dispatchGoBackText {
  color: #4164E3;
  margin-top: 4px;
  margin-right: 0;
}

.dispatchTopLeftPathText {
  color: #646B88;
}

/* Flex container for sliding filter and date inputs */
.slidingFilterDiv {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Date input fields */
.dateInputsDiv {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Reload and download button container */
.reloadAndDownloadButtonDiv {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Reload button styles */
.reloadButton {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reloadIcon {
  width: 16px;
  height: 16px;
}

.reloadText {
  font-size: 12px;
}

/* Button styles */
.buttonContainer {
  display: flex;
  gap: 10px;
}

/* Button padding for download button */
.downloadButton {
  padding: 8px 20px;
  align-items: center;
}


/* .right_main { */
/* width: 100%; */
/* background-color: transparent; */
/* background-color: white; */
/* color: #D9DCE5; */
/* padding: 0 15px; */
/* } */
/* Hover effect */
.statusCard_div {
    transition: all 0.3s ease;
    /* Smooth transition */
}

.statusCard_div.hovered {
    background-color: white;
    /* Background color when hovered */
    border: 2px solid #000;
    /* Border color on hover */
}

/* Active effect */
.statusCard_div.active {
    border: 2px solid #007bff;
    /* Blue border when card is active */
}

/* Default border */
.statusCard_div {
    border: 2px solid transparent;
    /* Default border */
}

/* Optional: For styling the card when it's not hovered/active */
.statusCard_div {
    background-color: var(--background-color);
    /* Set background depending on mode (dark/light) */
}


.heading {
    font-weight: 600;
    font-size: 30px;
    margin: 16px 0 0px 0;
}

.superAdmin_heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
}

.dispatchHeading {
    font-weight: 600;
    font-size: 30px;
    margin: 6px 0 0px 0;
}

.dashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    line-height: 1.5rem;
    justify-content: space-between;
}

.dispatchDashboard_header {
    display: flex;
    /* flex-wrap: wrap; */
    padding: 12px 0;
    /* line-height: 1.5rem; */
    justify-content: flex-end;
    /* align-items: center; */
}

.dealer_search_div {
    position: relative;
    width: 20%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 50px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 200%;
    min-width: 200px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dealer_header_right {
    display: flex;
    flex-direction: row;
}

.dealer_Sort_By {
    padding-right: 30px;
}

.dealer_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dealer_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.dashboard_sortBy_select option {
    color: #000000;
    /* background-color: #ffffff !important; */

}

.dashboard_header_select_days {
    max-height: 49px;
    font-size: 13px;
    color: white;
    background-color: black;
    /* background-color: #fff; */
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 410px;
    justify-content: center;
}

.dashboard_header2_section {
    margin-left: 100px;
}

/* .dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    gap: 10px;
  padding: 10px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 3px;
    justify-content: center;
} */
/* .dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    gap: 10px;
  padding: 10px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 360px;
    justify-content: center;
} */

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    /* gap: 10px; */
    padding: 10px;
    color: black;
    /* Default text color */
    /* background-color: #fff;  */

    display: flex;
    flex-direction: row;
    /* border-radius: 25px; */
    align-items: center;
    width: 300px;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    /* margin-left: "20%"; */

}

.dashboard_header_select_week:active {
    color: white;
    /* Text becomes white when clicked */
    /* Background becomes black when clicked */
}

/* Add this to your CSS file */
.dashboard_days_select {
    padding: 10px 20px;
    cursor: pointer;
    color: black;
    /* Default text color */
    background-color: transparent;
    /* Default background color */
    border: 1px solid transparent;
    /* Optional: add border for better visibility */
    transition: all 0.3s ease;
    /* Smooth transition for hover or active states */
}

.dashboard_days_select_active {
    color: white;
    /* Active text color */
    background-color: black;
    /* Active background color */
    border: 1px solid black;
    /* Border for active state */
}


.recipt_tabs_div {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
    padding: 0 6px;
}

.dashboard_days_select {
    /* width: 100px; */
    width: 95px;
    text-align: center;
    /* border-radius: 25px; */
    border-radius: 10px;
    /* padding: 10px 0px; */
    padding: 13px 0px;
    cursor: pointer;
}

.dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: black;

    /* background-color: #E3E7EF; */
    cursor: pointer;
    font-weight: bold;
}

.dashboard_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.dashboard_main_div::-webkit-scrollbar {
    display: none;
}

.dashboard_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    /* min-width: 270px; */
}

.dashboard_search_div {
    position: relative;
    width: 16%;
    display: flex;
}

.dashboard_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    font-size: 12px;
}




.dashboard_sortBy_txt {
    font-size: 14px;
    padding-left: 15px;
    color: #000;
    font: bold;
}

.dashboard_sortBy_select {
    border: 1px solid #1E2022;
    height: 40px;
    width: 50px;
    background-color: transparent;
    color: #000;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    /* text-align: left; */
    font-size: 13px;

}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.dashboard_sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.statusCard_div {
    display: flex;
    align-items: center;
    gap: 8px;
    /* cursor: pointer; */
}

.dashboard_goback_icon {
    margin-right: 5px;
    margin-top: 5px;
}

.statusCard {
    box-sizing: border-box;
    width: 14%;
    height: 130px;
    /* background: #1E2022; */
    background: white;

    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.danger_active {
    border: 3px solid #F32D3C;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: red;
    /* color: red; */
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusBlue {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    max-width: 96px;
}

.scroll {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scroll::-webkit-scrollbar {
    display: none;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    border-collapse: unset;
    border-spacing: 0 10px;
    width: 99% !important;
}



tr.item {
    /* border-color: #2C2E33; */
    border-style: solid;
    border-width: 5px 0;
    line-height: 4 !important;
    /* font-weight: 200; */
    /* font-size: 16px; */
    font-size: 12px;
    /* padding: 10px; */
    /* text-align: left; */
    cursor: pointer;
    color: #000;
    overflow-y: auto;

}


.item td,
th {
    /* padding: 0 10px; */
    /* border-color: #1E2022; */
    border-color: black;
    text-align: left;
    padding: 10px;
    overflow-y: auto;
    margin-top: 9px;

}

table.itemTable {
    /* caption-side: bottom; */
    border-collapse: unset;
    /* border-spacing: 0 10px; */
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    /* border-radius: 7px 0 0 7px; */
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}

.table_txt_green {
    /* color: #3F51B5; */
    color: #00A052;
    font-weight: 500;
}
.table_txt_blue1 {
    color: #9747FF;
    /* color: #9747FF; */
    font-weight: 500;
}
.table_txt_orange {
    color: #FFC776;
    font-weight: 600;
}

.table_txt_yellow {
    color: #FF9B04;
    /* color: #FF9B04; */
    font-weight: 500;
}

.itemtabledata {
    overflow-y: auto;

}
.table_txt_Pending{
    /* color: red; */
    color: #FF7F50;
    /* color: #E4D00A; */
    font-weight: 500;

}
.table_txt_blue {
    color: #4164E3;
    font-weight: 500;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 500;
}

.actionPending_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 80px 0 0 0;
    min-height: 480px;
}

.actionPending {
    width: 20%;
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    min-height: 480px;
    position: relative;
}

.InactiveDealers {
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 80%;
    margin-left: 40px;
}

.actionPending_heading {
    border-bottom: 1px solid #4D4D4D;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
}

.takeAction {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    height: 100%;
    padding-bottom: 24px;
    position: absolute;
}

.InactiveDealers_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px
}

.InactiveIcon_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inactiveIcon_div {
    border: 1px solid #4D4D4D;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
}

.inactiveIcon {
    width: 30px;
    height: 30px;
}

.whatsappIcon {
    width: 40px;
    height: 40px;
}

.inactive_profile_details {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 30px 0;
}

.profile_details {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    min-width: 220px;
}

.inactive_dealer_img {
    margin: 0 10px;
}

.selectAll {
    float: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4px;
    padding-right: 30px;
}

.textarea_css {
    background: #18191B;
    border-radius: 6.62606px;
    color: #FFFFFF;
    width: 100%;
    margin: 24px 0;
    border: 0;
    outline: none;
    padding: 10px;
    resize: none;
}

.progressBar_center {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shortBy_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 6px 0;
}

.adminHeight {
    overflow-y: scroll;
    max-height: calc(100vh - 416px);
    min-height: calc(100vh - 416px);
}

.adminHeight::-webkit-scrollbar {
    display: none;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .dispatchOrders_height {
    overflow-y: scroll;
    
  
    min-height: 200px;
    max-height: 430px;
    font-size: 14px;
 
    font-weight: 500;
text-align: left;    

}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
} */
.thpadding {
    padding: 10px;
    font-size: 14px;
}
.thpadding1 {
    padding: 10px;
    /* font-size: 14px; */
    font-weight: 300;
}


.dispatchOrders_height {
    overflow-y: scroll;
    min-height: 200px;
    max-height: 200px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

/* Hides scrollbar */
.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}
/* .dispatchOrders_height {
    overflow-y: scroll;
    min-height: 200px;
    max-height: 430px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
   */

  
/* Make the table responsive by adding horizontal scrolling */
@media (max-width: 768px) {
    .dispatchOrders_height {
        max-height: 400px;
        /* Adjust to your desired height */
    }

    .thpadding {
        padding: 8px;
        /* font-size: 12px; */
    }

    table {
        width: 100%;
    }

    /* Optional: Adjust font size for smaller screens */
    th,
    td {
        font-size: 12px;
    }

    /* Hide specific columns on smaller screens */
    th:nth-child(10),
    td:nth-child(10),
    th:nth-child(11),
    td:nth-child(11) {
        display: none;
        /* Hide "Delivery Partner" and "Status" on smaller screens */
    }
}

/* For very small screens (like mobile phones) */
@media (max-width: 480px) {
    .dispatchOrders_height {
        max-height: 300px;
        /* Adjust for smaller screens */
    }

    /* Make table text smaller */
    th,
    td {
        font-size: 10px;
    }

    /* Hide even more columns if needed */
    th:nth-child(9),
    td:nth-child(9) {
        display: none;
        /* Optionally hide "Sub-Method" */
    }
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
} */
.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}


::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.startEndDate_div {
    margin-left: 40px;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 16px;
    line-height: 2px;
    /* height: 10px; */
    /* margin: 5px; */
    justify-content: center;
}

.reload_icon {
    font-size: 5px;
}

.thpadding {
    padding: 10px;
}

.search {
    display: flex;
    margin-top: 10px;
    gap: 30px;
    /* cursor: pointer; */
}

.loadingOrders_dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}


.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.superAdmin_height {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
    /* min-height: 528px; */
}

.superAdmin_height::-webkit-scrollbar {
    display: none;
}

.tableIcons_center {
    text-align: center !important;
}

.table_status {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_pending {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 1.5em !important;
}

.table_status_green {
    color: #1FFC33;
    border: 1px solid #1FFC33;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_red {
    color: #F32D3C;
    border: 1px solid #F32D3C;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding-left: 20px;
    /* padding: 0 20px; */
}

.table_heading {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_heading_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_heading_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.recipt_scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 490px);
    min-height: calc(100vh - 490px);
}

.recipt_scrollTable::-webkit-scrollbar {
    display: none;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    border-radius: 6px;
    cursor: pointer;
    margin: 10px 0;
}

.table_content_subdiv {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    /* margin: 10px 0; */
    /* padding: 0 20px; */
    padding-left: 20px;
    cursor: pointer;
    position: relative;
}

.dropdown_content_div {
    padding: 0 108px;
}

.table_txt {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_txt_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_txt_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.dropdown_details_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.dropdown_details_div b {
    font-weight: 600;
}

.dropdown_reason {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #1FFC33;
    padding-top: 6px;
    padding-bottom: 12px;
}

.ordersName_truncate {
    /* margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative; */
    /* text-align: center; */
}

.showOverlay {
    position: absolute;
    background-color: #FFFFFF;
    width: 100px;
    right: 0;
    margin-right: 38px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-top: 8px;
}

.showOverlay span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.showOverlay span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

.text_center {
    text-align: center;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.message {
    text-align: center;
    margin: 16px 0 0 0;
    font-size: 24px;
}

.modal_backdrop {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
}

.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 100px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 40px;
    /* z-index: 999; */
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

/* @media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 20px;
        position: absolute;
        bottom: 0;
    }
} */

.warningInput {
    box-sizing: border-box;
    width: 88%;
    height: 48px;
    border: 0.5px solid #F3F3F3;
    border-radius: 4px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 0 6px;
    text-align: center;
    font-size: 20px;
    margin-top: 16px;
}

.warnigModal_btn {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 24px auto 10px;
}

.ReciptModalHeader {
    position: absolute;
}

.reciptModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%;
}

.reciptModal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #1E2022;
    color: #FFFFFF;
    border-radius: 12px;
}

.reciptModal_content::-webkit-scrollbar {
    display: none;
}

.text_center {
    text-align: center;
}

.reciptHeader_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.reciptHeader_detail {}

.recipt_tableDiv {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #FFFFFF;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptHeading {
    font-weight: 600;
    /* padding-bottom: 40px; */
}

.reciptContentDiv {
    padding: 0 40px;
}

.recipt_selectDiv {
    display: flex;
}

.recipt_selectDiv_Account {
    display: flex;
    margin: 16px 0;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    padding: 0 6px;
}

.reciptMethod_select option {
    background-color: #1E2022;
    text-align: left;
}

.method_txt {
    /* margin-right: 40px; */
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.reciptAmount_txt {
    margin: 18px 0;
    font-size: 18px;
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;
}

.recipt_inputField {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin: 32px 0 12px 0;
}

.recipt_inputField_ref {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin-top: 16px;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 16px auto 24px;
}

.recipt_payNow {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    line-height: 28px;
}

.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

.recipt_AmountDiv {
    margin: 20px 0;
}

.reciptAmount_input {
    background-color: transparent;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    float: right;
    min-width: 400px;
}

.amount_txt {
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.dragAndDrop_div {
    height: 224px;
    position: absolute;
    width: 516px;
    background-color: transparent;
    z-index: 1;
    /* margin: 16px 0; */
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 28px 0 0px 0;
    /* margin: 12px 0; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}

.border_OR_div {
    border: 1px solid #FFFFFF;
    width: 80%;
    margin: 0;
}

.OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
}

.browseFiles_btn {
    border: 1px solid #FFFFFF;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.packing_image {
    background: transparent;
}

.methodAndAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.method_selectDiv {
    width: 50%;
    margin-right: 5px;
}

.method {
    font-size: 20px;
    display: flex;
}

.refund_width {
    width: 88%;
    margin: auto;
}

.methodSelect {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #FFFFFF;
}

.amount_input {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.methodSelect option {
    background-color: #1E2022;
}

.submethod_txt {
    font-size: 20px;
}

.cancel_receipt_btn {
    font-size: 14px;
    font-weight: 500;
}

/* ----------------------------------DispatchDasboard-Mobile--------------------------------------------- */

.main_container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}

.maincont {
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 65px);
    padding: 10px;
}

.card-container {
    display: grid;
    /* Use grid layout */
    grid-template-columns: repeat(3, 1fr);
    /* 3 equal-width columns */
    gap: 10px;
    /* Space between cards */
}

.card {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 cards per row for medium screens */
    }
}

@media (max-width: 480px) {
    .card-container {
        grid-template-columns: 1fr;
        /* Stack cards for small screens */
    }
}


/* Default card styles */


/* Default card styles for larger screens (tablet and up) */
.statusCard_div .statusCard {
    width: 30%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
    padding: 5px;
    font-size: 12px;
}

.statusCard_div {
    border: 2px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    /* cursor: pointer; */
    border-radius: 14px;
    margin-top: 10px;
}

/* .Inputmaindiv{
    display: flex;
    flex-direction: column; 
    gap: 15px;
    align-items: center; 


} */
.page1 {
    margin-left: 5px;
    margin-top: 8px;
}

.page2 {
    margin-right: 10px;
    margin-top: 8px;
}

.SlidingFilterdiv1 {
    display: flex;
    gap: 16px;
    justify-content: center;
}


/* .paginationdiv {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    padding: 4px;
    width: 98.9%;
    border-radius: 20px 20px 0 0;
    height: 60px;
} */

.paginationdiv {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    padding: 4px;
    width: 98.9%;
    border-radius: 20px 20px 0 0;
    height: 60px;
  }
  
  .paginationdiv[data-mode="dark"] {
    background-color: #1b1d21;
  }
  
  .paginationdiv[data-mode="light"] {
    background-color: #ffffff;
  }
  
  .page1 {
    /* Add specific styles for .page1 if needed */
  }
  
  .page2 {
    /* Add specific styles for .page2 if needed */
  }
  
.reload_anddownloadbuttondiv {
    display: flex;
    align-items: center;
    gap: 17px;
}
.noDataCell {
    text-align: center;
    padding: 10px;
  }
  
.reload_button {
    background-color: black;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 45px;
    justify-content: center;
}

/* Responsive adjustments for screens up to 700px */
.statusCardContainer {
    display: grid;
    gap: 16px;
    /* Space between cards */
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    /* Default layout */
    width: 100%;
}

.statusCard {
    min-width: 140px;
    /* cursor: pointer; */
}

/* Media query for 350px */
@media (max-width: 350px) {
    .statusCardContainer {
        grid-template-columns: repeat(3, 1fr);
        /* 3 cards per row */
        gap: 8px;
        /* Smaller gaps for tighter spaces */
    }

    .statusCard {
        min-width: 60px;
        /* Adjust card size if needed */
    }
}

/* Media query for 351px to 700px */
@media (min-width: 351px) and (max-width: 700px) {
    .statusCardContainer {
        grid-template-columns: repeat(6, 1fr);
        /* 3 cards per row */
    }
}


/*  new css */
.mainContiner {
    height: calc(100vh - 65px);
    position: relative;
    /* padding: 10px; */
      padding: 10px;

}

.secondmainContiner {
    height: calc(100vh - 65px);
    padding: 10px 20px;
}




/* General Container Styles */
.mainContainer {
    padding: 10px;
}


/* Background & Scrollable Content */
.contentContainer {
    color: #E4E7EB;
    padding: 10px;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

.contentContainerDark {
    background-color: #2C2E33;
}

.contentContainerLight {
    background-color: #F8F9FC;
}

/* Header Section */
.headerSection {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.headerLeft {
    position: relative;
    top: -10px;
    padding: 2px;
}

.headerLeftGoBack {
    display: flex;
    cursor: pointer;
}

.goBackText {
    margin-top: 4px;
    margin-right: 0;
}

.pathText {
    color: #9C9797;
}

.pathTextLight {
    color: #646B88;
}

/* Filters Section */
.filtersSection {
    display: flex;
    gap: 16px;
    align-items: center;
}

.inputDateSection {
    display: flex;
    gap: 15px;
}

.reloadButton {
    background-color: black;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 45px;
    justify-content: center;
}

.reloadText {
    margin-right: 10px;
    font-size: 12px;
}

.downloadButton {
    padding: 8px 20px;
    align-items: center;
}

/* Status Cards */
.statusCard {
    border-radius: 14px;
    margin-top: 10px;
}

.statusCardDark {
    background-color: #232529;
}

.statusCardLight {
    background-color: white;
}

.statusBadge {
    padding: 5px;
    margin-left: 8px;
}

.statusTextNew {
    color: #4164E3;
}

.statusTextInProgress {
    color: #FFAD00;
}

.statusTextPacked {
    color: #008C00;
}

.statusTextPending {
    color: #FF0000;
}

.statusTextCancelled {
    color: #FF0000;
}

/* Table Styles */
.tableContainer {
    width: 100%;
    margin-top: 18px;
    padding: 10px;
}

.tableHeader {
    background-color: #D9DCE5;
    color: #646B88;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.tableHeaderDark {
    background-color: #232529;
    color: #E4E7EB;
}

.tableRow {
    background-color: white;
    color: black;
    border: 5px solid #F8F9FC;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
}

.tableRowDark {
    background-color: #1B1D21;
    color: white;
    border: 5px solid #2C2E33;
}

.tableRowYellow {
    color: #FFAD00;
}

.tableRowGreen {
    color: #008C00;
}

.tableRowBlue {
    color: #4164E3;
}

.tableRowRed {
    color: #FF0000;
}

.tableRowWhite {
    color: #FFFFFF;
}

.tableCell {
    padding: 10px;
}

.tableCellStatus {
    padding: 10px;
    text-align: center;
}

/* Pagination Section */
.paginationContainer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    padding: 4px;
    width: 98.9%;
    border-radius: 20px 20px 0 0;
    background-color: #FFFFFF;
    height: 60px;
}

.paginationContainerDark {
    background-color: #1B1D21;
}

.paginationLeft,
.paginationRight {
    margin-top: 8px;
}

.paginationButton {
    margin-left: 5px;
    margin-top: 8px;
}

/* .order_filter_active {
    border: 1px solid #4164E3; 
}
  .order_filter_dark {
    border: 1px solid #1B1D21; 
  }
  
  .order_filter_dark:hover {
    border: 1px solid #41e3d5; 
  }
  
  .order_filter_active:hover {
    border: 1px solid #4164E3;
  }
   */
/* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order_top p {
    margin: 0;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.order_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.order_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.so_right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 100%;
    height: 110px;
    /* min-width: 100px; */
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter_dark {
    border: 1px solid #1B1D21;
}

.order_filter:hover {
    border-color: #4164E3;
}

.order_filter_dark:hover {
    border-color: #FFFFFF;
}

.order_filter_active {
    border-color: #4164E3;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.order_data_list_row_header {

    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    align-items: center;
}
.order_data_list_row
 {
    /* letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    color: #000; */
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    /* padding: 10px; */
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    /* text-align: left; */
    cursor: pointer;
    /* background-color: #fff; */
    color: #000;
    align-items: center;
}

.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

.order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
}

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

.order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.so_order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.so_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 1rem;
}

.so_data_list_row_1,
.so_data_list_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #E4E7EB;
    text-align: left;
    width: 100%;
    color: #646B88;
    background-color: #fff;
}

.so_data_list_row_1 {
    padding: 0 10px;
    font-weight: 600;
    color: #646B88;
    background-color: transparent;
    border: none;
}

.so_data_list_row_dark {
    background-color: #1B1D21;
    color: #fafafa;
    border: none;
}

.so_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.col {
    flex: 1;
}

.so_data_list_container>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_top_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_top_right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.so_footer_top_right_1 {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 340px;
}

.so_footer_top_right_1_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_footer_top_right_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.so_footer_top_right_1>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_footer_top_right_1_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_footer_top_right_1_check>p {
    margin: 0;
    line-height: 1;
}

.so_footer_top_right_1_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_footer_top_right_1_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_footer_top_right_1_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.so_shipping {
    display: flex;
    align-items: center;
}

.so_shipping input {
    max-width: 60px;
    border: 1px solid #646B88;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    padding: 0.1rem 0.5rem;
    background-color: transparent;
}

.so_footer_top_right_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
}

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.so_inp {
    background-color: transparent;
    border: 1px solid #E4E7EB;
    border-radius: 6px;
    outline: none;
    color: #646B88;
    padding: 0.2rem 1rem;
    max-width: 150px;
    min-width: 50px;
}

.so_inp_dark {
    color: #fff;
    border-color: #646B88;
}

.so_right>div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.so_right>div>p {
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #646B88;
    font-weight: 500;
}

.so_right>p {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}


.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}

.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item */
.si_inp {
    position: relative;
}

.search_item {
    position: absolute;
    top: 150%;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    overflow: hidden;
    color: #000;
    padding: 0.3rem 0;
    width: 100%;
    z-index: 2;
}

.search_item_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.search_item_header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.8rem;
    border-bottom: 1px solid #E4E7EB;
}

.search_item_header>p {
    margin: 0;
    cursor: pointer;
}

.search_item_header>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.item_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.table_header_row,
.table_row {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    gap: 1rem;
    padding: 0.2rem 0.8rem;
    border-bottom: 1px solid #E4E7EB;
    column-gap: 180px;
}

.table_header_row div,
.table_row div {
    text-align: left;
    padding: 0 0.5rem;
    background-color: grey;
    white-space: nowrap;
}

.item_list {
    display: flex;
    flex-direction: column;
    max-height: 6rem;
    overflow-y: auto;
}

.table_row:hover {
    background-color: #e4e7eb;
    color: #000;
}

/* new search item */
.si_table_container {
    max-height: 160px;
    overflow-y: auto;
}

.si_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.si_table th,
.si_table td {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
}

.si_table tbody .selected_row:hover {
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: large;
}

.selected_row_dark {
    background-color: #d3e0ff;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
}

/* new order table approach */
.order_table {
    width: 100%;
    border-spacing: 0 10px;
}

.order_table th,
.order_table td {
    text-align: left;
    border: none;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
}

.order_table .table_header {
    font-weight: 500;
    color: #646B88;
}

.order_table .table_header_dark {
    color: #F8F9FC;
}

.order_table .table_cell {
    background-color: #FFFFFF;
    margin-bottom: 5rem;
}

.order_table .table_cell_dark {
    background-color: #1B1D21;
    color: #F8F9FC;
}

.table_cell .inp_div {
    width: 100%;
    position: relative;
}

.desc {
    color: #000000;
    font-size: 12px;
    width: 1000%;
    padding: 0 0.5rem;
}

.item_inp {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
}

.item_inp_dark {
    color: #FFFFFF;
}

/* shipping charges modal  */
.shipping_charges_modal {
    padding: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 0px 4px 50px 0px #00000026;
}

.shipping_charges_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.shipping_charges_modal>img {
    margin: 1rem auto;
}

.shipping_charges_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

.shipping_charges_modal>div {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
}

.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
    /* margin-top: 0.2rem; */
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.table2 {
    width: 100%;
    margin-top: 18px;
    padding: 10px;
  }
  
.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* refund receipt */
.refund_receipt {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
}

.add_reciept_modal>.refund_receipt>p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #646b88;
}

.refund_receipt>.textarea {
    font-size: 12px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
}

.refund_receipt>.textarea::placeholder {
    color: #9f9f9f;
}

.refund_receipt>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

/* iocn action text */
.icon_action_text {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.icon_action_text>svg {
    height: 18px;
    width: 18px;
}

.icon_action_text>p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1;
}

/* responsive */
.order_dd_mob {
    display: none;
    width: 8rem;
}

.show_760px {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #E4E7EB;
}

.show_760px_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
    border-color: #1B1D21;
}

.show_760px_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_1>p {
    margin: 0;
    font-size: 12px;
    color: #646B88;
}

.show_760px_1>span {
    font-weight: 700;
}

.show_760px_2 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.show_760px_2>div>p {
    margin: 0;
    font-size: 12px;
}

.show_760px_2,
.show_760px_3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_3 {
    gap: 10%;
}

.show_760px_3>.element {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.show_760px>.show_760px_3>.element>div {
    background-color: #F8F9FC;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
}

.show_760px_3>.element>p,
.show_760px_3>.element>div>p {
    margin: 0;
}

.show_760px_3>.element>div>p {
    font-size: 12px;
}

.show_760px_3>.element>p {
    font-size: 12px;
}

.show_760px_4 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    font-size: 12px;
}

.show_1150px,
.show_760px {
    display: none;
}

@media (width<1350px) {
    .so_footer {
        padding: 4px;
    }

    .so_footer_left {
        gap: 1px;
    }

    .so_receipt_data {
        width: 340px;
    }

    .add_receipt_btn {
        padding: 0.1rem;
    }

    .so_footer_right,
    .right_btns {
        gap: 2px;
    }

    .so_amount_info {
        width: 280px;
    }
}

@media (width<1150px) {
    .right_btns {
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
    }

    .so_footer_right {
        gap: 0.5rem;
    }

    .right_btns>.hide_1150px {
        display: none;
    }

    .right_btns>.show_1150px {
        display: block;
    }
}

@media (width<1200px) {
    .order_sf {
        display: none;
    }

    .order_dd_mob {
        display: block;
    }
}

@media (width<890px) {
    .so_form {
        gap: 10px;
        flex-direction: column;
    }

    .so_footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .so_footer_left {
        width: 100%;
        justify-content: center;
    }

    .so_footer_right {
        width: 100%;
        justify-content: center;
    }

    .p_table_header,
    .hide_760px {
        display: none;
    }

    .show_760px {
        display: flex;
    }

    .so_instc {
        display: none;
    }

    .search_item {
        left: -7%;
        top: -1000%;
    }

    .i_table_list {
        max-height: 100px;
    }

    .dealer_address_modal {
        max-width: 340px;
    }

    .address_Pop_Up>img {
        height: 10rem;
    }

    .dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
        font-size: 14px;
        width: 90%;
        font-weight: 400;
    }

    .dealer_address_modal>.address_Pop_Up>p {
        font-weight: 300;
        font-size: 12px;
    }

    .btn_text {
        display: none;
    }

    .so_scroll_wrapper {
        overflow: auto;
        padding-bottom: 0;
    }

    .desc {
        display: none;
    }
}

@media (width<700px) {
    .order {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        background: #f8f9fc;
        overflow: auto;
    }

    .order_top_left {
        display: none;
    }

    .order_right {
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
    }

    .so_footer {
        position: fixed;
    }
}

@media (width<400px) {
    .so_footer_right {
        flex-direction: column;
        align-items: center;
    }

    .right_btns {
        flex-direction: row;
        gap: 1rem;
    }

    .so_amount_info {
        width: 100%;
    }

    .next_btn {
        width: 100%;
    }
}

.tablemain{
    padding-top: 10px;
}
.order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 40vh;
    overflow-y: auto;
}
