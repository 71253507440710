   .dealer_Info_Card {
    background-color: #fff;
    color: #000;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e7eb;
    /* box-shadow: 0px 2px 20px 0px #0000000D; */
    cursor: pointer;
  }

  .dealer_Info_Card_Dark {
    color: #fff;
    background-color: #1b1d21;
    border-color: #1b1d21;
  }

  .dealer_Info_Card_Dark p {
    color: #fff;
  }

  /* .dealer_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
  }

  .dealer_img img {
    border-radius: 50%;
    object-fit: cover;
  } */
.text{
  text-wrap: nowrap;
}

  .dealer_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
  }

  .dealer_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    /* Ensures the image remains circular */
  }


  .dealer_status_icon {
    position: absolute;
    bottom: 0;
    right: -10%;
  }

  .dealer_Info_Card_name {
    display: flex;
    /* justify-self: center; */
    margin-top: 5px;
  }

  .dealer_Info_Card_name p {
    font-size: 16px;
    font-weight: 600;
    color: #646b88;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    margin-bottom: -0.2rem;
  }

  .dealer_Info_Card_Text_Section p:nth-child(1) {
    font-size: 12px;
    font-weight: 400;
    color: #646b88;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .dealer_Info_Card_Text_Section p:nth-child(2) {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }

  .dealer_Info_Card_Icons_Tray {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dealer_Info_Card_Icons_Tray svg {
    cursor: pointer;
  }

  @media (width<700px) {
    .dealer_Info_Card {
      padding: 10px;
      padding-top: 1px;
      overflow: hidden;
    }

    .mob_style_1 {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .dealer_Info_Card img {
      width: 50px;
      height: 50px;
    }

    .dealer_Info_Card_Text_Section p:nth-child(1) {
      text-wrap: wrap;
    }

    .dealer_Info_Card_Icons_Tray {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 0.5rem;
      width: 100%;
      margin-top: -1rem;
    }
  }

  /* dealer status card */

  .dealer_status_card {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: 1px solid #e4e7eb;
    background-color: #fff;
    color: #646b88;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .dealer_status_card p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    max-width: 150px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  .dealer_status_card p:nth-child(3) {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .dealer_status_card_dark {
    color: #fff;
    background-color: #1b1d21;
    border: none;
  }

  /* sliding filter styles */

  .sliding_filter {
    display: flex;
    /* background-color: #fff; */
    border-radius: 5px;
    color: #000;
    height: 2.5rem;
    /* border: 0.5px solid #c0c0c0; */
  }

  .sliding_filter_dark {
    display: flex;
    /* background-color: #020202; */
    border-radius: 5px;
    height: 2.5rem;
    color: floralwhite;
  }

  .sliding_filter_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  .sliding_filter_item {
    padding: 0.5vw;
    /* font-size: 16px; */
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease, height 0.3s ease;
  }

  .sliding_selected_item {
    background-color: #1B1D21;
    color: #fff;
    height: 120%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease, height 0.3s ease;
  }

  /* button styles */

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0.6rem;
    border: #000;
    outline: none;
    border-radius: 5px;
    width: 100%;
    position: relative;
  }
  .notifyicon{
    position: absolute;
    background-color: #F93E3E;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    font-size: 12px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .button:focus {
    outline: 2px solid #4164E3;
    outline-offset: 2px;
  }

  /* .button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0.6rem;
  border: #000;
  outline: none;
  border-radius: 5px;
  width: 100%;
  cursor: no-drop;
} */

  .button svg {
    width: 24px;
    height: 24px;
    text-align: center;
  }

  .button p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 0; */
    text-align: center;
  }

  @media (width<400px) {
    .button {
      gap: 4px;
      padding: 0.3rem;
    }

    .button p {
      font-size: 12px;
      font-weight: 300;
    }
  }