.bg_dark {
    background-color: #000;
}

h1 {
    margin: 0;
    font-weight: normal;
}

::-webkit-scrollbar {
    display: none;
}

.text_center {
    text-align: center;
}

.d_none {
    display: none;
}

.muted_clr {
    color: #999;
}

/* ------------------------Products----------------------- */
.product_main_div {
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    padding: 16px;
}

.product_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.product_header u {
    cursor: pointer;
}

.product_filter {
    background-color: transparent !important;
    color: #fff !important;
    border: 0.826924px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 0 30px;
    padding: 10px;
    width: auto;
}

.product_scroll_div {
    width: 100%;
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
}

.productRow {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
    border-radius: 5px;
    color: #fff;
    margin-top: 10px;
}

.productRow h1 {
    font-size: 30px;
    font-weight: normal;
    margin: 0;
    cursor: pointer;
}

.productBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productsEVS {
    background: #18191B;
    box-shadow: 0px 2.92168px 2.92168px rgb(0 0 0 / 25%);
    text-align: center;
    margin-left: 40px;
    border-radius: 50px;
    cursor: pointer;
    width: 62px;
    padding: 10px;
    text-decoration: underline;
    font-size: 12px;
}

/* ------------------------Products Views----------------------- */
.product_st_div {
    align-items: center;
    padding: 10px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
    border-radius: 5px;
    color: #fff;
    margin: 10px 0;
    text-align: center;
}

.product_st_div h1 {
    font-size: 30px;
    margin-bottom: 20px;
}

.product_st_row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.product_st_col {
    width: 16.66667%;
    max-width: 290px;
    position: relative;
    border-radius: 10px;
    background-color: #000;
    margin: 5px;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
}

.product_st_col h6 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

.product_st_status {
    position: absolute;
    width: 100%;
    text-align: end;
    top: 5px;
    right: 5px;
}

.product_st_status_icon {
    height: 22px;
}

.product_st_btn_div {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
}

.product_st_btn {
    background-color: #1E2022;
    padding: 12px 16px 10px 16px;
    border-radius: 14px;
    border: none;
}

/* ------------------------Add Product----------------------- */
.product_right {
    width: 100%;
    background-color: transparent;
    max-height: calc(100vh - 84px);
    overflow-y: scroll;
    color: #ffffff;
    padding: 0 15px;
    position: relative;
}

.product_right h1 {
    margin: 10px 0;
}

.add_product_main_div {
    background-color: #1E2022;
    border-radius: 12px;
    display: block;
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.addProductRow {
    display: flex;
    flex-wrap: wrap;
}

.addProductCol {
    width: 50%;
}

.addProductColP {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
}

.addProductCol3 {
    width: 33.33%;
}

.addProductCol9 {
    width: 66.66%;
}

.add_product_input {
    width: 'fit-content';
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    width: 92%;
    resize: none;
}

.addBannerRow {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.addBannerCol9 {
    width: 66.66%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.bannerAddBtn {
    border: 1px solid #1FFC33;
    width: 'fit-content';
    padding: 11px 2px;
    text-align: center;
    border-radius: 5px;
    font-size: 10px;
}

.bannerImg {
    object-fit: contain;
    border: 1px solid rgb(129, 129, 129);
    border-radius: 5px;
}

.bannerImgInput {
    display: none;
}

.bannerUrlInput {
    width: 60px;
    background-color: transparent;
    color: #fff;
    padding: 10px;
    border: 1px solid rgb(129, 129, 129);
    border-radius: 5px;
    outline: none;
}

.bannerSelect {
    height: 35px;
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: white;
    min-width: 135px;
    border: 1px solid;
    width: auto;
}

.bannerDeleteBtn {
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
    text-align: center;
    padding: 3px;
    border-radius: 6px;
    color: #F93E3E;
    cursor: pointer;
    font-size: 12px;
}

.addMoreBtn {
    text-align: right;
    margin: 15px 0;
    text-decoration: underline;
}

.addMoreBtn button {
    cursor: pointer;
    background: transparent;
    border: 1px solid #1FFC33;
    color: #1FFC33;
    padding: 5px 15px;
    border-radius: 5px;
}

.addProductCol9 label {
    display: inline-flex;
    align-items: center;
    width: 130px;
}

.addProductCol9 input[type="radio"] {
    width: 25px;
    height: 25px;
}

.addBannerCol9 input[type="radio"] {
    width: 25px;
    height: 25px;
}

.addProductBtmBtn {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
    bottom: 20px;
    width: 100%;
}

.addItemBtmBtn {
    display: flex;
    justify-content: space-evenly;
    padding: 17px 0;
    width: 100%;
}

.addSTC {
    padding: 10px;
    background-color: #1E2022;
    color: #fff;
    border-radius: 10px;
    overflow-y: scroll;
    max-height: 760px;
    height: calc(100vh - 250px);
    position: relative;
}

.addBannerCol9 select {
    width: 'fit-content' !important;
}

/* --------------------FAQ------------------ */
.faq_left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.faq_right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10%;
}

.faq_textArea {
    padding: 10px;
    width: 70%;
    background-color: transparent !important;
    color: #fff !important;
    border: 0.826924px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 2px;
}

.addItemRow {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.textMandatory {
    text-align: end;
    color: red;
    font-size: 12px;
}

.selectReact {
    background-color: #023950;
    color: #000;
    text-align: left;
    width: 100%;
}

.w_100 {
    width: 100%;
}

.metaRow {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.meta {
    margin-right: 20px;
    margin-left: 20px;
    width: 15%;
}

.url {
    display: flex;
    align-items: center;
    width: 80.50%;
}

.add_url_input {
    width: 'fit-content';
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: 92%;
    resize: none;
}

.tag_main_div {
    display: flex;
    flex-wrap: wrap;
}

.addtag {
    display: flex;
    overflow-y: scroll;
    overflow-x: scroll;
    border-radius: 5px;
    color: black;
    width: 62.66%;
    padding: 7px 5px;
    border: 1px solid #fff;
}

.add_inputtag {
    border: none;
    outline: none !important;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    resize: none;
    width: 100%;
}

.tag {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 2px;
    border-radius: 2px;
    background-color: #f5f5f5;
    color: #000;
}

.tag_button {
    align-items: center;
    border: none;
    padding-left: 4px;
    padding-right: 4px;
}

.heading {
    font-weight: 600;
    font-size: 30px;
    margin: 0;
}

.deleteModal_text {
    font-size: 24px;
    margin-bottom: 20px;
}

.massageModal_btn {
    width: 320px;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
}













/* new css for product  */


.Main_Layout {
    width: 100%;
    /* border: 1px solid; */
    height: calc(100vh - 65px);
    padding: 10px 10px 0px 10px;
    position: relative;

}

.Layout {
    background-color: #fff;
    height: 100%;
    padding: 10px;
    position: relative;
    /* overflow-y: scroll; */
}

.Top_bar_css {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn_Continer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 195px);
    margin-top: 10px;
}

@media (max-width: 700px) {
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.Table_row {
    width: 100%;
    height: calc(100vh - 245px);
    margin-top: -5px;
    overflow-x: scroll;
}

.Bootem_pagenation {
    width: 100%;
    border: 1px solid;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
}

.mgx_pagination {
    /* height: 5vh; */
    width: calc(100% - 8px );
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    margin: 4px;
    left: 0;
    /* left: 50%;
    transform: translate(-50%); */
    /* width: 100%; */
    font-size: 12px;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}


.responsiveTable {
    list-style-type: none;
    padding: 0;
    position: relative;

    .tableHeader {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        display: flex;
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .tablerow {
        background-color: #ffffff;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 3px;
        padding: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        position: relative;
    }

    .col {
        flex: 1;
        padding: 5px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .col1 {
        flex: 1;
        padding: 0;
        /* border: 1px solid; */
        font-size: 12px;
    }

    @media all and (max-width: 700px) {
        .tableHeader {
            display: none;
        }

        .tablerow {
            display: block;
            margin-bottom: 15px;
        }

        .col1 {
            display: none;
        }

        .col {
            display: flex;
            justify-content: space-between;
            /* padding: 10px 0; */

            &:before {
                color: #aaaaaa;
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
                text-align: left;
            }
        }

    }

}



@media (width< 700px) {
    .gobackClass {
        display: none;
    }

    .btn_Continer {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .mgx_pagination {
        display: none;
    }

    .Main_Layout {
        padding: 0px;
    }
}

.Style_continer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* height: auto; */
    /* overflow-y: scroll; */
}

.Style_continer_text {
    font-size: 14px;
    font-weight: 600;
}

.Style_Card_continer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 8px;
    overflow-y: scroll;
    width: 100%;
    position: relative;
}
.Scroll_con{
    width: 100%;
    height: calc(100vh - 130px);
    /* border: 1px solid; */
    overflow-y: scroll;
}