.nm_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 68vw;
}

.nm {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.nm_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.nm_top p {
    margin: 0;
}

.nm_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nm_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.nm_top_left_go_back_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 0.21rem;
}

.nm_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    color: #646B88;
}

.nm_top_right {
    color: #646B88;
    font-weight: 500;
    font-size: 14px;
}

.nm_form {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    overflow-y: auto;
    width: 100%;
    padding: 0 10px;
}

.nm_form_section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nm_photo {
    position: relative;
    cursor: pointer;
    width: 100px;
    height: 100px;
    margin-right: auto;
}

.nm_photo img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.nm_camera_icon {
    position: absolute;
    top: 50%;
    right: -15%;
}

.nm_form_section p {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #646b88;
}

.inp_con {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
    border: 1px solid #E4E7EB;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.inp_con_dark {
    background-color: #222428;
    border-color: #222428;
}

.nm_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-top: auto;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    backdrop-filter: blur(10px);
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
}

.nm_footer_dark {
    background: #222428;
}

.nm_footer_1 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #646b88;
}

.nm_footer_1 .toggle_section {
    display: flex;
    gap: 1rem;
}

.nm_footer_1 .radio_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
}

.nm_footer_2 {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
}

.nm_order_manager {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nm_order_manager p {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.nm_footer_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    background-color: #ffffff;
    width: 100%;
    padding: 0.8rem;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001a;
    position: fixed;
    bottom: 0;
    display: none;
    z-index: 11;
}

.empty_div_for_spacing {
    height: 400px;
    width: 100%;
    display: none;
    background-color: #f8f9fc;
}

.wa_heading {
    margin-bottom: 0.5rem;
    color: #000;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.wa_form {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -2.4rem;
    width: 100%;
}

.wa_logout_text {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #f93e3e !important;
    text-decoration: underline;
    cursor: pointer;
    z-index: 10;
}

.wa_device_list {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -0.8rem;
    margin-bottom: -1rem;
}

.wa_device_list_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #749AFF;
    text-decoration: underline;
    cursor: pointer;
}

/* * new manager media query */

@media (width<820px) {
    .nm {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        background: #f8f9fc;
        overflow: auto;
        padding: 0;
    }

    .nm_form {
        flex-direction: column;
    }

    .nm_form_section {
        width: 80%;
    }
}

@media (max-width: 700px) {
    .nm_footer {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
    }

    .nm_top_left {
        display: none;
    }

    .nm_top_right {
        text-align: center;
        padding-top: 0.5rem;
        padding: 1rem 0.5rem 0rem 0.5rem;
    }

    .nm_photo {
        width: 60px;
        height: 60px;
    }

    .nm_form {
        overflow-y: unset;
    }

    .nm_form_section {
        width: 100%;
    }

    .nm_footer {
        border: none;
    }

    .nm_footer .nm_footer_2 {
        display: none;
    }

    .nm_footer_1 {
        padding: 0 0.5rem;
        padding-bottom: 10rem;
    }

    .nm_footer_mob {
        display: flex;
    }

    .empty_div_for_spacing {
        display: block;
    }
}


/* * tabs section   */
.inp_con>.tabs_section_label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: #646B88;
}

.tabs_section {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    margin-top: -0.6rem;
    border-radius: 4px;
}

.tabs {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tab {
    color: #4164e3;
    background-color: #f8f9fc;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.tab svg {
    margin-left: 0.2rem;
}

.section_border {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
}