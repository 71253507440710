.select_city {
  border: 0.5px solid rgba(245, 245, 245, 1) !important;
  margin-right: 16px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: hsl(0, 0%, 0%);
  text-align: start;
  padding-left: 6px;
}

.deliveryMethod_col {
  flex-direction: column;
  min-width: 15%;
  flex: 0 0 15%;
  padding: 0 6px;
  min-width: 180px;
  position: relative;
}

.deliveryMethod_card {
  background: #000;
  border-radius: 10px !important;
  text-align: center;
  padding: 0px 0 8px 0;
  box-sizing: border-box;
  min-height: 150px;
  min-width: 230px;
  /* border: 1px solid #ddd; */
}

.deliveryMethod_active {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(116, 185, 255, 1)
}

.card_slider_container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.card_slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin: 0 8px;
}

.sliderBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}

.sliderBtn:first-child {
  left: 0;
}

.sliderBtn:last-child {
  right: 0;
}

.form_main_div {
  align-items: center;
  position: relative;
  min-height: calc(100vh - 116px);
}

.form_main_div label {
  font-size: 16px;
  margin-bottom: 4px;
}

.form_main_div input {
  font-size: 16px;
  border: rgba(31, 252, 51, 1) 1px solid;
  border-radius: 5px;
}

.form_main_div select {
  font-size: 16px;
  border: rgba(31, 252, 51, 1) 1px solid;
  border-radius: 5px;
  text-align: left;
}

.form_main_div option {
  background: #18191b
}

.courier_name_div {
  display: flex;
  justify-content: space-between;
}

.courier_name_div select {
  font-size: 16px;
  /* border: rgba(31, 252, 51, 1) 1px solid; */
  border-radius: 5px;
  text-align: left;
}

.courier_name_div option {
  background: #18191b
}

.form_partner_div {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: .75rem; */
  /* border: 1px solid #fff; */
  /* border-radius: 5px; */
  /* padding: 12px 8px; */
  /* padding-left: 0; */
}

.message {
  text-align: center;
  margin: 30px 0;
  font-size: 24px;
}

.input_delete {
  background: transparent;
  border: 1px solid #fff;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  margin: auto;
  margin-bottom: 32px;
  color: #fff;
  font-size: 20px;
  max-width: 90px;
  line-height: 20px;
  text-align: center;
}

.massageModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
}

.loading_css {
  border: 1px solid #FFFFFF;
  height: 38px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px 0 8px;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
}

.main_div {
  max-height: calc(100vh - 50px);
  padding: 8px 16px;
}

.main_div h3 {
  font-size: 24px;
}

.delivery_downArrow {
  /* position: absolute; */
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 8px;
  left: 0;
}

.label_cityName {
  /* position: absolute; */
  font-size: 11px;
  line-height: 30px;
  width: 100%;
  color: #808080d6;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  left: 0;
}

.partner_scroll_div {
  overflow-y: scroll;
  max-height: calc(100vh - 396px);
  min-height: calc(100vh - 396px);
  margin-top: 8px;
}

.partner_scroll_div::-webkit-scrollbar {
  display: none;
}

/* .status_active input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

.status_active input[type='radio']:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  /* position: relative; */
  background-color: #1ffc33;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.status_disable input[type='radio']:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  /* position: relative; */
  background-color: #dc3545;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.status_active_txt {
  color: #1ffc33;
}

.select_unit option {
  background: #000;
  text-align: left;
}

.state_delivery_width {
  width: 33.33%;
}

.col_input {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  height: 100px;
}

.col_radio {
  width: 28%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding: 10px 0px;
  /* height: 60px; */
}

.col_radio_status {
  width: 32%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0px;
  /* height: 60px; */
}

.select_method_div {
  display: flex;
  margin: 20px 0;
}

.select_method_col {
  /* width: 20%; */
  display: flex;
  align-items: center;
}

.select_method_col label {
  font-size: 18px;
  font-weight: 600;
}

.select_Sub_method {
  /* margin-bottom: 10px; */
  border: 1px solid gray;
  padding: 10px 16px 24px 16px;
}

.select_Sub_method label {
  font-size: 18px;
  font-weight: 600;
}

.state_select_div {
  /* border border-success h-100 d-flex justify-content-between align-items-center px-2 rounded */
  font-size: 16px;
  border: rgba(31, 252, 51, 1) 1px solid;
  border-radius: 5px;
  max-height: 36px;
  min-height: 36px;
  line-height: 34px;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 0 0px 0 5px;
  font-weight: 400;
  color: #fff;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
}

.warehouse_list {
  position: absolute;
  width: 100%;
  margin-top: 65px;
  padding-right: 1.5rem !important;
}

.cities_list {
  position: absolute;
  width: 100%;
  margin-top: 65px;
  padding-right: 3rem !important;
}

.state_list {
  position: absolute;
  width: 100%;
  margin-top: 65px;
  padding-right: 3rem !important;
}

.subMethods_scroll_div {
  overflow-y: scroll;
  max-height: calc(100vh - 532px);
  min-height: calc(100vh - 532px);
  margin-top: 20px;
}

.subMethods_scroll_div::-webkit-scrollbar {
  display: none;
}

.partner_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin: 12px 0;
}

.input_dropdown {
  position: absolute;
  right: 0;
}

.courierName {
  white-space: nowrap;
}

.active_btn {
  color: #1FFC33;
}

.courier_btn {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  border: none;
}

.courier_btn div {
  min-width: 32px;
  font-size: 12px;
}