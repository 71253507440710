  .mgx {
  height: calc(100vh - 80px);
  width: calc(100% - 20px);
  margin: 10px auto;
  background-color: #f8f9fc;
  border: 1px solid #e4e7eb;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  position: relative;
}

.mgx_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mgx_top p {
  margin: 0;
}

.mgx_top_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mgx_top_left_go_back {
  display: flex;
  cursor: pointer;
}

.mgx_top_left_go_back_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 0.3rem;
}

.mgx_top_left_path_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

.mgx_top_right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mgx_middle {
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  width: 100%;
  background-color: #fff;

}

.mgx_middle>div {
  max-width: 200px;
  min-width: 150px;
}

.mgx_bottom {
  width: 100%;
  background-color: #fff;
  /* border-radius: px; */
  position: relative;
  height: calc(100vh - 340px);
  /* overflow-y: scroll; */
}

.mgx_data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 8px;
  /* padding: 5px 10px 10px 10px; */
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}
.maindivscroll{
  height: calc(100vh - 400px);
}




.mgx_manager_card {
  box-sizing: border-box;
}

.mgx_mob_filters {
  display: none;
}

.mgx_mob_filters_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin: 1rem auto; */
}

.mgx_pagination {
  /* height: 5vh; */
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px 4px 0px 0px;
}

/* superSHWARMA69@WALLICON.in */

@media (max-width: 700px) {
  .mgx {
    height: 100%;
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: auto;
    padding: 0;
  }

  .mgx_middle {
    display: none;
  }

  .mgx_top,
  .mgx_middle {
    border-radius: 0;
    border: none;
  }

  .mgx_top {
    display: none;
  }

  .mgx_bottom {
    border-radius: 0px;
    height: calc(100vh - 300px);
    /* padding: 1rem 0;
     */
  }

  .mgx_data {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .mgx_mob_filters {
    display: block;
  }

  .empty_div_for_spacing {
    height: 50px;
    width: 100%;
    background-color: #f8f9fc;
  }

  .mgx_pagination {
    display: none;
  }
}

@media (max-width: 430px) {
  .mgx_mob_filters_top {
    width: 99%;
  }

  .mgx_manager_card {
    width: 98%;
  }
}
.receipts_filter {
  border-radius: 15px;
  border: 1px solid #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.receipts_filter:hover {
  border-color: #A7A7A7;
  /* Change to desired hover color */
}

.receipts_filter:active {
  border-color: #A7A7A7;
  /* Active color */
}

.receipts_filter_dark {
  border: 1px solid #1B1D21;
}

.receipts_filter_dark:hover {
  border-color: #656565;
  /* Adjust hover color for dark mode */
}

.receipts_filter_active {
  border-color: #656565;
}