  .mainMenu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0%;
      left: 50px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 8px;
      z-index: 10;
  }

  .menuItem,
  .menuItemDark {
      cursor: pointer;
      position: relative;
      width: 170px;
  }

  .menuItemDark:hover {
      border-radius: 5px;
  }

  .menuItem:hover {
      border-radius: 5px;
  }

  .scrollDiv {
      display: flex;
      height: auto;
      max-height: 75%;
      flex-grow: 1;
      overflow-y: scroll;
      width: 100%;
      font-size: 12px;
      font-weight: 300;
      flex-direction: column;
      gap: 5px;
      margin-top: 3px;
  }

  .searchContainer {
      height: 20%;
      font-size: 12px;
      font-weight: 500;
  }

  .searchInput {
      outline: none;
      border: none;
      border-radius: 5px;
      height: 50%;
  }

  .lightHover,
  .darkHover {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      border-radius: 5px;
      padding: 5px;
  }

  .lightHover:hover {
      background-color: #D9DCE5;
  }

  .darkHover:hover {
      background-color: #2C2E33;
  }

  .checkboxLabel {
      cursor: pointer;
  }

  .menuItem_dark0,
  .menuItem0 {
      cursor: pointer;
      position: relative;
      max-height: 300px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 5px;
  }

  .sarchCon {
      height: 25%;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      line-height: 1;
  }

  .sarchInput {
      outline: none;
      border: none;
      height: 35px;
      width: 100%;
      border: 1px solid;
      line-height: 0;
      margin-top: 5px;
      border-radius: 5px;
      padding-left: 10px;
  }

  .sarchInput1 {
      outline: none;
      border: none;
      height: 35px;
      width: 100%;
      line-height: 0;
      border-radius: 5px;
      padding-left: 10px;
      font-size: 12px;
  }
















  .cl_checkbox {
      margin-left: 5px;
  }

  .inputTypeckack {
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      outline: none;
      cursor: pointer;
      border-radius: 8px;
  }





  /* From Uiverse.io by lenin55 */
  .cl_checkbox {
      position: relative;
      display: inline-block;
  }

  /* Input */
  .cl_checkbox>input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      z-index: -1;
      position: absolute;
      left: -10px;
      top: -8px;
      display: block;
      margin: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      /* background-color: rgba(0, 0, 0, 0.6); */
      box-shadow: none;
      outline: none;
      opacity: 0;
      transform: scale(1);
      pointer-events: none;
      transition: opacity 0.5s, transform 0.2s;
  }

  /* Span */
  .cl_checkbox>span {
      display: inline-block;
      width: 100%;
      cursor: pointer;
  }

  /* Box */
  .cl_checkbox>span::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      margin: 2px 11px 3px 0px;
      border: solid 2px;
      /* Safari */
      border-color: #0065FF;
      border-radius: 5px;
      width: 18px;
      height: 18px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
  }

  /* Checkmark */
  .cl_checkbox>span::after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(3px, 4px) rotate(-45deg);
  }

  /* Checked, Indeterminate */
  /* .cl_checkbox>input:checked,
.cl_checkbox>input:indeterminate {
    background-color: #34A853;
} */

  .cl_checkbox>input:checked+span::before,
  .cl_checkbox>input:indeterminate+span::before {
      border-color: #0065FF;
      background-color: #0065FF;
  }

  .cl_checkbox>input:checked+span::after,
  .cl_checkbox>input:indeterminate+span::after {
      border-color: #fff;
  }

  .cl_checkbox>input:indeterminate+span::after {
      border-left: none;
      transform: translate(4px, 3px);
  }

  /* Hover, Focus */
  .cl_checkbox:hover>input {
      opacity: 0.04;
  }

  .cl_checkbox>input:focus {
      opacity: 0.12;
  }

  .cl_checkbox:hover>input:focus {
      opacity: 0.16;
  }

  /* Active */
  .cl_checkbox>input:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
  }

  .cl_checkbox>input:active+span::before {
      border-color: #0065FF;
  }

  .cl_checkbox>input:checked:active+span::before {
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.6);
  }

  /* Disabled */
  .cl_checkbox>input:disabled {
      opacity: 0;
  }

  .cl_checkbox>input:disabled+span {
      color: rgba(0, 0, 0, 0.38);
      cursor: initial;
  }

  .cl_checkbox>input:disabled+span::before {
      border-color: currentColor;
  }

  .cl_checkbox>input:checked:disabled+span::before,
  .cl_checkbox>input:indeterminate:disabled+span::before {
      border-color: transparent;
      background-color: currentColor;
  }

  .Location_Main_Con {
      position: relative;
      /* width: 150px; */
  }

  .Location_list {
      position: absolute;
      top: 100%;
      z-index: 999;
      max-height: 200px;
  }

  .chack_Loction {
      display: flex;
      align-items: center;
      /* padding: 5px; */
      border-bottom: 1px solid #2C2E33;
      width: 100%;
      cursor: pointer;
      height: 40px;
  }

  .chack_Loction:hover {
      background-color: #2C2E33;
  }

  .chack_Loction_light {
      display: flex;
      align-items: center;
      /* padding: 5px; */
      border-bottom: 1px solid #b1b1b1;
      width: 100%;
      cursor: pointer;
      height: 40px;
  }

  .text_toi {
      text-align: center;
  }

  .chack_Loction_light:hover {
      background-color: #ccc;
  }


  .location_drop {
      display: flex;
      /* width: 150px; */
      align-items: center;
      gap: 8px;
      /* border: 1PX solid; */
  }

  .Who_Select3 {
      width: 100px;
      display: flex;
      padding: 8px;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 5px;
  }

  .Location_list1 {
      position: absolute;
      top: 100%;
      z-index: 999;
      border: 1px solid;
      padding: 8px;
  }

  .loction_scroll {
      max-height: 180px;
      overflow-y: scroll;
      font-size: 12px;
      font-weight: 400;
  }

  .noDatFound {
      width: 100%;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;
      height: 100%;
  }