.progress_bar_container {
  display: flex;
}

.progressBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 0;
  transform: rotate(0);
  width: 15%;
}

.radioButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 3rem;
}

.customRadio {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #4164e3;
  background-color: transparent;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.customRadio::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #4164e3;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.activeRadio::after {
  opacity: 1;
}

.activeRadio {
  border-color: #4164e3;
}

.lineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
}

.line {
  width: 2px;
  height: 100px;
  background-color: #646b88;
}

.progress_bar_text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  gap: 100px;
  text-align: left;
}

.progress_bar_text {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.progress_bar_text_head {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #646b88;
}




/* 
.progress_bar_container {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.progressBar {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-left: 2.5rem;
}

.radioButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customRadio {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #4164e3;
  background-color: transparent;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.customRadio::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #4164e3;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.activeRadio::after {
  opacity: 1;
}

.activeRadio {
  border-color: #4164e3;
}

.lineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #646b88;
}

.progress_bar_text_container {
  display: flex;
  align-items: center;
  gap: 55px;
  text-align: center;
  margin-top: 6px;
}

.progress_bar_text {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.progress_bar_text_head {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #646b88;
}

@media (max-width: 700px) {
  .progress_bar_container {
    flex-direction: row;
    width: 300px;
    max-height: 600px;
    margin-bottom: -5rem;
  }
  .progressBar {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    transform: rotate(0);
    width: 15%;
  }

  .radioButtonContainer {
    flex-direction: column;
    align-items: center;
  }

  .lineContainer {
    width: 2px;
    height: 100px;
  }

  .line {
    width: 2px;
    height: 100%;
  }

  .progress_bar_text_container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    gap: 100px;
  }
} */