.AppHome {
  background-color: #000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
}

.AppHome h1 {
  color: #f93e3e;
  font-size: 50px;
  font-weight: bold;
}

.AppHome h2 {
  margin-bottom: 0;
  max-width: 80%;
  text-align: center;
}

.AppHome p {
  max-width: 80%;
  font-size: 20px;
  line-height: 30px;
}

.ms50 {
  margin-left: 50px;
}

.AppHome div {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; */
  max-width: 80%;
}

.danger {
  height: 300px;
  color: #f93e3e !important;
}

/* / ------------------------------ Modal CSS ------------------------- / */
.custom_modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 80px;
}

/* / Modal content / */
.modal_content {
  background-color: #1e2022;
  color: #fff;
  width: 50%;
  max-width: 850px;
  min-height: 500px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.modal_content_white {
  background-color: #f7f8fa;
  color: #1e2022;
  width: 50%;
  max-width: 850px;
  height: 480px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

/* / Close button / */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.text_center {
  text-align: center;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.center h1 {
  margin-bottom: 30px;
}

/* / ----------------------------- End Modal CSS ------------------------- / */

.loginInput {
  padding: 10px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  width: 100%;
  position: relative;
  border-radius: 5px;
}

.loginEye {
  position: absolute;
  right: 0;
  margin-top: 10px;
  color: #fff;
  cursor: pointer;
}

.err {
  color: red;
  font-size: 12px;
  margin-bottom: 0;
}

.px10 {
  font-size: 14px;
  padding: 9px 10px;
}

@media screen and (max-width: 768px) {

  /* .ms50 {
        margin-left: 10px;
    } */
  .btnW {
    width: "fit-content";
    padding: 10px;
  }

  .danger {
    height: "fit-content";
  }

  .AppHome h1 {
    font-size: 32px;
  }

  .danger {
    height: 100px;
  }

  .AppHome h2 {
    font-size: 19px;
  }

  .AppHome p {
    font-size: 14px;
    margin: 4px 0 8px 0;
  }

  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
  }

  /* .input_Tage_lable {
    margin-left: 0px;
  } */
}

.home {
  background: #d9dce5;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;

}

.frame {
  width: 48%;
  height: 96%;
  border-radius: 8px;
  background: #4164e3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_Frame {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.home_Logo {
  align-self: flex-start;
}

.home_wallicon_heading {
  font-size: 60px;
  font-weight: 800;
  line-height: 90px;
  color: #fff;
  text-align: center;
}

.login_container {
  width: 48%;
  height: 96%;
  border-radius: 8px;
  background: #f8f9fc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
  width: 500px;
  height: 591px;
  border-radius: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login1 {
  width: 500px;
  height: auto;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sub_Login {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e4e7eb;
  padding: 20px 0;
}

.login_Header_Text h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #4164e3;
  text-align: left;
}

.login_Header_Text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.login_Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  gap: 1rem;
  width: 100%;
  margin-top: 3rem;
}

.login_Form_element {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  /* background-color: lightcoral; */
}

.login_Form_label {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  margin: 0;
  color: #646b88;
}

.login_Form_label_dark {
  color: #ffffff;
}

.login_Form_input {
  width: 100%;
  border: none;
  outline: none;
  padding: 16px 12px 16px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #e4e7eb;
  background-color: #fff;
  color: #1b1d21;
  /* outline: none; */
}

.login_Form_input:focus {
  border: 1px solid #4164e3;
}

.login_Form_input_dark {
  background-color: #1b1d21;
  border: 1px solid #1b1d21;
  color: #FFFFFF;
}

.login_Form_input::placeholder {
  color: #9C9797;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_Form button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #4164e3;
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 20px 15px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.login_Form button:focus {
  outline: 2px solid #4164e3;
  outline-offset: 2px;
}

.login_Form button p {
  margin: 0;
}

.login_button:disabled {
  background-color: #e4e7eb;
  cursor: not-allowed;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sub_Login a {
  align-self: flex-end;
  color: #0100e4;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 0.5rem;
}

@media (width<1100px) {
  .sub_Frame svg {
    height: 300px;
    width: 250px;
  }

  .home_Logo svg {
    height: 80px;
    width: 140px;
  }

  .home_wallicon_heading {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (width<600px) {
  .frame {
    display: none;
  }

  .home {
    background: #f8f9fc;
    justify-content: center;
  }

  .login_container {
    width: 96%;
  }

  .login_Form_input {
    padding: 10px;
  }

}

.input_group {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid; */
}



.pin_container {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  /* border: 1px solid; */
}

.pin_input {
  width: 100%;
  max-width: 53px;
  height: 53px;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 5px;
  outline: none;
  text-align: center;
}

.error_message {
  font-size: 12px;
  font-weight: 400;
  color: red;
}

.Device_Continer {
  width: 400px;
  height: auto;
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
}

.Device_Continer_fristp {
  line-height: 1;
  font-size: 18px;
  font-weight: 600;
}

.Device_Continer_SandP {
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
}

.timer {
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

}

.logoutText p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}

.logoutText1 p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}