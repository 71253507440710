.bg_white {
    /* background-color: #fff; */
    /* width: 100%; */
}

.bg_dark {
    /* background-color: #000000; */
    /* background-color: #000000; */
    background-color: black;
    color: #fff;
    width: 100%;
    padding: 10 14px;
}
.mgx_top_left_go_back {
  display: flex;
  cursor: pointer;
}
.mgx_top_left_go_back {
    display: flex;
    cursor: pointer;
  }
.heading {
    /* font-family: 'Playfair Display'; */
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.08em;
    margin: 15px 0;
    /* text-align: center; */
}

/* .packingHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
} */

.packingHeading h2 {
    margin: 0;
    width: 90%;
}

.customers {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;
    /* margin: 0 5px; */
}

/* .customers th, td {
    border: 1px solid;
} */

.bg th {
    border: none;
    padding: 8px;
    background: #1E2022;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg2 th {
    border: none;
    padding: 8px;
    background: #f2f2f2;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #1E2022;
    text-align: center;
}

.cursor tr {
    cursor: pointer;
    margin-top: 20px;
}

.bg2 td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #f2f2f2;
    text-align: center;
}

.customers th {
    padding-top: 12px;
    padding-bottom: 12px;

}

.customers td {
    padding: 10px 2px;

}

.packingTd {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;


}

.packingTd td {
    padding: 10px 2px;
    border: 1px solid;
}

/* -----------------------voucher---------------------- */
.voucher_main {
    width: 75%;
    margin: auto;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 50px;
    margin-top: 20px;
}

.voucher_maindiv {
    /* width: 98.2%; */
    width: 100%;
    /* background: white; */
    /* background: #1E2022; */
    /* box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16); */
    /* border-radius: 10px; */
    height: 100%;
    /* border: 12px #1FFC33; */

    /* padding:10px; */
    /* /* margin: 16px 0; 
    margin-left: 10px ; */
    background-color: white;

    position: relative;
}
.voucher_maindiv1 {
    width: 100%;
    /* background: black; */
    /* background: #1E2022; */
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    /* border-radius: 10px ; */
    padding: 20px 0 18px 0;
    margin: 10px 0;
    position: relative;
}

.backArrow {
    cursor: pointer;
    text-decoration: underline;
}

.voucher_heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.08em;
    text-align: center;
    /* margin: 0 0 15px 0; */
}

.voucher_flex1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    padding: 1px;
    
   
}
.voucher_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    /* padding: 2px; */
   
}
.packingvoucherth{

    position: sticky;  /* Makes the element stick to the viewport during scrolling */
    top: 0;            /* The element will stick to the top of its container */
    z-index: 1;        /* Sets the stack order of the element */

padding: 8px;
}
.voucher_flex1{
    display: flex;
    align-items: center;
    gap: 120px;
    white-space: nowrap;
}
.packingvouchertablediv {
    width: 100%;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  /* cursor: pointer; */
  border-radius: 5px;
}

.packingvouchertable {
     width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}


.voucherFlexDirection {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.packingVoucher_left {
    min-width: 300px;
    /* margin-top: 34px; */
}

.slipheading_div {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    /* margin: 10px; */
    margin-right: 500px;
    /* margin-bottom: 10px; */
    /* align-content: flex-start; */
}

.sliptext {
    font-weight: 300;
    font-size: 14px;
    color: #000000;
}
.sliptext1 {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.sliptextGustin {
    font-weight: 400;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
}

.slipDetailsTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: black;
    /* width: 50%; */
    padding-top: 8px;
    
}

.slipDetailsData {
    font-weight: 300;
    font-size: 14px;
    /* line-height: 10px; */
    color: black;
    /* width: 50%; */
    /* padding-top: 6px; */
}

.slipDetailsDataSelect {
    border: 1px solid #E4E7EB;
    border-radius: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding-top: 6px;
    text-align: left;
    margin-top: 4px;
}
.slipDetailsDataSelect1 {
    /* border: 1px solid; */
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding-top: 6px;
    text-align: left;
}

.slipDetailsDataSelect option {
    background-color: #1E2022;
    
}

.selectheading {
    font-weight: 400;
    font-size: 20px;
    margin: 20px 0 4px 0;
}

.select {
    width: 358px;
    height: 44px;
    border: 0.826924px solid #F3F3F3;
    border-radius: 2px;
    outline: none;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    background-color: #1E2022;
    text-align: left;
}


.btn_div {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    /* border: 1px solid red; */
    padding: 5px;
    height: auto;
    
    position: relative;

}
.packingvouchertd{
    padding: 8px;
  font-size: 12px;
  cursor: pointer;

}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background-color: black;
    color: white;
}
.packingvouchertbody{
    margin-top: 5px;
}
/* .packingvouchertr{
    border-bottom: 1px solid #646B88;
} */


.packingdispachstatusdiv{ display: flex;                         /* Enables flexbox layout */
    justify-content: flex-end;             /* Aligns items to the right (end) of the container */
    width: 100%;                           /* Makes the element take up the full width of its parent */
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2); /* Adds a shadow at the top of the element */
    padding: 5px;                   
    border-top-left-radius: 20px;          /* Rounds the top-left corner */
    border-top-right-radius: 20px;         /* Rounds the top-right corner */
    height: auto;                          /* Allows the height to adjust based on the content */
    position: absolute;                    /* Positions the element absolutely in relation to its nearest positioned ancestor */
    bottom: 0;  }



/*-------------------- modal-------------- */
.modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 180px;
}

.modal_content {
    width: 90%;
    max-width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #000;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.modal_contentdiv {
    width: 90%;
    max-width: 400px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.input {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;
    height: 40px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border-radius: 3px;
    max-width: 383px;
    padding: 0 0 0 8px;
    line-height: 36px;
}

.inputcolor {
    width: 50%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    /* background: #fff; */
    /* color: #000; */
    /* border: 0.5px solid #000; */
    border-radius: 3px;
}

.inputpack {
    width: 10%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
}

.inputcolorpack {
    width: 20%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #fff;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 3px;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.modalBtn_div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    /* max-width: 90px; */
    /* margin: 32px auto 5px; */
}

.flexdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}

.label {
    width: 50%;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
}

.fileimg {
    Width: 80px;
    Height: 50px;
    text-align: center;
    border: 1px solid #ffffff66;
}

.deleteicon {
    margin: 0;
    color: #F93E3E;
    font-weight: 300;
    font-size: 8.7524px;
    letter-spacing: 0.08em;
}

.deletediv {
    width: 46px;
    min-height: 46px;
    padding: 1px 0;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 2.1881px;
    /* border: 0.364683px solid #F93E3E; */
}

.voucherStatus {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding-right: 90px;
}

.status {
    color: #FF9B04;
    font-weight: 500;

}

.status_blue {
    color: #1E3DAA;
    font-weight: 500;

}

.statuscolor1 {
    color: #FF0D0D;
    font-weight: 500;

}

.statuscolor3 {
    color: #9747FF;
    font-weight: 500;

}
.statuscolor4{
    color: #00A052;
}
.statuscolor9{
    color: #FF9B04;
}
.statuscolor12{
    color: #FF7F50;
}
.orderStatus_Txt {
    font-size: 15px;
    padding-bottom: 4px;
    font-weight: 600;
}
.statuscolor8{
    color: red;

}

.packing_left {
    text-align: left;
}

.dot {
    font-size: 48px;
    margin-bottom: 13px;
    margin-right: 4px;
}

.status1 {
    color: red;
}

.status2 {
    color: green;
}

.status3 {
    color: #DDE100;
}

.closeicon {
    float: right;
}

.packers_float {
    float: right;
    margin-bottom: 20px;
}

/* --------------create-packers------------------ */

.packersheading {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin-top: 0;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.col {
    width: 44%;
}

.flex {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.packertext {
    width: 40%;
    font-size: 18px;
    color: darkgray;
}

.chooseProduct_heading {
    font-size: 18px;
    color: darkgray;
}

.createinputdark {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    color: #fff;
    background-color: #1E2022;
    border: 0.826924px solid #F3F3F3;
}

.createinput {
    width: 100%;
    height: 30px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    border: 0.826924px solid #F3F3F3;
}

.margin {
    margin: 10px;
    padding-right: 16px;
}

.box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 0.870311px solid darkgray;
    padding: 4px;
    width: 100%;
    max-width: 600px;
}

.products {
    margin: 5px;
    padding: 2px 8px;
    border-radius: 3.94832px;
    border: 1.74062px solid #1FFC33;
}

.radiobtn {
    accent-color: green;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.radiobtnred {
    accent-color: red;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.createbtn {
    width: 50%;
    display: flex;
    margin: 20px auto;
    justify-content: space-around;
}

.createpackers_btnone {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #F93E3E;
}

.createpackers_btnone:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #F93E3E;
    border: 1px solid #F93E3E;
}

.createpackers_btn {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #1FFC33;
}

.createpackers_btn:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #1FFC33;
    border: 1px solid #1FFC33;
}

.itemInputs {
    display: inline;
    margin: 6px;
    border: 0.5px solid darkgray;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
    border-radius: 5px;
}

.itemInputs_selected {
    display: inline;
    margin: 6px;
    border: 0.5px solid #1FFC33;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

/* --------------------------------------------------------------------------------------- */
.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

@media (max-width: 1400px) {
    .heading {
        font-size: 26px;
    }
}

.complain_header_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.complain_Sort_By {
    /* padding-right: 30px; */
}

.complain_sortBy_txt {
    font-size: 18px;
    padding-right: 8px;
}

.complain_sortBy_select {
    border: 2px solid #3A3A3A;
    height: 44px;
    width: 140px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
}

.complain_sortBy_select option {
    color: #fff;
    background-color: #000;
    text-align: left !important;
}

.complain_search_div {
    position: relative;
    width: 12%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 150px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.addAdjestmentBtn {
    color: #FFFFFF;
    line-height: 24px;

}

.margin_top {
    margin-top: 20px;
}

/* -----------------------------------------------orders------------------------------------------------------ */
.table_heading_div {
    box-sizing: border-box;
    height: 68px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    /* padding: 0 40px; */
}

.table_heading_20 {
    width: 20%;
    text-align: center;
}

.table_heading_15 {
    width: 15%;
    text-align: center;
}

.table_heading_10 {
    width: 10%;
    text-align: center;
}

.table_content_div {
    height: 56px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 12px 0;
    /* padding: 0 40px; */
    cursor: pointer;
}

.table_txt_20 {
    width: 20%;
    text-align: center;
}

.table_txt_15 {
    width: 15%;
    text-align: center;
}

.table_txt_10 {
    width: 10%;
    text-align: center;
}

.table_txt_green {
    color: #05FF00;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}


.product_date {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-transform: uppercase;
    /* color: #9D9D9D; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    padding: 0 20px;
}

.itemCard_div {
    overflow-y: scroll;
    height: 420px;
    padding: 0 20px;
}

.itemCard {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #18191B;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemCard_light {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
}

.itemCard_left {
    display: flex;
    width: 40%;
}

.itemImage {
    width: 74px;
    height: 90px;
    margin: 0 12px;
}

.itemDetail_div {
    width: 100%;
    letter-spacing: 2px;
}

.itemDetail {
    display: flex;
}

.itemName_txt {
    width: 50%;
    font-size: 16px;
    font-weight: 400;
}

.itemName {
    width: 50%;
    /* padding-right: 8px; */
}

.itemCard_right {
    padding-right: 16px;
}

.modalFooter_div {
    width: 100%;
    height: 100px;
    background: #0f0f0f00;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalFooter_div_light {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}

.modalFooter_btn {
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

.modalFooter_GTotal {
    width: 45%;
    padding: 0 20px;
}

.borderBottom_div {
    border-bottom: 1px solid #6B6B6B;
    letter-spacing: 1px;
}

.total_div {
    display: flex;
    justify-content: space-between;
}

.total_rs {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.grandTotal {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    text-transform: capitalize;
    /* color: #FFFFFF;  */
}

.message {
    text-align: center;
    margin: 25px 0;
    font-size: 16px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-evenly;
    width: 300px;
    margin: auto;
    padding-bottom: 20px;
    /* gap: 1px; */
}


.dealer_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 214px;
    justify-content: center;
    padding: 1px 5px;
}

.dealer_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
    position: relative;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    position: relative;
}

.heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
    /* max-height: calc(100vh - 400px);
    min-height: calc(100vh - 400px); */
}

.heightFix_div::-webkit-scrollbar {
    display: none;
}

.dispatchHeightFix {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
}

.dispatchHeightFix::-webkit-scrollbar {
    display: none;
}

/* ------------------------------------Dispatch-Dashboard--------------------------------------------------- */
.DispatchMainDiv {
    color: #FFFFFF;
}

.Sort_By {
    padding-right: 30px;
}

.sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.sortBy_select {
    border: 1px solid #ffffff;
    height: 38px;
    width: 170px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.createPackerBtn {
    color: #FFFFFF;
}

.itemDescription_div {
    display: flex;
    /* width: 60%; */
    justify-content: space-between;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    /* border: 1px solid #1FFC33; */
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    /* border: 3px solid #00000045; */
    border-radius: 8px;
}

.view_Item_Image {
    width: 190px;
    height: 260px;
    display: flex;
    margin: 10px auto;
}

.demoImage_div {
    width: 86px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------------------------------------Create-Packers----------------------------------------------- */
.mainHeading {
    margin: 10px 0 16px 0;
    font-weight: 600;
    font-size: 30px;
}

.createPacker_container {
    background-color: #1E2022;
    border-radius: 10px;
    padding: 10px 0;
}

.radiobtn_div {
    display: flex;
    justify-content: left;
    align-items: center;
}

.radioBtn_heading {
    font-size: 18px;
    color: darkgray;
}

.packingVoucherDiv {
    /* width: 100%; */
    /* width: 90%; */
    /* margin: auto; */
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    gap: 50%;
}


.fixTableHead {
    overflow-y: auto;
    height: 38.5vh;
    color: #fff;
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead_packing {
    overflow-y: auto;
    height: 40.5vh;
    color: #fff;
    /* color: #fff; */
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead_packing thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    height: 46vh;
}

.fixTableHead_packing table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    /* height: 46vh; */
}

.fixTableHeadtd {
    padding: 4px 8px;
    border: 1px solid #fff;
}

.h10 {
    height: 10px;
}

.h10 td {
    padding: 4px 8px;
    border: 1px solid rgba(128, 128, 128, 0.366);
    color: #000;
    margin: 20px;
    /* gap: 1px; */
    /* border-bottom: none!important; */
}

.brderTr tr td {
    border: none !important;
}

.fixTableHead thead th {
    background: #000;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #fff;
}

.fixTableHead_packing thead th {
    background: #E4E7EB;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #E4E7EB;
    color: #000;
}

.packing_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 200px;
    justify-content: center;
    padding: 1px 5px;
}

.packing_days_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
}

.packing_days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}


.displayflexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enterPackets {
    width: 14%;
    height: 30px;
    outline: none;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 6px;
}

.checkbox {
    width: 15px;
    height: 15px;
}

.label_div {
    width: 44%;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
    line-height: 30px;
}

.packingImage {
    width: 80px;
    height: 50px;
    border: 0.441703px solid red;
    /* border: 0.441703px solid #D6D6D6; */
    padding: 3px;
}

.packingDeletediv {
    width: 44px;
    padding: 3px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    border: 0.364683px solid #F93E3E;
}

.packingModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.crossIcon {
    line-height: 42px;
    cursor: pointer;
}

.ordersNotiDiv {
    width: 100%;
    position: absolute;
    top: 0;
    color: #FFFFFF;
}

.ordersNoti {
    background: #2196F3;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 14px;
    margin: auto;
}

.disableBtn {
    color: gray;
    border: 1px solid gray;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.statusCard {
    box-sizing: border-box;
    width: 14%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 4px;
}
/* Wrapper around cards, keeps the layout responsive */
.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
    padding: 10px;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Card Styles */
.statusCard_div .order_filter {
    flex: 1 1 140px; /* This allows cards to grow and shrink with a minimum width */
    margin-right: 10px;
    margin-bottom: 10px; /* Adds space between filter items */
    padding: 8px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

/* Media Query for Tablets and Below */
@media (max-width: 768px) {
    .statusCard_div {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the left */
        padding: 15px;
    }

    .statusCard_div .order_filter {
        min-width: 100%; /* Full width on smaller screens */
        margin-bottom: 15px; /* Space between filter items */
        font-size: 14px; /* Adjust font size */
    }

    .statusCard_div .order_filter .dealerStatusCard {
        font-size: 12px; /* Make text smaller in the cards */
    }

    .statusCard_div span {
        font-size: 12px; /* Smaller font size for status count */
        margin-top: 10px;
    }

    .statusCard_div .statusTxt {
        font-size: 14px; /* Smaller font size for status text */
    }
}

/* Mobile-specific styles */
@media (max-width: 480px) {
    .statusCard_div {
        padding: 12px;
    }

    .statusCard_div .order_filter {
        min-width: 100%; /* Ensure filters take full width */
        font-size: 12px; /* Smaller font size for mobile */
    }

    .statusCard_div .statusTxt {
        font-size: 12px; /* Adjust font size for smaller screens */
    }

    .statusCard_div .order_filter .dealerStatusCard {
        font-size: 10px; /* Even smaller font size in cards */
    }
}
/* Card container, adjusts to screen size */
.order_filter {
  flex: 1 1 140px; /* Allows the card to grow and shrink with a minimum width of 140px */
  margin-right: 10px; /* Adds space between the cards */
  margin-bottom: 10px; /* Adds space between cards when they wrap */
  padding: 8px;
  box-sizing: border-box;
}

/* When the screen width is less than 768px (tablets and smaller devices) */
@media (max-width: 768px) {
  .order_filter {
    min-width: 100%; /* Make cards take full width on smaller screens */
    font-size: 14px; /* Adjust font size */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 15px; /* Space between cards */
  }

  .order_filter .dealerStatusCard {
    font-size: 12px; /* Adjust font size inside the card */
  }
}

/* When the screen width is less than 480px (mobiles) */
@media (max-width: 480px) {
  .order_filter {
    min-width: 100%; /* Cards take full width */
    font-size: 12px; /* Smaller font size */
    margin-bottom: 12px; /* Space between cards */
  }

  .order_filter .dealerStatusCard {
    font-size: 10px; /* Make text smaller inside the card */
  }
}


.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
    ;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusPacking {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
    height: 32px;
    padding: 0 2px;
}


.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.startEndDate_div {}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    margin-right: 16px;
    outline: none;
    text-align: center;
    border: 2px solid #3A3A3A;
}

.dragImage_div {
    /* display: flex;
    /* justify-content: center; 
    align-items: center;
    flex-direction: column; 
    /* border: 1px dashed #FFFFFF; */
    border-radius: 16px;
    /* padding: 16px 0 8px 0; */
    /* margin: 12px 0; */
    /* max-height: 300px; */
    /* border: 1px solid fuchsia; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}
.drag_txt1 {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
    color: #9C9797;
}

/* .border_OR_div {
    border: 1px solid #FFFFFF;
    width: 160px;
    margin: 0;
} */

.OR_css {
    position: absolute;
    margin-top: 36px;
    background: #1E2022;
    padding: 0 10px;
}

.browseFiles_btn {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}
.browseFiles_btn1 {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    /* background: red; */
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}

.checkBox_addImage {
    display: flex;
    justify-content: left;
    align-items: center;
}

.main_div {
    position: relative;
}

.dragAndDrop_div {
    height: 244px;
    position: absolute;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    margin-top: 12px;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
}

.source_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 250px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    margin: 0 5px;
    cursor: pointer;
}

.source_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.startEndDate_div {
    margin-left: 40px;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.order_heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 438px);
    min-height: calc(100vh - 438px);
}

.order_heightFix_div::-webkit-scrollbar {
    display: none;
}

.packing_image {
    background: transparent;
}
.packing_image1 {
    background: transparent;
    width: 100%;  /* Adjust width as needed (e.g., 100% to fill container, or fixed size like 500px) */
    height: auto; /* Keeps the aspect ratio, or set a specific height like 300px */
}

.view_packingImage {
    width: 120px;
    height: 46px;
    cursor: pointer;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    border-radius: 4px;
    /* border: 0.729367px solid #1FFC33; */
}

.packingStatus_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.packingImagefull {
    height: 100%;
}

.modal_close_button_div {
    display: flex;
    justify-content: right;
}

.modal_close_btn {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;

}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.manager_status {
    color: red !important;
}

.infoIcon {
    font-style: italic;
    border: 1px solid;
    border-radius: 50%;
    padding: 0 5px 0px 4px;
    font-size: 9px;
    color: red;
    text-transform: lowercase;
}

.editRequestButton {
    min-width: 216px;
}

.reqIssue {
    /* background-color: white; */
    /* border: 1px solid #FFFFFF; */
    width: 80%;
    padding: 7px 5px;
    border-radius: 5px;
    color: black;
    margin: 0px auto 40px;
    display: flex;
    text-align: center;
}
.mainCon{
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 65px);
    padding: 10px 10px 10px;
}


.packerdiv{align-items: center;}

.select_box {
    width: 100%;
    border-radius: 5px;
    border: none;
    text-align: left;
    outline: none;
    /* margin-left: 10px; */
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    font-size: 12px;
    font-weight: 400;
    display: flex;
  justify-content: flex-start;
  font-size: 16px;
}
.totalitemdiv{
    width: 100%;
}
.DropDown2div{
    width: 300px;
    margin-left: 10px;
}
.neworderbottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

/* Hide the .packingVoucherDiv div for screens between 350px and 700px */
@media (min-width: 1px) and (max-width: 700px) {
    .packingVoucherDiv {
      display: none; /* Hide the div within this screen width range */
    }
  }
  @media(min-width:1px)and(max-width:700px)
  {
    .dispatch_top_leftmob{
display: block;
    }
  }


