 .tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  
  .tabsList {
    width: 100%;
    display: flex;
    gap: 8px;
  }
  
  .tabsTrigger {
    border: none;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease-in-out;
    padding: 10px;
    font-size: 12px;
  }
  
  
  .tabsTrigger.active {
    background-color: #000000;
    color: white;
  }
  
  .tabsTrigger.active1 {
    background-color: #14181f;
    color: white;
  }
  .tabsTrigger {
    border: none;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease-in-out;
}
  
 


  