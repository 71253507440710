 /* .mainContiner {
     max-width: 800px;

     display: flex;
     flex-direction: column;
     background-color: #2C2E33;
     box-sizing: border-box;
     padding: 20px;
     border-radius: 20px;
 }

 .mainContiner_light {
     max-width: 800px;
     height: auto;
     display: flex;
     flex-direction: column;
     background-color: #ffffff;
     box-sizing: border-box;
     border-radius: 20px;
     padding: 20px;
 }

 .formContiner {
     max-width: 800px;
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
     flex-wrap: wrap;
     gap: 10px;
     box-sizing: border-box;
     overflow-y: scroll;

 }

 .dealer_form_header {
     display: flex;
     justify-content: space-between;
     font-size: 14px;
     font-weight: 600;
     line-height: 21px;
     color: #646B88;
 }

 .inputbox {
     min-width: 370px;
     width: 45%;
     display: flex;
     flex-direction: column;
     gap: 5px;
 }
 .btnContiner {
     width: 40%;
     height: 40px;
     align-self: center;
     display: flex;
     gap: 30px;
     min-width: 350px;
     margin-top: 1rem;
 }

 .error {
     color: red;
     font-size: 10px;
     line-height: 1;
     margin: 0;
 } */


 .mainContiner {
     /* max-width: 800px; */
     display: flex;
     flex-direction: column;
     background-color: #2C2E33;
     box-sizing: border-box;
     padding: 10px;
     border-radius: 20px;
 }

 .mainContiner_light {
     /* max-width: 800px; */
     height: auto;
     display: flex;
     flex-direction: column;
     background-color: #ffffff;
     box-sizing: border-box;
     padding: 10px;
     border-radius: 20px;
 }

 .formContiner {
     width: 100%;
     display: flex;
     flex-direction: column;
     flex-wrap: nowrap;
     gap: 10px;
     box-sizing: border-box;
 }

 .formContiner>div {
     display: flex;
     justify-content: space-between;
     gap: 10px;
     width: 100%;
 }

 .dealer_form_header {
     display: flex;
     justify-content: space-between;
     font-size: 14px;
     font-weight: 600;
     line-height: 21px;
     color: #646B88;
 }

 .inputbox {
     /* min-width: 370px;
     width: 45%; */
     display: flex;
     flex-direction: column;
     gap: 5px;
     justify-content: flex-end;
     min-width: 200px;
 }

 .pin_code_res {
     position: absolute;
     top: 0;
     color: #646B88;
 }

 .pin_code_res_dark {
     color: #ffffff;
 }

 .pin_code_res>p {
     margin: 0;
     font-size: 14px;
 }

 .btnContiner {
     width: 40%;
     height: 40px;
     align-self: center;
     display: flex;
     gap: 30px;
     min-width: 350px;
     margin-top: 1rem;
 }

 .error {
     color: red;
     font-size: 10px;
     line-height: 1;
     margin: 0;
 }

 .inp_check_mark {
     position: absolute;
     top: 0;
     right: 0;
 }

 .inp_check_mark>input {
     cursor: pointer;
 }

 .inp_check_mark>input:disabled {
     cursor: not-allowed;
 }