.st0 {
  fill: #4E4E4F;
}

.st1 {
  fill: #4D4E4E;
}

.AppHome {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
}

.App {
  background-color: #282c34;
  height: (100vh - 66px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
}

.spinner {
  display: block;
  min-width: 5px;
  min-height: 5px;
  border: 2px solid #000;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* --------------Loading------------- */
.loadingMain {
  height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Mob_loading {
  height: calc(100vh - 480px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Mob_dispatch_loading {
  height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loadingMainDealer {
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.order_loadingMain {
  height: calc(100vh - 350px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* ----------------------------Home----------------------------- */
.loginInput {
  padding: 10px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  width: 100%;
  position: relative;
  border-radius: 5px;
}

.loginEye {
  position: absolute;
  right: -15px;
  margin-top: -30px;
  color: #fff;
  cursor: pointer;
}

/* ------------------------------------------Products ------------------------------ */
.products {
  display: flex;
  margin: 10px 0;
  padding: 20px;
  align-items: center;
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  color: #fff;
}

.product_sub_heading {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.productsEVS {
  background: #18191B;
  box-shadow: 0px 2.92168px 2.92168px rgb(0 0 0 / 25%);
  text-align: center;
  margin-left: 40px;
  border-radius: 50px;
  cursor: pointer;
  width: 45px;
  padding: 10px;
  text-decoration: underline;
  font-size: 12px;
}

.product_main_div {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 16px;
}

.product_scroll_div {
  overflow-y: scroll;
  max-height: 816px;
}

/* .product_scroll_div::-webkit-scrollbar {
  display: none;
} */

.add_product_main_div {
  background-color: #1E2022;
  border-radius: 12px;
  display: block;
  max-height: 804px;
  min-height: 804px;
  overflow-y: scroll;
}

.add_product_main_div::-webkit-scrollbar {
  display: none;
}

.product_style_card {
  position: relative;
  background-color: #000000;
  width: 276px;
  max-width: 276px;
  min-width: 276px;
  height: 214px;
  max-height: 214px;
  min-height: 214px;
  border-radius: 12px;
  text-align: center;
  margin: 10px;
}

.product_status {
  position: absolute;
  width: 100%;
  text-align: end;
  /* padding-top: 12px; */
  top: 5px;
  right: 5px;
}

.product_status_icon {
  height: 22px;
}

.product_card_div {
  margin: auto;
  text-align: center;
  padding-top: 20px;
}

.product_image {
  width: 72px;
  height: 66px;
}

.style_name {
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

.product_btn_div {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  /* padding: 0 30px;
  width: 60%;
  margin: 14px auto; */
}

.product_Btn {
  background-color: #1E2022;
  padding: 12px 16px 10px 16px;
  border-radius: 14px;
  border: none;
}

.product_status_main_div {
  overflow-y: scroll;
  max-height: 780px;
}

.product_status_main_div::-webkit-scrollbar {
  display: none;
}

.product_status_padding {
  padding: 10px 20px;
}

.product_addfaq_div {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.addfaq_input_div_left {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.addfaq_input_div_right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10%;
}

.addfaq_fs {
  font-size: 20px;
  /* padding-right: 30px; */

}

.addfaq_textArea {
  font-size: 18px;
  padding: 10px;
  width: 70%;
  background-color: transparent !important;
  color: #fff !important;
  border: 0.826924px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 2px;
}

.addfaq_delete_btn {
  color: #F93E3E;
  box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
  font-size: 12px;
  padding: 5px 5px;
  border-radius: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.delete_txt {
  display: block;
}

.addfaq_addMore_div {
  display: flex;
  justify-content: left;
  margin: 10px 0;
  text-decoration-line: underline;
  font-size: 20px;
  padding-left: 10.5%;
  cursor: pointer;
}

.addfaq_main_div {
  min-height: 760px;
  position: relative;
}

.addfaq_btn_div {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.addstyle_main_div {
  padding: 10px;
  background-color: #1E2022;
  color: #fff;
  border-radius: 10px;
  font-size: 20px;
  overflow-y: scroll;
  /* min-height: 760px; */
  max-height: 760px;
  height: auto;
}

.addstyle_main_div::-webkit-scrollbar {
  display: none;
}

.css-1s2u09g-control {
  background-color: black !important;
}

.css-1pahdxg-control {
  background-color: black !important;
  color: #000 !important;
}


.games-dropdown-2 {
  background-color: #023950;
  color: #000;
  text-align: left;
}

/* ------------card css----------------- */

.styleRow {
  display: flex;
  flex-wrap: wrap;
  /* padding: 3px; */
  /* justify-content: space-between; */
}

.styleCol {
  flex: 0 0 auto;
  /* padding: 2px; */
  width: 18%;
}

.styleCard {
  position: relative;
  border-radius: 10px;
  background-color: #000;
  margin: 5px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
}

/* ---------------------------------Add-Item--------------------------------------------------- */
.add_item_main_div {
  color: #FFFFFF;
  padding: 0 15px;
}

.add_item_content_div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.addItem_addMore_div {
  display: flex;
  justify-content: right;
  text-decoration-line: underline;
  font-size: 20px;
  cursor: pointer;
}

.addmore_color_div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.add_color {
  font-size: 24px;
  border: 1px solid #1FFC33;
  border-radius: 20px;
  padding: 0px 10px;
  line-height: 1.8rem;
  margin-left: 14px;
  color: #1FFC33;
  font-weight: 600;
  cursor: pointer;
}



/* -------------------------main-container------------------------- */

/* .main_container {
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
} */

/* -------------------------------------Header---------------------------- */
.header {
  background-color: #1E2022;
  height: 66px;
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.wallIcon_image {
  width: 66px;
  max-width: 66px;
  max-height: 50px;
}

/* -------------------------------LeftMenu------------------------------- */
.content {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 66px);
  max-width: 1920px;
  margin: auto;
}

.PackingContent {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-width: 1920px;
  margin: auto;
}

.active_leftMenu {
  color: #24ABF8;
}

/* -------------------------------RightItem------------------------------- */
.right {
  width: 100%;
  background-color: transparent;
  max-height: calc(100vh - 84px);
  overflow-y: scroll;
  color: #ffffff;
  padding: 0 15px;
}

.right::-webkit-scrollbar {
  display: none;
}

.leftMenu {
  display: flex;
  flex-direction: column;
  width: 66px;
  max-width: 66px;
  min-width: 66px;
  max-height: calc(100vh - 66px);
  overflow-y: scroll;
}

.leftMenu::-webkit-scrollbar {
  display: none;
}

.menuItem_div {
  text-align: center;
  margin-top: 2px;
  width: 100%;
  padding: 14px 0;
  background-color: #1E2022;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
}

.iconImage {
  display: block;
  margin: auto;
  width: 24px;
}

.headerItems_div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header_item {
  padding-right: 50px;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.logout {
  position: absolute;
  background: dimgray;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.w_50 {
  width: 50%;
}

.w_40 {
  width: 40%;
}

.toggleBtn {
  background-color: #ffffff;
  width: 60px;
  border-radius: 25px;
  height: 25px;
  color: #000000;
}

.icon {
  max-width: 24px;
  margin-top: 4px;
}



/* ----------------------------------------------------------Dealer---------------------------------------------------------- */
.dealers {
  font-weight: 600;
  font-size: 30px;
  margin-top: 16px;
}

.Dealer_main_container {
  background-color: #1E2022;
  border-radius: 12px;
  display: block;
}

.dealer_header {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  line-height: 1.5rem;
  justify-content: space-between;
}

.dealer_header_right {
  display: flex;
  flex-direction: row;
}

.dealer_search {
  border-radius: 50px;
  height: 49px;
  padding-left: 30px;
  padding-right: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: #ffffff;
  outline-offset: 0px !important;
  outline: none;
  width: 259px;
}

.dealer_search_div {
  position: relative;
  width: 282px;
}

.dealer_search_icon {
  position: absolute;
  left: 8px;
  top: 15px;
}

.dealer_Sort_By {
  padding-right: 30px;
}

.dealer_sortBy_txt {
  font-size: 20px;
  padding-right: 12px;
}

.dealer_sortBy_select {
  border: 1px solid #ffffff;
  height: 49px;
  width: 234px;
  background-color: transparent;
  color: #ffffff;
  outline: none;
  padding-left: 12px;
}

.dealer_sortBy_color {
  color: #000000;
  background-color: #ffffff !important;
}

.dealer_header_select_days {
  max-height: 49px;
  font-size: 13px;
  color: black;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  align-items: center;
  width: 420px;
  justify-content: center;
}

.dealer_days_select {
  width: 102px;
  text-align: center;
  border-radius: 25px;
  padding: 8px 0px;
  cursor: pointer;
}

.days_active {
  background-color: #E3E7EF;
  cursor: pointer;
  font-weight: bold;
}

.dealer_main_div {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5rem;
}

.dealer_main_div::-webkit-scrollbar {
  display: none;
}

.dealer_card {
  position: relative;
  width: 16.66666%;
}

.dealer_card_div {
  margin: 10px;
  padding: 10px;
  background-color: #000000;
  border-radius: 24px;
  text-align: center;
  /* height: 250px; */
}

.dealer_image {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  display: block;
  margin: auto;
  object-fit: cover;
}

.dealer_name {
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 0px;
}

.dealeStore_owner {
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: gray;
}

.dealer_btn_div {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0 30px;
}

.dealer_Btn {
  background-color: #1E2022;
  padding: 12px 16px 10px 16px;
  border-radius: 14px;
  border: none;
}

.dealer_status {
  position: absolute;
  width: 100%;
  text-align: end;
  right: 0;
}

.dealer_status_icon {
  padding-right: 12px;
}

@media (max-width:1680px) {
  .dealer_card {
    width: 20%;
  }
}

@media (max-width:1400px) {
  .dealer_card {
    width: 25%;
  }
}

@media (max-width:1200px) {
  .dealer_header_select_days {
    width: 296px;
  }

  .dealer_days_select {
    width: 72px;
  }

  .dealer_sortBy_select {
    width: 160px;
  }

  .dealer_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
  }

  .dealer_days_select {
    font-size: 12px;
  }
}

@media (max-width:1100px) {
  .dealer_card {
    width: 33.333%;
  }
}

.modalContent .modal-content {
  background-color: #1E2022 !important;
  color: #fff !important;
}

.modalContent input {
  border-color: #9A9A9A;
  color: #fff;
}

.modalContent select {
  border-color: #9A9A9A;
}

.modalContent textarea {
  border-color: #9A9A9A;
}

.h4,
h4 {
  font-size: inherit !important;
}

/* ------------------------------KYC----------------------- */

.modal1 {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 1;
  /* Overlay effect: positioned over other containers */
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  position: fixed;
  /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto;
  /* Enable scroll if needed */
  padding-top: 80px;
  /* Location of the content container */
}

/* Modal content */
.modal_content {
  background-color: white;
  /* width: 70%;  */
  max-width: 640px;
  /* Max width where it stops expanding */
  /* height: 70%; */
  margin: auto;
  /* Auto margin according to the element width */
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
  /* Optional. Rounds container corners */
}


.close {
  color: #aaaaaa;
  float: right;
  /* Positioned to the right of the parent container whichever size it is */
  font-size: 25px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* -------------------------------------------------------------kyc-new-css-------------------------------------------------------------------------- */
.kyc_main_container_div {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 16px;
}

.d_flex_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kyc_main_div {
  padding: 16px;
  width: 100%;
  color: #fff;
  height: calc(100vh - 116px);
}

.kyc_sub_main_div {
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  max-width: 1920px;
  margin: auto !important;
  background-color: #1E2022;
}

.kyc_main_heading {
  font-size: 30px;
  text-align: center;
  margin: 16px;
}

.socialMedia_icons {
  position: absolute;
  display: inline;
  border: 1px solid;
  border-right: none;
  padding: 7px 5px 4px 5px;
  text-decoration: none;
}

.kyc_scroll_div {
  width: 100%;
  overflow-y: scroll;
  max-height: 780px;
}

.kyc_scroll_div::-webkit-scrollbar {
  display: none;
}

.text_end {
  text-align: end;
}

.mlrb {
  margin: 0px 16px 24px 16px;
}

.store_Image_div {
  width: 92%;
  height: 80%;
}

.profileAndStore {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  margin-top: 10px;
}

.profile_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 80%;
  height: 80%;
  font-size: 14px;
}

.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  height: 300px;
  margin-right: 10px;
}

.storeImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  width: 50%;
  height: 300px;
}

.d_none {
  display: none;
}

.store_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 90%;
  height: 80%;
  font-size: 28px;
}

.row_mt_20 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bg_Transparent_w_85_border_p_10 {
  background-color: #1E2022 !important;
  color: #FFFFFF !important;
  width: 85%;
  border: 0.1px Solid #D3D3D3;
  padding: 10px;
}

.bg_gray_dark {
  background-color: #1E2022;
  color: #fff;
}

.position_relative {
  position: relative;
}

.bg_Transparent_w_75_border_p_10_ml_30 {
  background-color: #1E2022 !important;
  border: 0.1px Solid #D3D3D3;
  width: 75%;
  padding: 10px;
  margin-left: 30px;
  color: #ffffff;
}

.row_br_5_bg_dark_p_10 {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #18191B;
  color: #fff;
  padding: 10px;
}

.tw_center {
  text-align: -webkit-center;
}

.text_right {
  text-align: right;
}

.productBtn {
  width: 110px;
  background-color: transparent;
  border: 1.5px solid #1FFC33;
  border-radius: 5px;
  padding: 7px 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.productDisableBtn {
  width: 110px;
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 5px;
  padding: 7px 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.bg_Transparent_Kyc_Mrgn {
  background-color: transparent;
  margin-left: 25px;
  margin-right: 25px;
}

.greenClr_fs_12 {
  color: #1FFC33;
  font-size: 12px;
}

.grayClr_fs_12 {
  color: #9A9A9A;
  font-size: 12px;
}

.fs_20_mt_10 {
  font-size: 20px;
  margin: 12px 0 6px 0;
}

.mt_0 {
  margin-top: 0px;
}

.mt_10 {
  margin-top: 10px;
}

.mb_8 {
  margin-bottom: 8px;
}

.pl_5 {
  padding-left: 5px;
}

.bg_Transparent_w_98_border_p_maplink {
  background-color: transparent;
  width: 98%;
  border: 1.5px solid white;
  padding: 15px 10px 15px 10px;
  color: #ffffff;
}

.fs_14 {
  font-size: 14px;
}

.bg_Transparent_w_98_border_p_10 {
  background-color: transparent;
  width: 98%;
  padding: 10px;
  border: 1.5px solid white;
  color: #ffffff;
}

.text_end_text_danger_fs_12 {
  text-align: end;
  color: #ED4F4F;
  font-size: 12px;
}

.kyc_sub_heading {
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
}

.row_fs_Kyc20_align_items_center {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  align-items: center;
}

.col_10 {
  flex: 0 0 auto;
  width: 75.33333333%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.kyc_input {
  background-color: transparent;
  width: 95%;
  border: 1.5px solid white;
  padding: 10px;
  color: #ffffff;
}

.kyc_input_select {
  background-color: transparent;
  width: 82%;
  border: 1.5px solid white;
  padding: 10px;
  color: #ffffff;
}

.kyc_toggle_btn {
  flex: 0 0 auto;
  display: flex;
  text-align: center;
  margin-left: 12px;
  margin-top: 46px;
  padding: 1px;
  width: 60px;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
}

.kyc_toggle_btn_profile_img {
  flex: 0 0 auto;
  display: flex;
  text-align: center;
  padding: 1px;
  width: 60px;
  margin: auto;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
}

.mr_40 {
  margin-right: 40px;
}

.radio_Btn {
  width: 25px;
  height: 25px;
}

.toggle_btn {
  margin-left: 12px;
  margin-top: 37px;
  padding: 1px;
  width: 60px;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
}

.toggle_btn_active {
  background: #1FFC33;
  width: 50%;
  border-radius: 25px;
}

.toggle_btn_deactive {
  background: #ED4F4F;
  width: 50%;
  border-radius: 25px;
}

.d_none {
  display: none;
}

.justify_space_between {
  justify-content: space-between;
}

.min_height_200 {
  min-height: 200px;
}

.kyc_w_50_mouse {
  cursor: pointer;
  width: 50%;
}

.kyc_dFlex_justifyCenter {
  display: flex;
  justify-content: center;
}

.kyc_col_2_text_center {
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.kyc_upload_aadhaarImg_heading {
  background-color: #282c34;
  height: 200px;
  max-width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  min-height: 200px;
  border: 2.2439px dashed #FFFFFF;
  cursor: pointer;
}

.kyc_upload_aadhaar_image {
  border: 2.2439px dashed #FFFFFF;
  width: 130px;
  height: 180px;
  max-height: 180px;
  margin-top: 30px;
  padding: 10px 25px 10px 25px;
}

.kyc_row_fs_Kyc20 {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
}

.kyc_col_2_item_Center {
  align-items: center;
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.kyc_radioBtn_div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}

.kyc_radioBtn_col_3 {
  flex: 0 0 auto;
  width: 25%;
  align-items: center;
  display: flex;
}

.kyc_mouse_radio_Btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.kyc_radioBtn_col_4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  align-items: center;
  display: flex;
}

.kyc_col_9 {
  flex: 0 0 auto;
  width: 75%;
  align-items: center;
  display: flex;
}

.text_end {
  text-align: end;
}

.darkBtn {
  border: 1px solid #BABABA;
  background-color: transparent;
  color: #BABABA;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.successBtn {
  width: 155px;
  background-color: transparent;
  border: 1px solid #1FFC33;
  border-radius: 5px;
  padding: 10px 0;
  color: #1FFC33;
  font-size: 13px;
  cursor: pointer;
}

.greenBtn {
  border: 1px solid #1FFC33;
  background-color: #1FFC33;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.dangerBtn {
  border: 1px solid #F93E3E !important;
  background-color: transparent;
  color: #F93E3E;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.uploadLabel {
  border: 1px solid #1FFC33;
  width: 155px;
  padding: 10px 2px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.enterUrlLabel {
  border: 1px solid #F3F3F3;
  width: 200px;
  padding: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.trashBin {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  border-radius: 6px;
  color: #F93E3E;
  cursor: pointer;
}

.caretDown {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  margin-left: 20px;
  border-radius: 10px;
  color: #D0D0D0;
  cursor: pointer;
  cursor: pointer;
}

@media (max-width:2560px) {
  .profileImage {
    width: 280px;
    height: 320px;
  }

  .storeImage {
    width: 800px;
    height: 320px;
  }

  .profile_Image_div {
    width: 220px;
    height: 250px;
  }
}

@media (max-width: 1500px) {
  .profileImage {
    width: 180px;
    height: 220px;
  }

  .storeImage {
    width: 600px;
    height: 250px;
  }

  .profile_Image_div {
    width: 142px;
    height: 170px;
  }
}

@media (max-width: 1200px) {
  .profileImage {
    width: 180px;
    height: 200px;
  }

  .storeImage {
    width: 600px;
    height: 200px;
  }
}

@media only screen and (max-width: 1595px) {
  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 75.33333333%;
  }

  .productBtn {
    width: 100px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 100px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1350px) {
  .row_fs_Kyc20_align_items_center {
    font-size: 18px;
  }

  .profileImgHW {
    width: 200px;
    height: 200px;
  }

  .storeImgHW {
    width: 600px;
    height: 300px;
  }

  .qr_HW {
    width: 145px;
    height: 145px;
  }

  .storeImgHW {
    width: 635px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .productBtn {
    width: 90px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 90px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1220px) {
  .Kyc_Mrgn {
    margin-left: 10px;
    margin-right: 10px;
  }

  .productBtn {
    width: 90px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 90px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1140px) {
  .storeImgHW {
    width: 535px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 7px;
    margin-right: 7px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 70.33333333%;
  }

  .qr_HW {
    width: 135px;
    height: 135px;
  }

  .productBtn {
    width: 85px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 85px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}

/* --------------------------------------------------------------Loading----------------------------------------- */
.loading span {
  display: inline-block;
  vertical-align: middle;
  width: .6em;
  height: .6em;
  margin: .19em;
  background: #007DB6;
  border-radius: .6em;
  animation: loading 1s infinite alternate;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.loading span:nth-of-type(2) {
  background: #008FB2;
  animation-delay: 0.2s;
}

.loading span:nth-of-type(3) {
  background: #009B9E;
  animation-delay: 0.4s;
}

.loading span:nth-of-type(4) {
  background: #00A77D;
  animation-delay: 0.6s;
}

.loading span:nth-of-type(5) {
  background: #00B247;
  animation-delay: 0.8s;
}

.loading span:nth-of-type(6) {
  background: #5AB027;
  animation-delay: 1.0s;
}

.loading span:nth-of-type(7) {
  background: #A0B61E;
  animation-delay: 1.2s;
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@keyframes loading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ----------------------------------------------------------old-product-css------------------------------------------------------------------------------------------- */
.bg_white {
  background-color: #fff;
  color: #18191B;
}

.bg_dark_clr {
  background-color: #18191B;
}

.bg_red {
  background-color: #F93E3E !important;
  color: #fff !important;
}

.text_white {
  color: #fff;
}

.text_dark {
  color: #18191B;
}


.text_underline {
  text-decoration-line: underline;
}

.radio_btn {
  width: 25px;
  height: 25px;
}

.object_fit {
  object-fit: fill;
}

/* ----- font Size -----*/
.fs_10 {
  font-size: 10px;
}

.fs_12 {
  font-size: 12px;
}

.fs_14 {
  font-size: 14px;
}

.fs_20 {
  font-size: 20px;
}

.fs_24 {
  font-size: 24px;
}

.fs_26 {
  font-size: 26px;
}

.fs_28 {
  font-size: 28px;
}

.fs_30 {
  font-size: 30px;
}

.fs_50 {
  font-size: 50px;
}

.mouse {
  cursor: pointer;
}

/*----- Border CSS----- */

.border_text_area {
  border: 0.826924px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 2px;
}

.border_top_white {
  border-top: 2px solid #18191B;
}

.br_2 {
  border-radius: 2px;
}

.br_10 {
  border-radius: 10px;
}

.br_5 {
  border-radius: 5px;
}

.b_t_b_r_r_10 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border {
  border: 1px solid #fff;
}

/*----- Display CSS----- */

.d_flex {
  display: flex;
}

.d_none {
  display: none;
}

.bg_transparent {
  background-color: transparent !important;
  color: #fff !important;
}

/* ----- Color CSS ----- */

.text_danger {
  color: #F93E3E;
}

.text_success {
  color: #1FFC33;
}

.gray_clr {
  background-color: #C4C4C4;
  color: #1E2022;
}

.clr_Blue {
  color: #24ABF8;
}

.clr_muted {
  color: #858585;
}

.active_fs {
  font-size: 16px;
}

/* ------Margin CSS----- */

.m_10 {
  margin: 10px;
}

.m_20 {
  margin: 20px;
}

.mx_10 {
  margin: 0 10px;
}

.mt_1 {
  margin-top: 1px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_13 {
  margin-top: 13px;
}

.mt_15 {
  margin-top: 15px !important;
}

.mt_20 {
  margin-top: 20px;
}

.mt_23 {
  margin-top: 23px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_32 {
  margin-top: 32px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_36 {
  margin-top: 36px;
}

.mt_38 {
  margin-top: 38px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_41 {
  margin-top: 41px;
}

.mt_48 {
  margin-top: 48px;
}

.mt_51 {
  margin-top: 51px;
}

.mt_58 {
  margin-top: 58px;
}

.mt_63 {
  margin-top: 65px;
}

.mt_64 {
  margin-top: 64px;
}

.mt_66 {
  margin-top: 66px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_42 {
  margin-top: 42px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_41 {
  margin-bottom: 41px;
}

.mb_45 {
  margin-bottom: 45px;
}

.ms_2 {
  margin-left: 2px;
}

.ms_5 {
  margin-left: 5px;
}

.ms_10 {
  margin-left: 10px;
}

.ms_10p {
  margin-left: 10% !important;
}

.ms_15 {
  margin-left: 15px;
}

.ms_25 {
  margin-left: 25px;
}

.ms_65 {
  margin-left: 65px;
}

.ms_300 {
  margin-left: 300px;
}

.me_10 {
  margin-right: 10px;
}

.me_7p {
  margin-right: 7%;
}

.me_15p {
  margin-right: 15%;
}

.me_20p {
  margin-right: 20%;
}

.me_20 {
  margin-right: 20px;
}

.me_40 {
  margin-right: 40px;
}

.me_300 {
  margin-right: 250px;
}

/* ----- Padding CSS ----- */

.p_5 {
  padding: 5px;
}


.p_20 {
  padding: 20px !important;
}

.p_30 {
  padding: 30px;
}

.px_5 {
  padding: 0 5px;
}

.px_10 {
  padding: 0 10px;
}

.py_5 {
  padding: 5px 0;
}

.py_10 {
  padding: 10px 0;
}

/*----- Width ------*/

.w_100 {
  width: 100%;
}

.w_90 {
  width: 90%;
}

.w_85 {
  width: 85%;
}

.w_78 {
  width: 78% !important;
}

.mnw_1024 {
  min-width: 1024px;
}

.w_3 {
  width: 3%;
}

.w_97 {
  width: 97%;
}

.width66 {
  width: 75px;
}

/* ------Height CSS----- */

.h_100 {
  height: 100vh;
}

/*----- Text CSS ----- */
.justify_space_between {
  justify-content: space-between;
}

.justify_space_around {
  justify-content: space-around;
  align-items: center;
}

.justify_content_right {
  justify-content: right;
}

.justify_content_between {
  display: flex;
  justify-content: space-between;
}

.text_start {
  text-align: start;
}

.text_center {
  text-align: center;
}

.align_items_center {
  align-items: center;
}

/* ----- Top ----- */

.top {
  min-width: 1024px;
}

.boxST {
  background: #18191B;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 15px 0;
  margin: 0 14px;
}

/* ------------------------End Common Css-------------------------------*/

/* ------------------------BootStrap Css-------------------------------*/
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col_1_5 {
  flex: 0 0 auto;
  width: 15%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width:768px) {
  .col-md {
    flex: 1 0 0%
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.33333333%
  }

  .offset-md-2 {
    margin-left: 16.66666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.33333333%
  }

  .offset-md-5 {
    margin-left: 41.66666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.33333333%
  }

  .offset-md-8 {
    margin-left: 66.66666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.33333333%
  }

  .offset-md-11 {
    margin-left: 91.66666667%
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:992px) {
  .col-lg {
    flex: 1 0 0%
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333333%
  }

  .offset-lg-2 {
    margin-left: 16.66666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333333%
  }

  .offset-lg-5 {
    margin-left: 41.66666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333333%
  }

  .offset-lg-8 {
    margin-left: 66.66666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333333%
  }

  .offset-lg-11 {
    margin-left: 91.66666667%
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1200px) {
  .col-xl {
    flex: 1 0 0%
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.33333333%
  }

  .offset-xl-2 {
    margin-left: 16.66666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.33333333%
  }

  .offset-xl-5 {
    margin-left: 41.66666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.33333333%
  }

  .offset-xl-8 {
    margin-left: 66.66666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.33333333%
  }

  .offset-xl-11 {
    margin-left: 91.66666667%
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1400px) {
  .col-xxl {
    flex: 1 0 0%
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xxl-0 {
    margin-left: 0
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%
  }

  .offset-xxl-3 {
    margin-left: 25%
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%
  }

  .offset-xxl-6 {
    margin-left: 50%
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%
  }

  .offset-xxl-9 {
    margin-left: 75%
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem
  }
}

/* -------------------------------------------media-css----------------------------------------------- */


.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

/* --------------------------End BootStrap CSS------------------------------*/


/* ----------------------Start LeftMenu Css----------------------------*/
.left_menu_col_3 {
  flex: 0 0 auto;
  width: 3%;
}

.left_menu_active {
  cursor: pointer;
}

.left_menu_active :active {
  color: #24ABF8;
}

.img_HW {
  width: 80px;
  height: 45px;
}

/* ------------------------ End LeftMenu Css --------------------------------*/

/* ----------------------- start AddProduct Css -----------------------------*/
.product_fs_20 {
  font-size: 20px;
}

.text_area_height {
  height: 180px;
  padding: 10px;
}

.partnerBox {
  border: 1px solid;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 0 5px;
  min-height: 39px;
}

.dorpDownBox {
  padding: 8px;
  border: 1px solid #ffffff;
  border-top: none;
  display: flex;
  flex-direction: column;
}

.dropDownIcon {
  display: block;
  width: 100%;
}

.selectedPartners {
  border: 1px solid #1ffc33;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 0 5px 5px 0;
}

/* --------------------------End AddProduct Css-------------------------------*/

/* ------------------------start EditProduct Css--------------------------------*/
.edit_view {
  background: #18191B;
  box-shadow: 0px 2.92168px 2.92168px rgba(0, 0, 0, 0.25);
  text-align: center;
  margin-left: 40px;
  border-radius: 50px;
  cursor: pointer;
  width: 40px;
  padding: 10px;
}


/* --------------------------------------------------------------------------------- */
.roundedBlck {
  border-radius: 10px !important;
}

.item_Border {
  width: 252px;
  background: #18191B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 10px;
}

.editDelBtns {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: center;
  padding: 12px;
  color: #1FFC33;
  cursor: pointer;

}

.trashbtn {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: center;
  padding: 12px;
  color: #F93E3E;
  cursor: pointer;

}

.bg_white {
  background-color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent !important;
  /* background-clip: border-box; */
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

/* -----------------------------------------start Style CSS------------------------------------------------ */

.uploadbtn {
  border: 1px solid #1FFC33;
  width: 155px;
  padding: 10px 2px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.upload_fs_14 {
  font-size: 14px;
}

.text_area_width {
  width: 135px;
}

.bSelect {
  height: 35px;
  border-radius: 6px;
  background: transparent;
  color: white;
}

/* ----------------------------------FaqCss------------------------------- */

.text_area_faq {
  height: 65px;
  padding: 5px;
}

/* ----------------------------------------------------------------------- */
.fs_R_20 {
  font-size: 20px;
}

.col_1_3 {
  flex: 0 0 auto;
  width: 6.333333%;
}

.offset_1 {
  margin-left: 1.33333333%;
}

.fs_Up_14 {
  font-size: 14px;
}

/* -------------------------Start Media Css------------------------------- */
@media only screen and (max-width: 1727px) {
  .text_area_width {
    width: 100px;
  }
}

@media only screen and (max-width: 1601px) {
  .upload_fs_14 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1547px) {
  .text_area_width {
    width: 85px;
  }

  .fs_R_20 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1493px) {
  .uploadbtn {
    border: 1px solid #1FFC33;
    width: 155px;
    padding: 11px 2px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1359px) {
  .uploadbtn {
    border: 1px solid #1FFC33;
    width: 155px;
    padding: 11px 2px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }

  .upload_fs_14 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1327px) {
  .col_1_3 {
    flex: 0 0 auto;
    width: 5.333333%;
  }

  .upload_fs_14 {
    font-size: 9px;
  }

  .fs_R_20 {
    font-size: 16px;
  }

  .offset_1 {
    margin-left: 3.33333333%;
  }
}

@media only screen and (max-width: 1150px) {
  .offset_1 {
    margin-left: 0%;
  }

  .fs_R_20 {
    font-size: 13px;
  }

  .col_1_2 {
    flex: 0 0 auto;
    width: 2.333333%;
  }

  .fs_Up_14 {
    font-size: 11px;
  }
}

/* ---------------------------End Media Css------------------------------- */


/* -----------------------------------------Start style & Type Media CSS------------------------------------------------ */

.type_fs_30 {
  font-size: 30px;
}

@media only screen and (max-width: 1850px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 13px;
  }
}

@media only screen and (max-width: 1730px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 12px;
  }
}

@media only screen and (max-width: 1610px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 11px;
  }

  .type_fs_30 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1490px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1370px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 9px;
  }

  .type_fs_30 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1250px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 8px;
  }

  .type_fs_30 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1130px) {

  .boxST {
    background: #18191B;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 15px 0;
    margin: 0 7px;
  }

  .type_fs_30 {
    font-size: 22px;
  }

}

/* -----------------------------------------------End style & Type Media CSS---------------------------------------------- */


/* -------------------------------------------------AdvancedSettingsModal------------------------------------------------- */
.btnMKD {
  width: 94px;
  height: 40px;
  border-radius: 50px;
  border: none;
}

.border_SH {
  width: 189px;
  background: #C4C4C4;
  border-radius: 21.5px;
}

.gray_btn {
  background: #C4C4C4;
}

.w_25 {
  width: 65px;
  height: 30px;
}

.w_89 {
  width: 70px;
  height: 34px;
}

.light_Green {
  background: #71E07B;
  border: 1px solid #1FFC33;
  box-sizing: border-box;
  border-radius: 21.5px;
}

.red_btn {
  background-color: #DE8181;
  border: 1px solid #F93E3E;
  box-sizing: border-box;
  border-radius: 21.5px;
}

.moldal_height {
  height: 350px;
}

.text_Box_mar {
  margin: 25;
}

.offset_1_AS {
  margin-left: 8.33333333%;
}

@media only screen and (max-width: 1150px) {
  .offset_1_AS {
    margin-left: 6.33333333%;
  }
}

.checkBoxOver {
  height: 39px;
}


/* -------------------------------------------------MARKETING-CSS----------------------------------------------------------------- */

/* ------------------------Start Common Css-----------------------------*/
.bg_white {
  background-color: #FFFFFF;
  color: #18191B;
}


.bg_white_gray_Light {
  background-color: #F7F8FA;
  color: #18191B;
}

.bg_dark {
  background-color: #000000;
  color: #fff;
}

.bg_dark_clr {
  background-color: #18191B;
}

.bg_red {
  background-color: #F93E3E !important;
  color: #fff !important;
}

.text_white {
  color: #fff !important;
}

.text_dark {
  color: #18191B !important;
}

.text_underline {
  text-decoration-line: underline;
}

.object_fit {
  object-fit: fill;
}

.gfg {
  border-collapse: separate;
  border-spacing: 0 15px;
}

/* ----- font Size -----*/
.fs_10 {
  font-size: 10px;
}

.fs_12 {
  font-size: 12px;
}

.fs_14 {
  font-size: 14px;
}

.fs_16 {
  font-size: 16px;
}

.fs_18 {
  font-size: 18px;
}

.fs_20 {
  font-size: 20px;
}

.fs_24 {
  font-size: 24px;
}

.fs_26 {
  font-size: 26px;
}

.fs_28 {
  font-size: 28px;
}

.fs_30 {
  font-size: 30px;
}

.fs_50 {
  font-size: 50px;
}

.mouse {
  cursor: pointer;
}

/*----- Text Box CSS----- */
.text_Box {
  width: 100%;
  border: 0.826924px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #FFFFFF;
  text-decoration: none;
}

.text_Box_Dark {
  width: 100%;
  border: 0.826924px solid #3A3A3A;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #3A3A3A;
}

/*----- Border CSS----- */


.border_top {
  border-top: 10px solid #18191B;
}

.borderTop {
  border-top: 1px solid #fff;
}

.borderTopDark {
  border-top: 1px solid #18191B;
}

.border_Right {
  border-right: 1px solid;
}

.br_2 {
  border-radius: 2px;
}

.br_10 {
  border-radius: 10px;
}

.br_5 {
  border-radius: 5px;
}

.br_30 {
  border-radius: 30px !important;
}

.br_50 {
  border-radius: 50px !important;
}

.b_t_b_r_r_10 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border {
  border: 1px solid #fff;
}

.border_Dark {
  border: 1px solid #18191B;
}

.border_bottom {
  border-bottom: 1px solid #18191B;
}

.border_bottom_white {
  border-bottom: 1px solid #fff;
}

.border_Nome {
  border: none;
}


/*----- Display CSS----- */

.d_flex {
  display: flex;
}

.d_none {
  display: none;
}

.bg_Transparent {
  background-color: transparent !important;
  color: #fff !important;
}

/* ----- Color CSS ----- */

.bg_danger {
  background-color: #F93E3E !important;
}

.text_danger {
  color: #F93E3E;
}

.text_success {
  color: #05FF00 !important;
}

.text_black {
  color: #252525 !important;
}

.gray_clr {
  background-color: #C4C4C4;
  color: #1E2022;
}

.light_gray_clr {
  background-color: #E3E7EF;
  color: #18191B;
}

.clr_Blue {
  color: #24ABF8;
}

.clr_muted {
  color: #858585;
}

.btnBorderclr {
  color: #FF9700;
}

.text_info {
  color: #24ABF8;
}

.orangeclr {
  color: #F56221;
}

.text_warning {
  color: #FFC776;
}

/* ------ Margin CSS ----- */
.m_5 {
  margin: 5px;
}

.m_10 {
  margin: 10px;
}

.m_20 {
  margin: 20px;
}

.m_16 {
  margin: 16px;
}

/* ----- Margin-Top ----- */
.mt_1 {
  margin-top: 1px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_21 {
  margin-top: 21px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_40 {
  margin-top: 40px;
}

/* ----- Margin-Bottom ----- */
.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_30 {
  margin-bottom: 30px;
}

/* ----- Margin-Left ----- */
.ms_5 {
  margin-left: 5px;
}

.ms_10 {
  margin-left: 10px;
}

.ms_20 {
  margin-left: 20px;
}

.ms_65 {
  margin-left: 65px;
}

.ms_100 {
  margin-left: 100px;
}

.ms_2_6p {
  margin-left: 2.666%;
}

.ms_15p {
  margin-left: 15%;
}

/* ----- Margin-Left ----- */
.me_5 {
  margin-right: 5px;
}

.me_7 {
  margin-right: 7px;
}

.me_10 {
  margin-right: 10px;
}

.me_20 {
  margin-right: 20px;
}

.me_40 {
  margin-right: 40px;
}

/* ----- Padding CSS ----- */
.p_10_5 {
  padding: 10px 5px;
}

.p_5 {
  padding: 5px;
}

.pe_5 {
  padding-right: 5px;
}

.p_8 {
  padding: 8px;
}

.p_10 {
  padding: 10px;
}

.p_20 {
  padding: 20px !important;
}

.p_30 {
  padding: 30px;
}

.px_5 {
  padding: 0px 5px;
}

.px_10 {
  padding: 0px 10px;
}

.py_5 {
  padding: 5px 0px;
}

.py_10 {
  padding: 10px 0px;
}

.py_10_3 {
  padding: 10px 3px;
}

.pb_15 {
  padding-bottom: 15px;
}

/* ----- Width ------ */
.max_Width {
  max-width: 1920px;
  min-width: 1024px;
  margin: auto;
}

.w_100 {
  width: 100%;
}

.w_96 {
  width: 96%;
}

.w_95 {
  width: 95%;
}

.w_90 {
  width: 90%;
}

.w_70 {
  width: 70%;
}

.w_60 {
  width: 60%;
}

.w_50 {
  width: 50%;
}

.w_30 {
  width: 30%;
}

.w_25 {
  width: 25%;
}

.w_20 {
  width: 20%;
}

.w_14 {
  width: 14%;
}

.w_10 {
  width: 10%;
}

.width75 {
  width: 75px;
}

/* ------Height CSS----- */
.h_125 {
  height: 125vh;
}

.h_100 {
  height: 100vh;
}

.h_85 {
  height: 85vh;
}

.h_385 {
  height: 385px;
}

.h_36 {
  height: 36px;
}

.h_65 {
  height: 65px;
}

/* ------------------------End Common Css-------------------------------*/

/* ------------------------------ Modal CSS ------------------------- */
.custom_modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 80px;
}

/* Modal content */
.modal_content {
  background-color: #1E2022;
  color: #fff;
  width: 50%;
  max-width: 850px;
  min-height: 500px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.modal_content_white {
  background-color: #F7F8FA;
  color: #1E2022;
  width: 50%;
  max-width: 850px;
  height: 480px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

/* Close button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cityMain {
  position: relative;
}

/* ----------------------------- End Modal CSS ------------------------- */
/* ----------------------Start Header Css-----------------------*/
/* .header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0px;
  height: 50px;
  align-items: center;
  min-width: 1024px;
} */

.headerColR {
  align-items: center;
  display: flex;
  justify-content: right;
  margin-right: 20px;
}

.wallicon {
  width: 56px;
  height: 33px;
  margin-left: 10px;
  margin-top: 5px;
}

.icon_WH {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  margin-right: 20px;
}

/* ------------------------End Header Css------------------------------*/



/* --------------------------Start Button CSS------------------------------ */

.disableBtn {
  background: transparent !important;
  border: 1px solid #C4C4C4 !important;
  color: #C4C4C4;
  width: 100px;
  height: 36px;
  padding: 10px 0;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}

.yesBtn {
  border: none;
  background-color: #1FFC33;
  color: #000;
  width: 100px;
  height: 36px;
  padding: 10px 0;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 20px;
}

.yesOutBtn {
  border: 1px solid #1FFC33;
  background: transparent;
  color: #1FFC33;
  width: 100px;
  height: 36px;
  padding: 10px 0;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  margin-right: 20px;
}

.noBtn {
  border: none;
  background-color: #F32D3C;
  color: #fff;
  width: 100px;
  height: 36px;
  padding: 10px 0;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  /* margin-left: 20px; */
}

/* ---------------------------- End Button CSS ---------------------- */
/* html */
/* .timeClr::-webkit-calendar-picker-indicator {
  color: #fff!important;
} */
.timeClr::-webkit-calendar-picker-indicator {
  background-color: white;
}

/* --------------------- Marketing --------------------- */

/* ------ Search Icon ------ */
.search_Icon {
  margin-top: 15px;
  margin-left: -35px;
  width: 20px;
  height: 20px;
  position: absolute;
}

/* ------ Add Icon ------ */
.add_Icon {
  position: absolute;
  right: 0;
  bottom: 0;

}

.btnHW {
  width: 33%;
  height: 40px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
}

.swichBtn {
  width: 50%;
  height: 40px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
}

.dropdown {
  width: 280px;
  height: 45px;
}


.border_05 {
  border: 0.5px solid #3A3A3A !important;
}

.text_Left {
  text-align: left;
}

/* -------------------- End Marketing ------------------- */

/* ------------------ MarketingDashboard ---------------- */
.tableCss {
  /* text-align: center; */
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0.5px solid #3A3A3A !important;
}

.border_1 {
  border: 0.5px solid red;
  border-radius: 30px;
  color: red;
  cursor: pointer;
}

.border_2 {
  border: 0.5px solid #05FF00;
  border-radius: 30px;
  color: #05FF00;
  cursor: pointer;
}

.border_3 {
  border: 0.5px solid #FF9700;
  border-radius: 30px;
  color: #FF9700;
  cursor: pointer;
}

/* ---------------- End MarketingDashboard ---------------- */


/* ------------------ MarketeerOrder ---------------- */
.iconHw {
  width: 38px;
  height: 38px;
}

.imgHW {
  width: 100%;
  height: 110px;

}

.borderNone {
  background-color: #1E2022;
  border: 1px solid #3A3A3A;
  color: #fff;
}

.dropdownCss {
  background-color: #18191B;
  border: 1px solid #3A3A3A;
  color: #fff;
}

.height50 {
  height: 50px;
}

.btnMKD {
  width: 100px;
  height: 36px;
}

.text_BoxHW {
  width: -webkit-fill-available;
  height: 65px;
  padding: 10px;
  background: transparent;
}

.chooseBtn {
  width: 115px;
  border: 1.5px solid #1FFC33;
  border-radius: 5px;
  padding: 7px 0;
  font-size: 14px;
  cursor: pointer;
}


.leadLostModal {
  width: 50%;
  max-width: 517px;
  height: 240px;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
}

.align_self {
  align-self: center;
}

.searchList {
  height: 375px;
  overflow: auto;
  border: 1px solid #3A3A3A;
  padding: 15px !important;
  /* position: absolute; */
  /* top: 52%; */
  /* width: 45%; */
}


/* .border_text_area {
  width: 100%;
  height: 65px;
  border: 0.826924px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  margin-top: 5px;
  background-color: transparent !important;
  color: #fff !important;
} */

.border_text_area_dark {
  width: 100%;
  height: 65px;
  border: 0.826924px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  margin-top: 5px;
  background-color: transparent !important;
  color: #FFFFFF !important;
}

/* ---------------- End MarketeerOrder ---------------- */

/*----------------------- Table ----------------------- */

/* tr {
  box-shadow: 0 0 0 1px #18191B;
  border-radius: 5px;
} */

/* --------------------- Media Css --------------------- */
@media only screen and (max-width: 1760px) {
  .market_fs_16 {
    font-size: 14px;
  }

  .dropdown {
    width: 260px;
    height: 40px;
  }
}

@media only screen and (max-width: 1560px) {
  .dropdown {
    width: 230px;
    height: 40px;
  }
}

@media only screen and (max-width: 1205px) {
  .market_fs_16 {
    font-size: 12px;
  }

  .dropdown {
    width: 200px;
    height: 40px;
  }
}

@media only screen and (max-width: 1050px) {
  .market_fs_16 {
    font-size: 10px;
  }
}

/* ----------------------Super admin dashboard-------------------------- */

.fs_Sa_30 {
  font-size: 30px;
}

.fs_Sa_20 {
  font-size: 20px;
}

.fs_Sa_18 {
  font-size: 18px;
}

.mx_15p {
  margin: 0px 15%;
}

.mb_25 {
  margin-bottom: 25px;
}

.img_65 {
  width: 65px;
  height: 65px;
}

.swichBtnSAD {
  width: 25%;
  height: 40px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
}

.textarea {
  width: 95%;
  height: 90%;
  padding: 10px;
}

.icons {
  border-radius: 50%;
  padding: 18px 18px 9px 18px;
  font-size: 26px;
}

/* ---------------------------Media Super admin dashboard----------------------- */

@media only screen and (max-width: 1555px) {
  .fs_Sa_20 {
    font-size: 18px;
  }

  .fs_Sa_18 {
    font-size: 16px;
  }

  .fs_Sa_16 {
    font-size: 10px;
  }

}

@media only screen and (max-width: 1315px) {
  .fs_Sa_30 {
    font-size: 26px;
  }

}

@media only screen and (max-width: 1277px) {
  .fs_Sa_20 {
    font-size: 16px;
  }

  .fs_Sa_18 {
    font-size: 14px;
  }

  .img_65 {
    width: 55px;
    height: 55px;
  }
}

@media only screen and (max-width: 1186px) {
  .fs_Sa_30 {
    font-size: 24px;
  }

  .fs_Sa_20 {
    font-size: 14px;
  }

  .fs_Sa_18 {
    font-size: 12px;
  }

  .img_65 {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 1115px) {
  .fs_Sa_30 {
    font-size: 21px;
  }

  .fs_Sa_20 {
    font-size: 12px;
  }

  .fs_Sa_18 {
    font-size: 10px;
  }
}

/* -------------- Chart ------------ */

/* ------------------ Messanger -------------------- */
.img_50 {
  width: 50px;
  height: 50px;
}

.col_1 {
  flex: 0 0 auto;
  width: 1.33333333%;
}

.col_8 {
  flex: 0 0 auto;
  width: 73.66666667%;
}

.bg_Msg {
  background-color: #33383C;
  color: #FFFFFF;
}

.b_t_b_r_r_l_10 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.b_t_b_r_l_10 {
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.bg_messanger {
  background-color: #00B09C;
}

.attachmentIcon {
  position: absolute;
  margin-left: -80px;
  margin-top: 17px;
}

.cameraIcon {
  position: absolute;
  margin-left: -45px;
  margin-top: 17px;
}

.icons_Mic {
  border-radius: 50%;
  padding: 15px 15px 8px 15px;
  font-size: 26px;
}

.color_Icon {
  color: #1FFC33;
}

/* ------------------------------------------------marketing-new-css----------------------------------------- */
.marketing_sub_div {
  background-color: #1E2022;
  padding: 12px 12px 2px 12px;
  border-radius: 12px;
}

.marketing_scroll_div {
  height: 706px;
  max-height: 706px;
  min-height: 706px;
  overflow-y: scroll;
}

.marketing_scroll_div::-webkit-scrollbar {
  display: none;
}

.marketOrder {
  position: relative;
  background-color: #000000;
  color: #fff;
  border: 0.5px solid #3A3A3A !important;
  border-radius: 12px;
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
}

.threeDots_div_position {
  position: absolute;
  right: 0;
  top: 34px;
  z-index: 1060;
  /* padding-right: 10px; */
}

.priority_div {
  width: 300px;
}

.order_customer_name {
  font-size: 28px;
}

.marketOrder_mob_no {
  font-size: 20px;
}

.call_answer {
  text-align: center;
  margin-top: 30px;
  font-size: 28px;
}

.icon_mr_40 {
  margin-right: 40px;
}

.marketOrder_notes {
  line-height: 1rem;
  font-size: 28px;
  text-align: center;
}

.marketing_main_content {
  /* font-size: 20px; */
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid; */
  border-radius: 12px;
  padding: 16px;
  background-color: #1E2022;
  min-height: 800px;
  max-height: 800px;
}

.marketOrder_right_col {
  flex: 0 0 auto;
  width: 50%;
  min-height: 800px;
  max-height: 800px;
  overflow-y: scroll;
}

.marketOrder_right_col::-webkit-scrollbar {
  display: none;
}

.pr_24 {
  padding-right: 24px;
}

.marketingOrder_edit_div {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 16px;
  padding-right: 4%;
}

.marketingOrder_lead_div {
  position: absolute;
  top: 15px;
  right: 1%;
}

.marketingOrder_edit {
  cursor: pointer;
  border: 1px solid #3A3A3A;
  padding: 4px 16px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.marketingOrder_lead {
  cursor: pointer;
  padding-right: 16px;
}

.todays_leads_div {
  min-height: 470px;
  max-height: 470px;
  overflow-y: scroll;
}

.todays_leads_div::-webkit-scrollbar {
  display: none;
}

.marketing_mobile_alert {
  font-size: 14px;
  color: red;
  margin: 0px;
  text-align: left;
}

.text_Box_red {
  width: 100%;
  border: 0.826924px solid red;
  outline: none;
  outline-color: red;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #FFFFFF;
}

.priority_1 {
  color: red;
  border: 1px solid;
  width: 'fit-content';
  border-radius: 25px;
  padding: 8px;
}

.priority_2 {
  color: #05FF00;
  border: 1px solid;
  width: 'fit-content';
  border-radius: 25px;
  padding: 8px;
}

.priority_3 {
  color: #FF9700;
  border: 1px solid;
  width: 'fit-content';
  border-radius: 25px;
  padding: 8px;
}

@media (max-width: 1300px) {
  .order_customer_name {
    font-size: 20px;
  }

  .marketOrder_mob_no {
    font-size: 15px;
  }

  .priority_div {
    width: 250px;
  }

  .call_answer {
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }

  .iconHw {
    width: 18px;
    height: 18px;
  }

  .icon_mr_40 {
    margin-right: 20px;
  }
}


.progresscustom {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  transform: rotate(-90deg) rotateX(180deg);
}

.progresscustom circle {
  strokeDashoffset: 0;
  transition: strokeDashoffset 1s ease;
  stroke: #24ABF8;
  strokeWidth: 18px;
}

.progresscustom .bar {
  stroke: #fff;
  /* strokeWidth: 14px; */
}

.progressdiv {
  position: relative;
}

.progressdiv:after {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 35px;
  transform: translate(-50%, -50%);
  content: attr(data-percent) attr(data-symbol);
}

/* --------------------------Drag-&-Drop------------------------------------- */
#input-file-upload {
  display: none;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#form-file-upload {
  height: 100%;
  width: 100%;
  max-width: 100%;
  text-align: center;
  /* position: absolute; */
}


#label-file-upload {
  height: 100%;
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 80px; */
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: transparent;
}

#label-file-upload.drag-active {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


.drag_div {
  text-align: -webkit-center;
  padding: 20px 0;
}

.drag_txt {
  font-size: 20px;
  width: 56%;
  text-align: center;
}

.alert_drag_txt {
  font-size: 20px;
  width: 56%;
  text-align: center;
  color: red;
}

.border_OR_div {
  border: 1px solid #cbd5e1;
  width: 80%;
  margin: 20px 0;
  position: relative;
}

.alert_border_OR_div {
  border: 1px solid red;
  width: 80%;
  margin: 20px 0;
  position: relative;
}

.OR_css {
  position: absolute;
  margin-top: -15px;
  background: #1E2022;
  padding: 0 20px;
  font-size: 20px;
  left: 50%;
  transform: translate(-50%, 0px);
}

.alert_OR_css {
  position: absolute;
  margin-top: -15px;
  background: #1E2022;
  padding: 0 20px;
  font-size: 20px;
  left: 50%;
  transform: translate(-50%, 0px);
  color: red;
}

.browseFiles_btn {
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  background: transparent;
  /* border: none; */
  outline: none;
  line-height: 44px;
  width: 200px;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;
}

.alert_browseFiles_btn {
  border: 1px solid red;
  border-radius: 6px;
  background: transparent;
  /* border: none; */
  outline: none;
  line-height: 44px;
  width: 200px;
  color: red;
  font-weight: 600;
  cursor: pointer;
}

.packingImage {
  height: 100%;
  background-color: #1E2022;
  margin: auto;
  z-index: 1;
}


.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  /* height: 100px; */
  box-sizing: border-box;
  width: 14%;
  height: 120px;
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 0 4px;
}

.Mob_skeleton {
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  background: #1E2022;
  box-sizing: border-box;
  animation: skeleton-loading 1s linear infinite alternate;
  margin: 10px;
  min-width: 60px;
  min-height: 64px;
  background: #FFFFFF;
  border: 0.407232px solid #D3D3D3;
  border-radius: 3.15007px;
  cursor: pointer;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.Mob_skeleton_order {
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  background: #1E2022;
  box-sizing: border-box;
  animation: skeleton-loading 1s linear infinite alternate;
  margin: 5px;
  min-width: 60px;
  min-height: 64px;
  background: #FFFFFF;
  border: 0.407232px solid #D3D3D3;
  border-radius: 3.15007px;
  cursor: pointer;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* ------------------------------------------------------------------------------------------------------- */

@media (-webkit-device-pixel-ratio: 1.25) {
  .zw {
    zoom: 80%;
  }
}

@media (-webkit-device-pixel-ratio: 1.50) {
  .zw {
    zoom: 65%;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {
  .zw {
    zoom: 56%;
  }
}

@media (-webkit-device-pixel-ratio: 2) {
  .zw {
    zoom: 70%;
  }
}

.acmodal .modal-content {
  background: #000 !important;
  color: #fff;
  border: 1px solid;
}

.acmodal input {
  background-color: transparent;
  color: #fff;
}

.acmodal input:focus {
  color: #fff;
  /* border-color: inherit; */
  background-color: transparent;
  box-shadow: none !important;
}

.acmodal .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.drag_div_mob {
  margin: auto;
  padding: 10px;
}

.uploadtext {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.product_pev_btn {
  height: 80px;
  width: 80px;
  padding-top: 10px;
}

.bgColor {
  background: #1e2022;
  color: #ffffff;
}

.cardcss {
  background: #000;
}

.courier_btn {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  border: none;
}

.redClr {
  color: #F44336;
}

.greenClr {
  color: #1ffc33;
}

.deliveryModal {
  background-color: #18191B;
}

.dispatchModal .modal-dialog {
  margin: 0 !important;
}

.dispatchModal .modal-content {
  border-radius: 0 !important;
}

.textGray {
  color: #d6d6d6 !important;
}

.deletebtncss {
  border: 1px solid;
  border-radius: 5px;
}

.partnerHeight {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid;
  padding: 15px;
  border-radius: 10px;
}

.form_group {
  margin-bottom: .75rem;
}

.form_group:last-child {
  margin-bottom: 0 !important;
}

.wishAlert {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1060;
  background: #FFF;
  color: #000;
  width: 75%;
  padding: 9px;
  display: flex;
  justify-content: center;
  max-width: 416px;
  z-index: 1100;
  border-radius: 5px;
  font-weight: 600;
}

.w_fit {
  width: fit-content !important;
}

.omBtnClickClr {
  background: #E3E7EF;
}

.omBtnWidth {
  width: 100px;
}

.bgSale {
  background-color: #18191b;
  color: #fff;
}

.bgHeight {
  min-height: 100vh;
}

.border_text {
  height: 123px;
  background-color: #1E2022;
}

.redButtonShadow {
  border: red;
  border-style: solid;
  border-width: 3px;
}

.stColr {
  color: #24ABF8;

}

.bgDark {
  background-color: #1e2022;
}

.borderRightRed {
  border: none !important;
  border-left: 5px solid #f93e3e !important;
}

.whiteBg {
  background-color: #C4C4C4;
}

.hwBtn {
  width: 300px;
  height: 60px;
}

.tw_right {
  text-align: -webkit-right;
}

.product_editDelete {
  width: 100% !important;
  min-height: calc(100vh - 166px);
  background-color: #18191b;
  color: #fff;
  padding: 1rem !important;
}

.partnerHeight_delivery {
  max-height: 472px;
  overflow-y: scroll;
  /* border: 1px solid; */
  padding: 6px 0;
  border-radius: 10px;
}

.productBtn_DELIVERY {
  min-width: 80px;
  background-color: transparent;
  border: 1.5px solid #1FFC33;
  border-radius: 5px;
  padding: 4px 10px;
  color: #1FFC33;
  font-size: 12px;
  cursor: pointer;
  margin: 2px;
}

.productDisableBtn_delivery {
  min-width: 80px;
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 5px;
  padding: 4px 10px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  margin: 2px;
}

.plus_product {
  font-weight: bolder;
  font-size: 14px;
  width: 20px;
}

.productName_center {
  text-align: center;
}

.form_group_delivery {
  margin-bottom: .75rem;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 4px 8px;
  padding-bottom: 0;
}

.delivery_method label{
  font-size: 14px;
}