.compoContiner {
    width: 100%;
    height: calc(100vh - 65px);
    padding: 10px 10px 0 10px;
    border: 1px solid;
}

.scandMainCon {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.cardDash {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /* Responsive grid */
    gap: 8px;
    width: 100%;
}

.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}
.mainCompo{
    width: 100%;
}
.mainText h1{
    font-size: 30px;
    font-weight: 600;
}