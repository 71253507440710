.product_right {
    width: 100%;
    background-color: transparent;
    max-height: calc(100vh - 84px);
    overflow-y: scroll;
    color: #ffffff;
    padding: 0 15px;
    position: relative;
}

.product_right h1 {
    margin: 10px 0;
}

.product_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.product_header u {
    cursor: pointer;
}

.add_product_main_div {
    background-color: #1E2022;
    border-radius: 12px;
    display: block;
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.metaRow {
    display: flex;
    align-items: center;
    padding: 15px 40px 0 40px;
}

.meta {
    /* margin-right: 20px;
    margin-left: 20px; */
    width: 294px
}

.url {
    display: flex;
    align-items: center;
    width: 100%;
}

.add_url_input {
    width: 'fit-content';
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: 92%;
    resize: none;
}

.inputField_row {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

.col_left {
    width: 35%;
}

.col_right {
    width: 50%;
}

.addItemRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    /* align-items: center;
    margin-top: 20px; */
}

.addProductCol3 {
    min-width: 200px;
}

.addProductCol9 {
    /* width: 66.66%; */
}

.bannerImgInput {
    display: none;
}

.bannerAddBtn {
    border: 1px solid #1FFC33;
    width: 170px;
    /* padding: 11px 2px; */
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    height: 44px;
    line-height: 40px;
    margin: 0 16px 0 40px;
    cursor: pointer;
}

.bannerDeleteBtn {
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px #000;
    text-align: center;
    padding: 4px 8px;
    border-radius: 4px;
    color: #F93E3E;
    cursor: pointer;
    font-size: 12px;
    box-shadow: 0px 0px 5.21px 2.61px rgba(0, 0, 0, 0.32);

}

.bannerSelect {
    height: 35px;
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: white;
    min-width: 135px;
    border: 1px solid;
    width: 178px;
    margin-right: 25px;
}

.addMoreBtn {
    text-align: right;
    margin: 15px 0;
    text-decoration: underline;
}

.addMoreBtn button {
    cursor: pointer;
    background: transparent;
    border: 1px solid #1FFC33;
    color: #1FFC33;
    padding: 5px 15px;
    border-radius: 5px;
}

.masterimg_text {
    font-size: 12px;
    position: absolute;
    right: 0;
    padding-right: 11.2%;
    /* width: 152px; */
    text-align: center;
    top: 10px;
    line-height: 14px;
}

.addItemBtmBtn {
    display: flex;
    justify-content: space-evenly;
    padding: 17px 0;
    width: 100%;
}

.addBannerRow {
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 10px;
    font-weight: 600;
}

.checkBox_div {
    border: 1px solid #fff;
    border-radius: 6px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    min-width: 42px;
    max-width: 42px;
    height: 42px;
    min-height: 42px;
    max-height: 42px;
    margin: 0 20px 0 2px;
}

.activeDisable_btn {
    width: 260px;
    display: flex;
    justify-content: space-between;
}

.activeDisable {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-right: 14px;
    accent-color: #1FFC33;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.activeDisable_disable {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-right: 14px;
    accent-color: red;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

/* .add_product_input {
    width: 100%;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    resize: none;
} */


.add_product_input {
    width: 100%;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    resize: none;
    min-height: 50px;
    overflow-y: scroll;
    background-color: #0c141b;
    /* position: relative; */
}

.add_product_input::-webkit-scrollbar {
    display: block;
}

.add_product_input::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.add_product_input::-webkit-scrollbar-track {
    background: transparent;
    /* Color of the track (the area behind the scrollbar) */
}

.add_product_input::-webkit-scrollbar-thumb {
    background: gray;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners */
}

.add_product_input::-webkit-scrollbar-thumb:hover {
    background: gray;
    /* Color of the scrollbar thumb on hover */
}









.selectReact {
    background-color: #023950;
    color: #000;
    text-align: left;
    width: 100%;
}

.add_inputtag {
    border: none;
    outline: none !important;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    resize: none;
    width: 100%;
}

.tag_button {
    align-items: center;
    border: none;
    padding-left: 4px;
    padding-right: 4px;
}

.tag {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 2px;
    border-radius: 2px;
    background-color: #f5f5f5;
    color: #000;
}

.tag_main_div {
    display: flex;
    flex-wrap: wrap;
}

.addtag {
    display: flex;
    overflow-y: scroll;
    overflow-x: scroll;
    border-radius: 5px;
    color: black;
    width: 100%;
    padding: 7px 5px;
    border: 1px solid #fff;
}

.addBannerImage {
    border: 1px dashed #fff;
    width: 50px;
    height: 65px;
}

.InputRadioRow {
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 32px;
    font-weight: 600;
}

.bg_dark {
    background-color: #000000;
    color: #fff;
  }