.main_div {
    width: 100%;
    margin: 12px 16px;
}
.delbtn{
  padding: 5px;
}
.heading {
    font-size: 36px;
    color: #fff;
    margin: 0 0 12px 0;
}

.addwarehouse_btn {
    border: 3.5px solid #1ffc33;
    color: red;
    border-radius: 7px;
    line-height: 36px;
    height: 40px;
    padding: 0 10px;
    font-weight: 600;
    cursor: pointer;
}
/* .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem; 
  }
   */
   

.Warehouse_card {
    background: white;
    color: black;
    /* display: flex; */
    /* justify-content: space-between; */
    min-height: 100px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 12px;
}

.ViewEdit_div {
    width: 64px;
    height: 64px;
    background-color: #000;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-right: 20px;
}

.ViewEdit_div svg {
    margin: 0 auto;
    width: 26px;
    height: 26px;
}

.ViewEdit_div span {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}

.warehouse_name {
    /* margin-bottom: 0; */
    font-size: 12px;
    font-weight: 300;
}

.modal_div {
    width: 90%;
    display: flex;
    /* color: #fff; */
}

.modal_div input {
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    height: 36px;
    border-radius: 4px;
    margin-bottom: 4px;
    color: #fff;
    padding: 0 5px;
    line-height: 36px;
    font-size: 15px;
}

.modal_div textarea {
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    /* height: 36px; */
    border-radius: 4px;
    color: #fff;
}

.modal_div span {
    line-height: 28px;
}

.days_radio {
    width: 16px !important;
    height: 16px !important;
    margin: 0 10px 0 0px;
    margin-bottom: 0px !important;
}

.status_radio {
    display: flex;
    width: 64%;
}

.button-container {
  display: flex;
  margin-bottom: 10px;


}




.kyc_main_container_div {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 4px 16px 16px;
}

.d_flex_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kyc_main_div {
  padding: 16px;
  width: 100%;
  color: #fff;
  height: calc(100vh - 116px);
}

.kyc_sub_main_div {
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  max-width: 1920px;
  margin: auto !important;
  background-color: #1E2022;
}

.kyc_main_heading {
  font-size: 30px;
  text-align: center;
  margin: 16px;
}

.socialMedia_icons {
  position: absolute;
  /* display: inline; */
  border: 1px solid;
  border-right: none;
  text-decoration: none;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyc_scroll_div {
  width: 100%;
  overflow-y: scroll;
  max-height: 780px;
}

.kyc_scroll_div::-webkit-scrollbar {
  display: none;
}

.text_end {
  text-align: end;
}

.mlrb {
  margin: 0px 16px 24px 16px;
}

.store_Image_div {
  width: 100%;
  height: 100%;
}

.profileAndStore {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  margin-top: 10px;
}

.profile_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 80%;
  height: 80%;
  font-size: 14px;
}

.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  height: 300px;
  margin-right: 10px;
}

.storeImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  width: 100%;
  height: 320px;
}

.d_none {
  display: none;
}

.store_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 90%;
  height: 80%;
  font-size: 28px;
}

.Catalogue {
  margin-top: 20px;
  margin-bottom: 8px;

}

.row_mt_20 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bg_Transparent_w_85_border_p_10 {
  box-sizing: border-box;
  background-color: #1E2022 !important;
  color: #FFFFFF !important;
  width: 84%;
  border: 1px Solid #D3D3D3;
  outline: none;
  padding: 10px;
  text-align: left;
  max-height: 42px;
}

.bg_gray_dark {
  background-color: #1E2022;
  color: #fff;
}

.position_relative {
  position: relative;
}

.bg_Transparent_w_75_border_p_10_ml_30 {
  background-color: #1E2022 !important;
  border: 0.1px Solid #D3D3D3;
  width: 75%;
  padding: 8px 10px;
  margin-left: 40px;
  color: #ffffff;
  outline: none;
}

.row_br_5_bg_dark_p_10 {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #18191B;
  color: #fff;
  padding: 10px;
}

.tw_center {
  text-align: -webkit-center;
}

.text_right {
  text-align: right;
}

.productBtn {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid #1FFC33;
  border-radius: 5px;
  padding: 7px;
  color: #1FFC33;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.product_pending {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid yellow;
  border-radius: 5px;
  padding: 7px;
  color: yellow;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.container {
  padding: 10px;
  max-height: 43vh;
}

.gridContainer {
  display: grid;
  gap: 10px;
}

 .cards{
  width: calc(12.55% - 0px);
box-sizing: border-box;
justify-content: space-between;
position: relative;
flex-direction: column;
padding: 10px;
padding-left: 10px;
border-radius: 10px;
display: grid;
grid-template-columns: repeat( minmax(220px, 1fr));
gap:9px;
/* overflow-y: scroll; */
width: 100%;
position: relative;
} 



/* Small screens: 350px to 400px, show 2 cards per row */

/* Large screens: 701px to 1000px, show 7 cards per row */
@media (min-width: 500px) and (max-width: 1000px) {
  .cards {
    width: calc(33.45% - 10px); /* 7 cards per row */
  }
}
@media (min-width: 1001px) and (max-width: 1300px) {
  .cards {
    width: calc(19% - 0px ); /* 7 cards per row */
  }
}
@media (min-width: 1301px) and (max-width: 1600px) {
  .cards {
    width: calc(20% - 10px ); /* 7 cards per row */
  }
}

/* Extra-large screens: Above 1000px, show 8 cards per row */
@media (min-width: 1601px) {
  .cards {
    width: calc(12.5% - 10px); /* 8 cards per row */
  }
}





.productDisableBtn {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 5px;
  padding: 7px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.bg_Transparent_Kyc_Mrgn {
  background-color: transparent;
  margin-left: 25px;
  margin-right: 25px;
}

.greenClr_fs_12 {
  color: #1FFC33;
  font-size: 12px;
}

.grayClr_fs_12 {
  color: #9A9A9A;
  font-size: 12px;
}

.fs_20_mt_10 {
  font-size: 17px;
  margin: 12px 0 6px 0;
}

.mt_0 {
  margin-top: 0px;
}

.margin {
  margin-top: 10px;
  margin-bottom: 8px;
}

.pl_5 {
  padding-left: 5px;
}

.bg_Transparent_w_98_border_p_maplink {
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid white;
  padding: 15px 10px;
  color: #ffffff;
}

.fs_14 {
  font-size: 14px;
}

.align_items_center {
  align-items: center;
}

.bg_Transparent_w_98_border_p_10 {
  background-color: transparent;
  width: -webkit-fill-available;
  padding: 10px;
  border: 1.5px solid white;
  color: #ffffff;
  outline: none;
}

.text_end_text_danger_fs_12 {
  text-align: end;
  color: #ED4F4F;
  font-size: 12px;
}

.kyc_sub_heading {
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
}

.float_end {
  margin-top: 15px;
  text-align: right;
}

.row_fs_Kyc20_align_items_center {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  /* align-items: center; */
}

.col_10 {
  flex: 0 0 auto;
  width: 75.33333333%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.demo {
  /* padding-left: 5px; */
  font-size: 30px;

}

.col6 {
  flex: 0 0 auto;
  width: 50%;
}

.order {
  flex: 0 0 auto;
  width: 41.66666667%;
  margin-bottom: 8px;
}

.col12 {
  flex: 0 0 auto;
  width: 100%;
}

.col3 {

  flex: 0 0 auto;
  width: 25%;

}

.col9 {
  flex: 0 0 auto;
  width: 75%;
}

.kyc_input {
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid white;
  padding: 5px;
  color: #ffffff;
  outline: none;
  font-size: 18px;
}

.kyc_input_select {
  background-color: transparent;
  width: 82%;
  border: 1.5px solid white;
  padding: 10px;
  color: #ffffff;
  outline: none;
  text-align: left;
}

.kyc_toggle_btn {
  width: 60px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
  text-align: center;
  border: 1px solid;
  border-radius: 25px;
  margin-top: 46px;
  padding: 3px;
  font-size: 16px;
}

.kyc_toggle_btn_profile_img {
  flex: 0 0 auto;
  display: flex;
  text-align: center;
  padding: 1px;
  width: 60px;
  margin: auto;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
  margin-top: 10px;
}

.mr_40 {
  margin-right: 40px;
}

.radio_Btn {
  width: 25px;
  height: 25px;
}

.toggle_btn {
  margin-left: 12px;
  margin-top: 37px;
  padding: 1px;
  width: 60px;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
}

.toggle_btn_active {
  background: #1FFC33;
  width: 50%;
  border-radius: 25px;
}

.toggle_btn_deactive {
  background: #ED4F4F;
  width: 50%;
  border-radius: 25px;
}

.d_none {
  display: none;
}

.justify_space_between {
  justify-content: space-between;
}

.min_height_200 {
  min-height: 200px;
}

.kyc_w_50_mouse {
  cursor: pointer;
  width: 50%;
}

.kyc_dFlex_justifyCenter {
  display: flex;
  justify-content: center;
}

.kyc_col_2_text_center {
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
  padding-top: 44px;
}

.productName_center {
  text-align: center;
  margin-top: 4px;
}

.kyc_upload_aadhaarImg_heading {
  background-color: #282c34;
  height: 200px;
  max-width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  min-height: 200px;
  border: 2.2439px dashed #FFFFFF;
  cursor: pointer;
}

.kyc_upload_aadhaar_image {
  border: 2.2439px dashed #FFFFFF;
  width: 130px;
  height: 180px;
  max-height: 180px;
  margin-top: 30px;
  padding: 10px 25px 10px 25px;
}

.kyc_row_fs_Kyc20 {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  margin-bottom: 20px;
}

.kyc_col_2_item_Center {
  align-items: center;
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.kyc_radioBtn_div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}

.kyc_radioBtn_col_3 {
  flex: 0 0 auto;
  width: 25%;
  align-items: center;
  display: flex;
}

.kyc_mouse_radio_Btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.kyc_radioBtn_col_4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  align-items: center;
  display: flex;
}

.kyc_col_9 {
  flex: 0 0 auto;
  width: 75%;
  align-items: center;
  display: flex;
}

.surface {
  margin-top: 19px;
}

.text_end {
  text-align: end;
}

.darkBtn {
  border: 1px solid #BABABA;
  background-color: transparent;
  color: #BABABA;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.successBtn {
  width: 155px;
  background-color: transparent;
  border: 1px solid #1FFC33;
  border-radius: 5px;
  padding: 10px 0;
  color: #1FFC33;
  font-size: 13px;
  cursor: pointer;
}

.greenBtn {
  border: 1px solid #1FFC33;
  background-color: #1FFC33;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.dangerBtn {
  border: 1px solid #F93E3E;
  background-color: transparent;
  color: #F93E3E;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.uploadLabel {
  border: 1px solid #1FFC33;
  width: 155px;
  padding: 10px 2px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.enterUrlLabel {
  border: 1px solid #F3F3F3;
  width: 200px;
  padding: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.trashBin {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  border-radius: 6px;
  color: #F93E3E;
  cursor: pointer;
}

.caretDown {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  margin-left: 20px;
  border-radius: 10px;
  color: #D0D0D0;
  cursor: pointer;
  cursor: pointer;
}

.col5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.margin_LR {
  margin: 0 10px;
}

.dflex_space_between {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.map_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.map_seacrh_div {
  width: 100%;
  position: relative;
}

.seacrh_inputField {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  height: 40px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  padding: 0 4px;
  font-size: 15px;
  outline: none;
}

.seacrh_inputField1 {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 0 10px;
  outline: none;
  border-radius: 4px;
  font-size: 12px;
}

.seacrh_inputField1::placeholder {
  font-size: 12px;
}

.search_Icon {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-right: 1%;
  cursor: pointer;
}

.google_map {
  border: 0;
  width: 100%;
  height: 400px;
  margin-top: 10px;
}

.accept_modal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 360px;
}

.accept_modal_content {
  width: 90%;
  max-width: 500px;
  background: #1E2022;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: auto;
  padding-top: 2px;
  color: #FFFFFF;
}

.close_btn {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.5rem;
  padding-right: 6px;
}

.message {
  text-align: center;
  margin: 30px 0;
  font-size: 24px;
}

.massageModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
}

.goBack {
  cursor: pointer;
  border-bottom: 1px solid #FFFFFF;
  /* text-decoration-line:  underline; */
}

.searchPlace_div {
  flex: 0 0 auto;
  width: 58.3%;
}

.map_heading_div {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  width: 100%;
}

.map_txt {
  width: 20%;
  padding-bottom: 6px;
}

.OfficeUse_col {
  flex: 0 0 auto;
  width: 33.33%;
}

.leadModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
  margin-top: 70px;
}

.rejectionList_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #FFFFFF;
  padding: 6px 0 4px 0;
}

.rejectionList {
  min-width: 104px;
  width: 104px;
  max-width: 104px;
}

.rejectionList_text {
  width: 50%;
  /* text-align: center; */
}

.rejectionList_checkbox {
  /* width: 40px; */
  margin-right: 4px;
}

.rejectCheckbox {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.rejectListModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 16px auto;
}

.input_field_alert {
  color: red;
}

.profileImage_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed red;
  height: 300px;
  margin-right: 10px;
}

.profile_bg_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 80%;
  height: 80%;
  font-size: 14px;
  color: red;
}

.storeImage_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed red;
  width: 100%;
  height: 300px;
}

.store_bg_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 90%;
  height: 80%;
  font-size: 28px;
  color: red;
}

.rowOffice {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.rowOffice .col3 {
  width: 33.333333%;
  margin-bottom: 20px;
}

.dfac {
  display: flex;
  align-items: center;
}

.dfac label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.profile_Image_div {
  width: 100%;
  height: 100%;
}

.plus_product {
  font-weight: bolder;
  font-size: 14px;
  width: 20px;
}

@media (max-width:2560px) {
  .profileImage {
    width: 280px;
    height: 320px;
  }

  .storeImage {
    width: 100%;
    height: 320px;
  }


}

@media (max-width: 1500px) {
  .profileImage {
    width: 180px;
    height: 220px;
  }

  .storeImage {
    width: 100%;
    height: 320px;
  }

  .profileImage_alert {
    width: 180px;
    height: 220px;
  }

  .storeImage_alert {
    width: 600px;
    height: 250px;
  }
}

@media (max-width: 1200px) {
  .profileImage {
    width: 180px;
    height: 200px;
  }

  .storeImage {
    width: 100%;
    height: 320px;
  }

  .profileImage_alert {
    width: 180px;
    height: 200px;
  }

  .storeImage_alert {
    width: 600px;
    height: 200px;
  }
}

@media only screen and (max-width: 1595px) {
  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 75.33333333%;
  }


}

@media only screen and (max-width: 1350px) {
  .row_fs_Kyc20_align_items_center {
    font-size: 18px;
  }

  .profileImgHW {
    width: 200px;
    height: 200px;
  }

  .storeImgHW {
    width: 600px;
    height: 300px;
  }

  .qr_HW {
    width: 145px;
    height: 145px;
  }

  .storeImgHW {
    width: 635px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .productBtn {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .productDisableBtn {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1220px) {
  .Kyc_Mrgn {
    margin-left: 10px;
    margin-right: 10px;
  }

  /* .productBtn {
   width: 90px;
   background-color: transparent;
   border: 1.5px solid #1FFC33;
   border-radius: 5px;
   padding: 7px 0;
   color: #fff;
   font-size: 14px;
   cursor: pointer;
 } */

  /* .productDisableBtn {
   border: 1px solid #BABABA;
   background-color: transparent;
   color: #BABABA;
   width: 90px;
   padding: 7px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 } */
}

@media only screen and (max-width: 1140px) {
  .storeImgHW {
    width: 535px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 7px;
    margin-right: 7px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 70.33333333%;
  }

  .qr_HW {
    width: 135px;
    height: 135px;
  }

  .productBtn {
    /* width: 85px; */
    font-size: 12px;
    cursor: pointer;
  }

  .productDisableBtn {
    font-size: 12px;
  }
}

/* -------------------------------------------------------------New-KYC------------------------------------------------------------------------ */

.revertBack_btn {
  font-size: 16px;
  margin: 0 10px;
}

.kyc_btn_div {
  padding-top: 10px;
}

.billingDetails_heading {
  font-weight: 400;
  font-size: 24px;
  margin: 16px 0;
}

.profileImage_div {
  width: 280px;
}

.billingDetails_div {
  display: flex;
}

.accountSetting {
  background-color: rgba(27, 28, 29, 1);
  padding: 0 32px;
}


.mapHeading_txt {
  width: 100%;
  padding-bottom: 6px;
}

.accountSetting_div {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}

.accountSetting_left {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.accountSetting_right {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.Catalogue_ml {
  margin-bottom: 26px;
  margin-left: 20%;
}

.kyc_div_height {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
}

.kyc_div_height::-webkit-scrollbar {
  display: none;
}



















/* new css for kyc */
.main {
  width: 100%;
  height: calc(100vh - 65px);
  padding: 10px;
}

.continer {
  width: 100%;
  padding: 10px;
  height: calc(100vh - 75px);
  overflow-y: scroll;
}

.go_back {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Personal_Info {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.Personal {
  width: 30%;
  padding: 20px;
  border-radius: 10px;
}

.Company {
  width: 70%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
}

.Company_FristChile {
  width: 100%;
}

.Company_SeacandChile {
  width: 100%;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profileContainer p {
  font-weight: 600;
  line-height: 1;
}

.imageWrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.fileInput {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 99;
}

.selectPik {
  width: 100px;
  border: 1px solid;
  z-index: 999;
}

.nm_photo {
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin-right: auto;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

.nm_photo img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  /* position: relative; */
}

/* .nm_camera_icon {
  position: absolute;
  top: 50%;
  right: -15%;
} */

.nm_info {
  width: 100%;
  margin-top: 10px;
}

.Company_SeacandChile p {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.Drag_drop {
  width: 100%;
  border: 1px dashed #D9D9D9;
  height: 140px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.aadherCard {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  font-weight: 600;

}

.aadherCard1 {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: red;
}

.textaline {
  text-align: center;
  color: #9C9797;
}

.textaline p {
  line-height: 0.5;
  font-size: 14px;
  font-weight: 600;
}

.textaline1 {
  text-align: center;
  color: #D9D9D9;
}

.textaline1 p {
  line-height: 0.5;
  font-size: 12px;
  font-weight: 400;
}

.browseSpan {
  color: #749AFF;
  border-bottom: 1px solid;
  cursor: pointer;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
  width: 100%;
}

.uploadedImage {
  max-width: 100%;
  max-height: 140px;
  object-fit: cover;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.imagePreview1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
  width: 100%;
}

.uploadedImage1 {
  max-width: 100%;
  object-fit: cover;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.Location {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.LocationAdress {
  width: 25%;
  border-radius: 8px;
  padding: 20px;
}

.LocationMap {
  width: 50%;
  border-radius: 8px;
  padding: 20px;
}

.Account_Setting {
  width: 25%;
  border-radius: 8px;
  padding: 20px;
}

.LocationAdress p {
  font-size: 14px;
  font-weight: 600;
}

.Location_map {
  width: 100%;
  /* height: calc(100% - 75px); */
  /* border: 1px solid; */
  /* margin-top: 10px; */
  border-radius: 8px;
}

.Account_Setting p {
  font-size: 14px;
  font-weight: 600;
  /* line-height: 1; */
}

.redioBtnConintner {
  display: flex;
  /* flex-direction: column; */
  /* margin-top: 20px; */
}

.redioBtnConintner2 {
  display: flex;
  gap: 20px;
}

.redioText {
  font-size: 13px;
  font-weight: 400;
}

.redio_Btn_Conintner_Status {
  margin-top: 20px;
}

.redio_Btn_Conintner_Status p {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.status_redio_btn {
  width: 100%;
  /* border: 1px solid; */
  display: flex;
  gap: 30px;
  /* margin-left: -40px; */
}

.redioCon {
  width: 130px;
  /* border: 1px solid; */
}

.sosal_Media {
  display: flex;
  width: 100%;
  margin-top: 10px;
  /* align-items: center; */
  gap: 10px;
}

.sosale_media_link {
  width: 25%;
  padding: 20px;
  border-radius: 8px;
}

.sosale_media_About {
  width: 75%;
  border-radius: 8px;
  padding: 20px;

}

.sosale_media_About p {
  font-size: 14px;
  font-weight: 400;
}

.kycchack {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
}

.KycChack_coontiner {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.KycChack_coontiner1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Description_textarea {
  width: 100%;
  outline: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 8px;
}

.textarea_text {
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
  font-size: 12px;
  color: #F93E3E;
}

.upload_file {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  padding: 20px;
}

.upload_file_continer {
  border: 1px dashed #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  font-size: 14px;
}


.dropdownContainer {
  position: relative;
  width: 100%;
}

.searchInput input {
  width: 100%;
  border-radius: 4px;
  outline: none;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 100;
}

.dropdownItem {
  cursor: pointer;
  transition: background-color 0.5s;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
}

/* .dropdownItem:hover {
 background-color: var(--hover-bg, #f0f0f0);
 color: var(--hover-color, #000);
 cursor: pointer;
} */

.selectedItem {
  background-color: #d0eaff;
}

.noItems {
  padding: 8px;
  text-align: center;
  color: #999;
}

.filter_cantiner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.select_dealer {
  display: flex;
  gap: 10px;
}

.select_dealerCont {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select_dealerCont :first-child {
  font-size: 14px;
  font-weight: 400;
}

.Transfer_loction {
  display: flex;
  gap: 10px;
  align-items: center;
}

.main2 {
  width: 100%;
  height: calc(100vh - 65px);
  padding: 10px;
  position: relative;
}

.pageContiner {
  position: absolute;
  bottom: 0;
  width: calc(100% - 26px);
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 3px;
  border-radius: 5px 5px 0px 0px;
  font-size: 12px;
  font-weight: 400;
}




.tableContiner {
  width: 100%;
  height: calc(100% - 150px);
  overflow: hidden;
  margin-top: 10px;
}

.scrolllist {
  height: calc(100% - 45px);
  overflow-y: scroll;
}


.tableContiner2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tableContinerTable {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tableContiner1 {
  width: 100%;
  height: 71.5%;
  /* overflow-y: scroll; */
  margin-top: 5px;
}

.responsiveTable {
  list-style-type: none;
  padding: 0;

  .tableHeader {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 3px;
    padding: 13px 9px;
    margin-bottom: 5px;
  }

  .tablerow {
    background-color: #ffffff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 5px;
    align-items: center;
    /* cursor: pointer; */
    position: relative;
    /* overflow-y: scroll */

  }

  .col {
    flex: 1;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    align-items: center;
    display: flex;
    /* border: 1px solid; */
  }

  .col0 {
    flex: 1/4;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    align-items: center;
    display: flex;
    /* border: 1px solid; */
  }

  .coljustyfy {
    flex: 1;
    /* align-items: center;
justify-content: center;
display: flex; */
  }

  .large {
    flex: 2;
    /* pehle box ko bada karne ke liye */
  }

  .uperCase {
    text-transform: uppercase;
  }

  @media all and (max-width: 700px) {
    .tableHeader {
      display: none;
    }

    .TableHeadChackBox {
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }

    .tablerow {
      display: block;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      border-radius: 5px;
    }

    .col {
      /* display: flex; */
      /* justify-content: space-between; */
      /* padding: 10px 0; */
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: left;
      }
    }
  }

}



.cl_checkbox {
  margin-left: 5px;
}

.cl_checkbox {
  position: relative;
  display: inline-block;
}

/* Input */
.cl_checkbox>input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* background-color: rgba(0, 0, 0, 0.6); */
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.5s, transform 0.2s;
}

/* Span */
.cl_checkbox>span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.cl_checkbox>span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 2px 11px 3px 0px;
  border: solid 2px;
  /* Safari */
  border-color: #0065FF;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.cl_checkbox>span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.cl_checkbox>input:checked,
.cl_checkbox>input:indeterminate {
  background-color: #0065FF;
}

.cl_checkbox>input:checked+span::before,
.cl_checkbox>input:indeterminate+span::before {
  border-color: #0065FF;
  background-color: #0065FF;
}

.cl_checkbox>input:checked+span::after,
.cl_checkbox>input:indeterminate+span::after {
  border-color: #fff;
}

.cl_checkbox>input:indeterminate+span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.cl_checkbox:hover>input {
  opacity: 0.04;
}

.cl_checkbox>input:focus {
  opacity: 0.12;
}

.cl_checkbox:hover>input:focus {
  opacity: 0.16;
}

/* Active */
.cl_checkbox>input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.cl_checkbox>input:active+span::before {
  border-color: #0065FF;
}

.cl_checkbox>input:checked:active+span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.cl_checkbox>input:disabled {
  opacity: 0;
}

.cl_checkbox>input:disabled+span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.cl_checkbox>input:disabled+span::before {
  border-color: currentColor;
}

.cl_checkbox>input:checked:disabled+span::before,
.cl_checkbox>input:indeterminate:disabled+span::before {
  border-color: transparent;
  background-color: currentColor;
}

.DrawerWrapperContinetr {
  width: 100%;
  padding: 20px;
  z-index: 9999;
  background: rgba(44, 46, 51, 0.85);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border-radius: 5px 5px 0px 0px ;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.DrawerWrapperContinetr_light {
  width: 100%;
  padding: 20px;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.7);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 5px 5px 0px 0px ;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.button-wrapper1 {
  width: 5vh;
}
.button-wrapper {
  width: 15vh;
}


.edit-mode {
  /* Optional: Add styles specific to edit mode if needed */
}



.workDays {
    width: 100%;
    /* background-color: transparent; */
    /* border: 1px solid #fff; */
    /* height: 36px; */
    /* border-radius: 4px; */
    color: #fff;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 18px;
}

.delete_btn {
    font-size: 10px;
}




/* New CSS */




.mainCon {
    width: 100%;
    height: calc(100vh - 65px);
    padding: 10px 10px 8px;
    /* background-color: white; */
/* background-color: #1ffc33; */
    position: relative;
}

.voucher_maindiv {
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;

}

.wearhousecardicon{
    display: flex;
    padding-right: 40px;
    gap: 20px;
    /* margin-bottom: 40px; */
    /* position: absolute;
bottom: 10px;
left: 10px; */
/* left:"10px", */
}










.dispatch {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .dispatch_dark {
    background-color: #2C2E33;
    border-color: #2C2E33;
  }
  
  .dispatch_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .dispatch_top p {
    margin: 0;
  }
  
  .dispatch_top_left {
    display: flex;
    align-items: center;
    justify-content:  space-around;
    gap: 10px;
    position: relative;            
    top: -8px;                     
    padding-top: 0;             
   padding: 10px;
    width: 100%;                  
    display: flex;                
    justify-content: space-between; /* Distributes children elements with space between them */
  }
  .mgx_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 3px;
  }
  .mgx_top_left_go_back_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-top: 0.3rem;
  }
  .mgx_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    margin-top: 5px;
  }
  .dispatch_top_left_go_back {
    display: flex;
    cursor: pointer;
  }
  .mgx_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .mgx_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .dispatch_go_back_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    /* padding-top: 0.21rem; */
  }
  
  .dispatch_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
    /* margin-top: 9px; */
  }
  
  .dispatch_right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .dispatch_filters {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #e4e7eb;
    width: 100%;
  }
  
  .dispatch_filters_dark {
    background-color: #232529;
    border-color: #232529;
  }
  
  .dispatch_filter_card {
    min-width: 150px;
    height: 110px;
  }
  
  .dispatch_data {
    background-color: transparent;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid #e4e7eb;
    width: 100%;
    overflow: hidden;
  }
  
  .dispatch_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dispatch_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .dispatch_data_search {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #E4E7EB;
    border-radius: 10px;
    /* width: 30vh; */
  }
  
  .dispatch_data_search_dark {
    background-color: #1b1b1b;
    border-color: #1b1b1b;
  }
  
  .dispatch_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .dispatch_item_header {
    font-weight: 500;
    background-color: #D9DCE5;
    color: #646B88;
  }
  
  .dispatch_item_header_dark {
    background-color: #232329;
    color: #fafafa;
  }
  
  .dispatch_data_list_item {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .dispatch_item_value {
    background-color: #fff;
    color: #000;
  }
  
  .dispatch_item_value_dark {
    background-color: #1b1b1b;
    color: #fafafa;
  }
  
  .dispatch_data_list_container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .col {
    flex: 1;
  }
  
  .test_top p {
    margin: 0;
  }
  
  .test_middle {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #e4e7eb;
  }
  
  .test_bottom {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #e4e7eb;
  }
  
  .test_bottom_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .test_bottom_top_right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
  
  .test_bottom_bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    overflow-y: auto;
    max-height: 460px;
    width: 100%;
    justify-content: center;
  }
  
  .test_bottom_bottom::-webkit-scrollbar {
    display: none;
  }
  
  
  @media (max-width: 700px) {
    .test {
      height: 100vh;
      width: 100%;
      margin: 0;
      border: none;
      border-radius: 0;
      background: #f8f9fc;
      overflow: auto;
      padding: 0;
    }
  
    .test_top,
    .test_middle {
      border-radius: 0;
      border: none;
    }
  }
  
  
  
  
  
  .right_main {
    width: 100%;
    /* background-color: transparent; */
    background-color: white;
    color: #D9DCE5;
    padding: 0 15px;
}
/* .right_main { */
    /* width: 100%; */
    /* background-color: transparent; */
    /* background-color: white; */
    /* color: #D9DCE5; */
    /* padding: 0 15px; */
/* } */
/* Hover effect */
.statusCard_div {
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  .statusCard_div.hovered {
    background-color: white; /* Background color when hovered */
    border: 2px solid #000; /* Border color on hover */
  }
  
  /* Active effect */
  .statusCard_div.active {
    border: 2px solid #007bff; /* Blue border when card is active */
  }
  
  /* Default border */
  .statusCard_div {
    border: 2px solid transparent; /* Default border */
  }
  
  /* Optional: For styling the card when it's not hovered/active */
  .statusCard_div {
    background-color: var(--background-color); /* Set background depending on mode (dark/light) */
  }
  

.heading {
    font-weight: 600;
    font-size: 30px;
    margin: 16px 0 0px 0;
}

.superAdmin_heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
}

.dispatchHeading {
    font-weight: 600;
    font-size: 30px;
    margin: 6px 0 0px 0;
}

.dashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    line-height: 1.5rem;
    justify-content: space-between;
}

.dispatchDashboard_header {
    display: flex;
    /* flex-wrap: wrap; */
    padding: 12px 0;
    /* line-height: 1.5rem; */
    justify-content: flex-end;
    /* align-items: center; */
}

.dealer_search_div {
    position: relative;
    width: 20%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 50px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 200%;
    min-width: 200px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dealer_header_right {
    display: flex;
    flex-direction: row;
}

.dealer_Sort_By {
    padding-right: 30px;
}

.dealer_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dealer_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.dashboard_sortBy_select option {
    color: #000000;
    /* background-color: #ffffff !important; */

}

.dashboard_header_select_days {
    max-height: 49px;
    font-size: 13px;
    color: white;
    background-color: black;
    /* background-color: #fff; */
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 410px;
    justify-content: center;
}
.dashboard_header2_section {
    margin-left: 100px;
}

/* .dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    gap: 10px;
  padding: 10px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 3px;
    justify-content: center;
} */
/* .dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    gap: 10px;
  padding: 10px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 360px;
    justify-content: center;
} */

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    /* gap: 10px; */
    padding: 10px;
    color: black; /* Default text color */
    /* background-color: #fff;  */
    
    display: flex;
    flex-direction: row;
    /* border-radius: 25px; */
    align-items: center;
    width: 300px;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    /* margin-left: "20%"; */

}

.dashboard_header_select_week:active {
    color: white; /* Text becomes white when clicked */
  /* Background becomes black when clicked */
}
/* Add this to your CSS file */
.dashboard_days_select {
    padding: 10px 20px;
    cursor: pointer;
    color: black; /* Default text color */
    background-color: transparent; /* Default background color */
    border: 1px solid transparent; /* Optional: add border for better visibility */
    transition: all 0.3s ease; /* Smooth transition for hover or active states */
  }
  
  .dashboard_days_select_active {
    color: white; /* Active text color */
    background-color: black; /* Active background color */
    border: 1px solid black; /* Border for active state */
  }
  

.recipt_tabs_div {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
    padding: 0 6px;
}

.dashboard_days_select {
    /* width: 100px; */
    width: 95px;
    text-align: center;
    /* border-radius: 25px; */
    border-radius: 10px;
    /* padding: 10px 0px; */
    padding: 13px 0px;
    cursor: pointer;
}

.dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: black;
    
    /* background-color: #E3E7EF; */
    cursor: pointer;
    font-weight: bold;
}

.dashboard_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.dashboard_main_div::-webkit-scrollbar {
    display: none;
}

.dashboard_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    /* min-width: 270px; */
}

.dashboard_search_div {
    position: relative;
    width: 16%;
    display: flex;
}

.dashboard_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    font-size: 12px;
}




.dashboard_sortBy_txt {
    font-size: 14px;
    padding-left: 15px;
    color: #000;
    font: bold;
}

.dashboard_sortBy_select {
    border: 1px solid #1E2022;
    height: 40px;
    width: 50px;
    background-color: transparent;
    color: #000;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    /* text-align: left; */
    font-size: 13px;
    
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.dashboard_sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.statusCard_div {
    display: flex;
    align-items: center;
    gap: 14px;
    cursor: pointer;
  }
  
.dashboard_goback_icon{
    margin-right: 5px;
    margin-top: 5px;
}
.statusCard {
    box-sizing: border-box;
    width: 14%;
    height: 130px;
    /* background: #1E2022; */
    background: white;

    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.danger_active {
    border: 3px solid #F32D3C;
}
.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusBlue {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    max-width: 96px;
}

.scroll {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scroll::-webkit-scrollbar {
    display: none;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    border-collapse: unset;
    border-spacing: 0 10px;
    width: 99% !important;
}



tr.item {
    /* border-color: #2C2E33; */
    border-style: solid;
    border-width: 5px 0;
    line-height: 4 !important;
    /* font-weight: 200; */
    /* font-size: 16px; */
    font-size: 12px;
    /* padding: 10px; */
    /* text-align: left; */
    cursor: pointer;
    color:  #000;
    overflow-y: auto;
    
}


.item td,
th {
    /* padding: 0 10px; */
    /* border-color: #1E2022; */
    border-color: black;
    text-align: left;
    padding: 10px;
    overflow-y: auto;
    margin-top: 9px;

}

table.itemTable {
    /* caption-side: bottom; */
    border-collapse: unset;
    /* border-spacing: 0 10px; */
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    /* border-radius: 7px 0 0 7px; */
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}

.table_txt_green {
    color: #05FF00;
    font-weight: 600;
}

.table_txt_orange {
    color: #FFC776;
    font-weight: 600;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
}
.itemtabledata{
    overflow-y: auto;

}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

.actionPending_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 80px 0 0 0;
    min-height: 480px;
}

.actionPending {
    width: 20%;
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    min-height: 480px;
    position: relative;
}

.InactiveDealers {
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 80%;
    margin-left: 40px;
}

.actionPending_heading {
    border-bottom: 1px solid #4D4D4D;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
}

.takeAction {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    height: 100%;
    padding-bottom: 24px;
    position: absolute;
}

.InactiveDealers_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px
}

.InactiveIcon_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inactiveIcon_div {
    border: 1px solid #4D4D4D;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
}

.inactiveIcon {
    width: 30px;
    height: 30px;
}

.whatsappIcon {
    width: 40px;
    height: 40px;
}

.inactive_profile_details {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 30px 0;
}

.profile_details {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    min-width: 220px;
}

.inactive_dealer_img {
    margin: 0 10px;
}

.selectAll {
    float: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4px;
    padding-right: 30px;
}

.textarea_css {
    background: #18191B;
    border-radius: 6.62606px;
    color: #FFFFFF;
    width: 100%;
    margin: 24px 0;
    border: 0;
    outline: none;
    padding: 10px;
    resize: none;
}

.progressBar_center {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shortBy_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 6px 0;
}

.adminHeight {
    overflow-y: scroll;
    max-height: calc(100vh - 416px);
    min-height: calc(100vh - 416px);
}

.adminHeight::-webkit-scrollbar {
    display: none;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dispatchOrders_height {
    overflow-y: scroll;
    /* max-height: 528px; */
    /* min-height: 528px; */
    min-height: 200px;
    max-height: 430px;
    font-size: 14px;
    /* cursor: pointer; */
    font-weight: 500;
text-align: left;    /* border: 1px solid red; */
/* padding: 10px; */
}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
} */
 .startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}


::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.startEndDate_div {
    margin-left: 40px;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 16px;
    line-height: 2px;
    /* height: 10px; */
    /* margin: 5px; */
    justify-content: center;
}
.reload_icon{
    font-size: 5px;
}
.thpadding{
    padding: 10px;
}
.search{
    display: flex;
    margin-top: 10px;
    /* cursor: pointer; */
    
}

.loadingOrders_dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}


.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.superAdmin_height {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
    /* min-height: 528px; */
}

.superAdmin_height::-webkit-scrollbar {
    display: none;
}

.tableIcons_center {
    text-align: center !important;
}

.table_status {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_pending {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 1.5em !important;
}

.table_status_green {
    color: #1FFC33;
    border: 1px solid #1FFC33;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_red {
    color: #F32D3C;
    border: 1px solid #F32D3C;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding-left: 20px;
    /* padding: 0 20px; */
}

.table_heading {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_heading_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_heading_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.recipt_scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 490px);
    min-height: calc(100vh - 490px);
}

.recipt_scrollTable::-webkit-scrollbar {
    display: none;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    border-radius: 6px;
    cursor: pointer;
    margin: 10px 0;
}

.table_content_subdiv {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    /* margin: 10px 0; */
    /* padding: 0 20px; */
    padding-left: 20px;
    cursor: pointer;
    position: relative;
}

.dropdown_content_div {
    padding: 0 108px;
}

.table_txt {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_txt_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_txt_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.dropdown_details_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.dropdown_details_div b {
    font-weight: 600;
}

.dropdown_reason {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #1FFC33;
    padding-top: 6px;
    padding-bottom: 12px;
}

.ordersName_truncate {
    /* margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative; */
    /* text-align: center; */
}

.showOverlay {
    position: absolute;
    background-color: #FFFFFF;
    width: 100px;
    right: 0;
    margin-right: 38px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-top: 8px;
}

.showOverlay span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.showOverlay span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

.text_center {
    text-align: center;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.message {
    text-align: center;
    margin: 16px 0 0 0;
    font-size: 24px;
}

.modal_backdrop {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
}

.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 100px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 40px;
    /* z-index: 999; */
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

/* @media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 20px;
        position: absolute;
        bottom: 0;
    }
} */

.warningInput {
    box-sizing: border-box;
    width: 88%;
    height: 48px;
    border: 0.5px solid #F3F3F3;
    border-radius: 4px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 0 6px;
    text-align: center;
    font-size: 20px;
    margin-top: 16px;
}

.warnigModal_btn {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 24px auto 10px;
}

.ReciptModalHeader {
    position: absolute;
}

.reciptModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%;
}

.reciptModal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #1E2022;
    color: #FFFFFF;
    border-radius: 12px;
}

.reciptModal_content::-webkit-scrollbar {
    display: none;
}

.text_center {
    text-align: center;
}

.reciptHeader_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.reciptHeader_detail {}

.recipt_tableDiv {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #FFFFFF;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptHeading {
    font-weight: 600;
    /* padding-bottom: 40px; */
}

.reciptContentDiv {
    padding: 0 40px;
}

.recipt_selectDiv {
    display: flex;
}

.recipt_selectDiv_Account {
    display: flex;
    margin: 16px 0;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    padding: 0 6px;
}

.reciptMethod_select option {
    background-color: #1E2022;
    text-align: left;
}

.method_txt {
    /* margin-right: 40px; */
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.reciptAmount_txt {
    margin: 18px 0;
    font-size: 18px;
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;
}

.recipt_inputField {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin: 32px 0 12px 0;
}

.recipt_inputField_ref {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin-top: 16px;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 16px auto 24px;
}

.recipt_payNow {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    line-height: 28px;
}

.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

.recipt_AmountDiv {
    margin: 20px 0;
}

.reciptAmount_input {
    background-color: transparent;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    float: right;
    min-width: 400px;
}

.amount_txt {
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.dragAndDrop_div {
    height: 224px;
    position: absolute;
    width: 516px;
    background-color: transparent;
    z-index: 1;
    /* margin: 16px 0; */
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 28px 0 0px 0;
    /* margin: 12px 0; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}

.border_OR_div {
    border: 1px solid #FFFFFF;
    width: 80%;
    margin: 0;
}

.OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
}

.browseFiles_btn {
    border: 1px solid #FFFFFF;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.packing_image {
    background: transparent;
}

.methodAndAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.method_selectDiv {
    width: 50%;
    margin-right: 5px;
}

.method {
    font-size: 20px;
    display: flex;
}

.refund_width {
    width: 88%;
    margin: auto;
}

.methodSelect {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #FFFFFF;
}

.amount_input {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.methodSelect option {
    background-color: #1E2022;
}

.submethod_txt {
    font-size: 20px;
}

.cancel_receipt_btn {
    font-size: 14px;
    font-weight: 500;
}

/* ----------------------------------DispatchDasboard-Mobile--------------------------------------------- */

.main_container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}
.maincont{
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 65px);
    padding: 10px;
}

  
  .card {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  



  
  
  /* Default card styles */


/* Default card styles for larger screens (tablet and up) */
.statusCard_div .statusCard {
  width: 30%; /* Ensure three cards fit in one row */
  height: 100px; /* Default height */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px; /* Optional rounded corners */
  text-align: center;
  box-sizing: border-box;
  padding: 5px;

  font-size: 12px; /* Default font size */
}
 .statusCard_div {
    border: 2px; 
    border-radius: 5px; 
    padding: 10px;
background-color: white;   
cursor: pointer;
  border-radius: 14px;
margin-top: 10px;

  } 
/* .Inputmaindiv{
    display: flex;
    flex-direction: column; 
    gap: 15px;
    align-items: center; 


} */
 
 .page1{margin-left: 5px;
    margin-top: 8px;
}
.page2 {
    margin-right: 10px;
    margin-top: 8px;
}
.SlidingFilterdiv1{
    display: flex;
    gap: 16px;
    justify-content: center; 
}


.paginationdiv{
    display: flex; 
    justify-content: space-between; 
    position: absolute; 
    bottom: 8px; 
    padding: 4px;
    width: 98.9%; 
    border-radius: 20px 20px 0 0; 
    height: 60px; 
    /* margin-top: 5PX; */
}


  .reload_anddownloadbuttondiv{
    display: flex;              
    align-items: center;      
    gap: 17px;  
  }
  .reload_button{
     background-color: black;        
  color: white;                   
  padding: 15px 20px;             
  border: none;                    
  border-radius: 8px;           
  display: flex;                
  align-items: center;           
  cursor: pointer;               
  height: 45px;                   
  justify-content: center;       
  }

/* Responsive adjustments for screens up to 700px */
.statusCardContainer {
    display: grid;
    gap: 16px; /* Space between cards */
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); /* Default layout */
    width: 100%;
  }
  
  .statusCard {
    min-width: 140px;
    /* cursor: pointer; */
  }
  
  /* Media query for 350px */

    .statusCard {
      min-width: 60px; /* Adjust card size if needed */
    }
  
  
  /* Media query for 351px to 700px */

  

  /*  new css */
  .mainContiner{
    height: calc(100vh - 65px);
    position: relative;
    /* padding: 10px; */
  }
  .secondmainContiner{
    height: calc(100vh - 65px);
    padding: 10px 20px;
  }




  /* General Container Styles */
.mainContainer {
  padding: 10px;
}


/* Background & Scrollable Content */
.contentContainer {
  color: #E4E7EB;
  padding: 10px;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.contentContainerDark {
  background-color: #2C2E33;
}

.contentContainerLight {
  background-color: #F8F9FC;
}

/* Header Section */
.headerSection {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.headerLeft {
  position: relative;
  top: -10px;
  padding: 2px;
}

.headerLeftGoBack {
  display: flex;
  cursor: pointer;
}

.goBackText {
  margin-top: 4px;
  margin-right: 0;
}

.pathText {
  color: #9C9797;
}

.pathTextLight {
  color: #646B88;
}

/* Filters Section */
.filtersSection {
  display: flex;
  gap: 16px;
  align-items: center;
}

.inputDateSection {
  display: flex;
  gap: 15px;
}

.reloadButton {
  background-color: black;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  justify-content: center;
}

.reloadText {
  margin-right: 10px;
  font-size: 12px;
}

.downloadButton {
  padding: 8px 20px;
  align-items: center;
}

/* Status Cards */
.statusCard {
  border-radius: 14px;
  margin-top: 10px;
}

.statusCardDark {
  background-color: #232529;
}

.statusCardLight {
  background-color: white;
}

.statusBadge {
  padding: 5px;
  margin-left: 8px;
}

.statusTextNew {
  color: #4164E3;
}

.statusTextInProgress {
  color: #FFAD00;
}

.statusTextPacked {
  color: #008C00;
}

.statusTextPending {
  color: #FF0000;
}

.statusTextCancelled {
  color: #FF0000;
}

/* Table Styles */
.tableContainer {
  width: 100%;
  margin-top: 18px;
  padding: 10px;
}

.tableHeader {
  background-color: #D9DCE5;
  color: #646B88;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.tableHeaderDark {
  background-color: #232529;
  color: #E4E7EB;
}

.tableRow {
  background-color: white;
  color: black;
  border: 5px solid #F8F9FC;
  gap: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableRowDark {
  background-color: #1B1D21;
  color: white;
  border: 5px solid #2C2E33;
}

.tableRowYellow {
  color: #FFAD00;
}

.tableRowGreen {
  color: #008C00;
}

.tableRowBlue {
  color: #4164E3;
}

.tableRowRed {
  color: #FF0000;
}

.tableRowWhite {
  color: #FFFFFF;
}

.tableCell {
  padding: 10px;
}

.tableCellStatus {
  padding: 10px;
  text-align: center;
}

/* Pagination Section */
.paginationContainer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  padding: 4px;
  width: 98.9%;
  border-radius: 20px 20px 0 0;
  background-color: #FFFFFF;
  height: 60px;
}

.paginationContainerDark {
  background-color: #1B1D21;
}

.paginationLeft,
.paginationRight {
  margin-top: 8px;
}

.paginationButton {
  margin-left: 5px;
  margin-top: 8px;
}

/* .order_filter_active {
    border: 1px solid #4164E3; 
}
  .order_filter_dark {
    border: 1px solid #1B1D21; 
  }
  
  .order_filter_dark:hover {
    border: 1px solid #41e3d5; 
  }
  
  .order_filter_active:hover {
    border: 1px solid #4164E3;
  }
   */
   /* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order_top p {
    margin: 0;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.order_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.order_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.so_right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter_dark {
    border: 1px solid #1B1D21;
}

.order_filter:hover {
    border-color: #4164E3;
}

.order_filter_dark:hover {
    border-color: #FFFFFF;
}

.order_filter_active {
    border-color: #4164E3;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.order_data_list_row,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

.order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
}

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

.order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.so_order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.so_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 1rem;
}

.so_data_list_row_1,
.so_data_list_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #E4E7EB;
    text-align: left;
    width: 100%;
    color: #646B88;
    background-color: #fff;
}

.so_data_list_row_1 {
    padding: 0 10px;
    font-weight: 600;
    color: #646B88;
    background-color: transparent;
    border: none;
}

.so_data_list_row_dark {
    background-color: #1B1D21;
    color: #fafafa;
    border: none;
}

.so_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.col {
    flex: 1;
}

.so_data_list_container>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_top_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_top_right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.so_footer_top_right_1 {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 340px;
}

.so_footer_top_right_1_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_footer_top_right_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.so_footer_top_right_1>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_footer_top_right_1_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_footer_top_right_1_check>p {
    margin: 0;
    line-height: 1;
}

.so_footer_top_right_1_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_footer_top_right_1_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_footer_top_right_1_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.so_shipping {
    display: flex;
    align-items: center;
}

.so_shipping input {
    max-width: 60px;
    border: 1px solid #646B88;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    padding: 0.1rem 0.5rem;
    background-color: transparent;
}

.so_footer_top_right_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
}

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.so_inp {
    background-color: transparent;
    border: 1px solid #E4E7EB;
    border-radius: 6px;
    outline: none;
    color: #646B88;
    padding: 0.2rem 1rem;
    max-width: 150px;
    min-width: 50px;
}

.so_inp_dark {
    color: #fff;
    border-color: #646B88;
}

.so_right>div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.so_right>div>p {
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #646B88;
    font-weight: 500;
}

.so_right>p {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}


.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}
.search {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .dropdown-container {
    display: flex;
    gap: 10px;
  }
  
  /* @media (max-width: 1800px) and (min-width: 600px) {
    .dropdown-container {
      flex-wrap: wrap; 
      justify-content: center; 
    }
  } */
.search-container {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 700px) {
    .search-container {
      width: 100%; /* Make it take full width */
      justify-content: center; /* Center the search bar */
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 11px;
    /* padding-left: 9px; */
  }
  
 .redioBtnConintner {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
 
  .redioBtnConintner2 {
    /* display: flex; */
    margin-bottom: 5px;
    gap: 20px;
    /* margin-top: 10px; */
  }
 
  .redioText {
    font-size: 13px;
    font-weight: 400;
  }
 
  .redio_Btn_Conintner_Status {
    margin-top: 20px;
  }
 
  .redio_Btn_Conintner_Status p {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
  }
 
  .status_redio_btn {
    width: 100%;
    /* border: 1px solid; */
    display: flex;
    gap: 30px;
    /* margin-left: -40px; */
  }
 
  .redioCon {
    width: 130px;
    /* border: 1px solid; */
  }
.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item */
.si_inp {
    position: relative;
}

.search_item {
    position: absolute;
    top: 150%;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    overflow: hidden;
    color: #000;
    padding: 0.3rem 0;
    width: 100%;
    z-index: 2;
}

.search_item_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.search_item_header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.8rem;
    border-bottom: 1px solid #E4E7EB;
}

.search_item_header>p {
    margin: 0;
    cursor: pointer;
}

.search_item_header>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.item_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.table_header_row,
.table_row {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    gap: 1rem;
    padding: 0.2rem 0.8rem;
    border-bottom: 1px solid #E4E7EB;
    column-gap: 180px;
}

.table_header_row div,
.table_row div {
    text-align: left;
    padding: 0 0.5rem;
    background-color: grey;
    white-space: nowrap;
}

.item_list {
    display: flex;
    flex-direction: column;
    max-height: 6rem;
    overflow-y: auto;
}

.table_row:hover {
    background-color: #e4e7eb;
    color: #000;
}

/* new search item */
.si_table_container {
    max-height: 160px;
    overflow-y: auto;
}

.si_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.si_table th,
.si_table td {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
}

.si_table tbody .selected_row:hover {
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: large;
}

.selected_row_dark {
    background-color: #d3e0ff;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
}

/* new order table approach */
.order_table {
    width: 100%;
    border-spacing: 0 10px;
}

.order_table th,
.order_table td {
    text-align: left;
    border: none;
    font-size: 12px;
    font-weight: 400;
    padding: 10px;
}

.order_table .table_header {
    font-weight: 500;
    color: #646B88;
}

.order_table .table_header_dark {
    color: #F8F9FC;
}

.order_table .table_cell {
    background-color: #FFFFFF;
    margin-bottom: 5rem;
}

.order_table .table_cell_dark {
    background-color: #1B1D21;
    color: #F8F9FC;
}

.table_cell .inp_div {
    width: 100%;
    position: relative;
}

.desc {
    color: #000000;
    font-size: 12px;
    width: 1000%;
    padding: 0 0.5rem;
}

.item_inp {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
}

.item_inp_dark {
    color: #FFFFFF;
}

/* shipping charges modal  */
.shipping_charges_modal {
    padding: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 0px 4px 50px 0px #00000026;
}

.shipping_charges_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.shipping_charges_modal>img {
    margin: 1rem auto;
}

.shipping_charges_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

.shipping_charges_modal>div {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
}

.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
    /* margin-top: 0.2rem; */
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
  
  
  
  
  .bg_white {
    /* background-color: #fff; */
    /* width: 100%; */
}

.bg_dark {
    /* background-color: #000000; */
    /* background-color: #000000; */
    background-color: black;
    color: #fff;
    width: 100%;
    padding: 10 14px;
}
.mgx_top_left_go_back {
  display: flex;
  cursor: pointer;
}
.mgx_top_left_go_back {
    display: flex;
    cursor: pointer;
  }
.heading {
    /* font-family: 'Playfair Display'; */
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.08em;
    margin: 15px 0;
    /* text-align: center; */
}

/* .packingHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
} */

.packingHeading h2 {
    margin: 0;
    width: 90%;
}

.customers {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;
    /* margin: 0 5px; */
}

/* .customers th, td {
    border: 1px solid;
} */

.bg th {
    border: none;
    padding: 8px;
    background: #1E2022;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg2 th {
    border: none;
    padding: 8px;
    background: #f2f2f2;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #1E2022;
    text-align: center;
}

.cursor tr {
    cursor: pointer;
    margin-top: 20px;
}

.bg2 td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #f2f2f2;
    text-align: center;
}

.customers th {
    padding-top: 12px;
    padding-bottom: 12px;

}

.customers td {
    padding: 10px 2px;

}

.packingTd {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;


}

.packingTd td {
    padding: 10px 2px;
    border: 1px solid;
}

/* -----------------------voucher---------------------- */
.voucher_main {
    width: 75%;
    margin: auto;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 50px;
    margin-top: 20px;
}

.voucher_maindiv {
    /* width: 98.2%; */
    width: 100%;
    /* background: white; */
    /* background: #1E2022; */
    /* box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16); */
    /* border-radius: 10px; */
    height: 100%;
    /* border: 12px #1FFC33; */

    /* padding:10px; */
    /* /* margin: 16px 0; 
    margin-left: 10px ; */
    background-color: white;

    position: relative;
}
.voucher_maindiv1 {
    width: 100%;
    /* background: black; */
    /* background: #1E2022; */
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    /* border-radius: 10px ; */
    padding: 20px 0 18px 0;
    margin: 10px 0;
    position: relative;
}

.backArrow {
    cursor: pointer;
    text-decoration: underline;
}

.voucher_heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.08em;
    text-align: center;
    /* margin: 0 0 15px 0; */
}

.voucher_flex1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    padding: 1px;
    
   
}
.voucher_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    /* padding: 2px; */
   
}
.packingvoucherth{

    position: sticky;  /* Makes the element stick to the viewport during scrolling */
    top: 0;            /* The element will stick to the top of its container */
    z-index: 1;        /* Sets the stack order of the element */

padding: 8px;
}
.voucher_flex1{
    display: flex;
    align-items: center;
    gap: 120px;
    white-space: nowrap;
}
.packingvouchertablediv {
    width: 100%;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  /* cursor: pointer; */
  border-radius: 5px;
}

.packingvouchertable {
     width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}


.voucherFlexDirection {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.packingVoucher_left {
    min-width: 300px;
    /* margin-top: 34px; */
}

.slipheading_div {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    /* margin: 10px; */
    margin-right: 500px;
    /* margin-bottom: 10px; */
    /* align-content: flex-start; */
}

.sliptext {
    font-weight: 300;
    font-size: 14px;
    color: #000000;
}
.sliptext1 {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.sliptextGustin {
    font-weight: 400;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
}

.slipDetailsTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: black;
    /* width: 50%; */
    padding-top: 8px;
    
}

.slipDetailsData {
    font-weight: 300;
    font-size: 14px;
    /* line-height: 10px; */
    color: black;
    /* width: 50%; */
    /* padding-top: 6px; */
}

.slipDetailsDataSelect {
    border: 1px solid #E4E7EB;
    border-radius: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding-top: 6px;
    text-align: left;
    margin-top: 4px;
}
.slipDetailsDataSelect1 {
    /* border: 1px solid; */
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding-top: 6px;
    text-align: left;
}

.slipDetailsDataSelect option {
    background-color: #1E2022;
    
}

.selectheading {
    font-weight: 400;
    font-size: 20px;
    margin: 20px 0 4px 0;
}

.select {
    width: 358px;
    height: 44px;
    border: 0.826924px solid #F3F3F3;
    border-radius: 2px;
    outline: none;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    background-color: #1E2022;
    text-align: left;
}


.btn_div {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    /* border: 1px solid red; */
    padding: 5px;
    height: auto;
    
    position: relative;

}
.packingvouchertd{
    padding: 8px;
  font-size: 12px;
  cursor: pointer;

}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background-color: black;
    color: white;
}
.packingvouchertbody{
    margin-top: 5px;
}
/* .packingvouchertr{
    border-bottom: 1px solid #646B88;
} */


.packingdispachstatusdiv{ display: flex;                         /* Enables flexbox layout */
    justify-content: flex-end;             /* Aligns items to the right (end) of the container */
    width: 100%;                           /* Makes the element take up the full width of its parent */
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2); /* Adds a shadow at the top of the element */
    padding: 5px;                   
    border-top-left-radius: 20px;          /* Rounds the top-left corner */
    border-top-right-radius: 20px;         /* Rounds the top-right corner */
    height: auto;                          /* Allows the height to adjust based on the content */
    position: absolute;                    /* Positions the element absolutely in relation to its nearest positioned ancestor */
    bottom: 0;  }



/*-------------------- modal-------------- */
.modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 180px;
}

.modal_content {
    width: 90%;
    max-width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #000;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.modal_contentdiv {
    width: 90%;
    max-width: 400px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.input {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;
    height: 40px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border-radius: 3px;
    max-width: 383px;
    padding: 0 0 0 8px;
    line-height: 36px;
}

.inputcolor {
    width: 50%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    /* background: #fff; */
    /* color: #000; */
    /* border: 0.5px solid #000; */
    border-radius: 3px;
}

.inputpack {
    width: 10%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
}

.inputcolorpack {
    width: 20%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #fff;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 3px;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.modalBtn_div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    /* max-width: 90px; */
    /* margin: 32px auto 5px; */
}

.flexdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}

.label {
    width: 50%;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
}




.fileimg {
    Width: 80px;
    Height: 50px;
    text-align: center;
    border: 1px solid #ffffff66;
}

.deleteicon {
    margin: 0;
    color: #F93E3E;
    font-weight: 300;
    font-size: 8.7524px;
    letter-spacing: 0.08em;
}

.deletediv {
    width: 46px;
    min-height: 46px;
    padding: 1px 0;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 2.1881px;
    /* border: 0.364683px solid #F93E3E; */
}

.voucherStatus {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding-right: 90px;
}

.status {
    color: #FF9B04;
    font-weight: 500;

}

.status_blue {
    color: #1E3DAA;
    font-weight: 500;

}

.statuscolor1 {
    color: #FF0D0D;
    font-weight: 500;

}

.statuscolor3 {
    color: #28699C;
    font-weight: 500;

}
.statuscolor4{
    color: #00A052;
}
.statuscolor9{
    color: #FF9B04;
}
.statuscolor12{
    color: #F93E3E;
}
.orderStatus_Txt {
    font-size: 15px;
    padding-bottom: 4px;
    font-weight: 600;
}
.statuscolor8{
    color: red;

}

.packing_left {
    text-align: left;
}

.dot {
    font-size: 48px;
    margin-bottom: 13px;
    margin-right: 4px;
}

.status1 {
    color: red;
}

.status2 {
    color: green;
}

.status3 {
    color: #DDE100;
}

.closeicon {
    float: right;
}

.packers_float {
    float: right;
    margin-bottom: 20px;
}

/* --------------create-packers------------------ */

.packersheading {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin-top: 0;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.col {
    width: 44%;
}

.flex {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.packertext {
    width: 40%;
    font-size: 18px;
    color: darkgray;
}

.chooseProduct_heading {
    font-size: 18px;
    color: darkgray;
}

.createinputdark {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    color: #fff;
    background-color: #1E2022;
    border: 0.826924px solid #F3F3F3;
}

.createinput {
    width: 100%;
    height: 30px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    border: 0.826924px solid #F3F3F3;
}

.margin {
    margin: 10px;
    padding-right: 16px;
}

.box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 0.870311px solid darkgray;
    padding: 4px;
    width: 100%;
    max-width: 600px;
}

.products {
    margin: 5px;
    padding: 2px 8px;
    border-radius: 3.94832px;
    border: 1.74062px solid #1FFC33;
}

.radiobtn {
    accent-color: green;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.radiobtnred {
    accent-color: red;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.createbtn {
    width: 50%;
    display: flex;
    margin: 20px auto;
    justify-content: space-around;
}

.createpackers_btnone {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #F93E3E;
}

.createpackers_btnone:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #F93E3E;
    border: 1px solid #F93E3E;
}

.createpackers_btn {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #1FFC33;
}

.createpackers_btn:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #1FFC33;
    border: 1px solid #1FFC33;
}

.itemInputs {
    display: inline;
    margin: 6px;
    border: 0.5px solid darkgray;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
    border-radius: 5px;
}

.itemInputs_selected {
    display: inline;
    margin: 6px;
    border: 0.5px solid #1FFC33;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

/* --------------------------------------------------------------------------------------- */
.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

@media (max-width: 1400px) {
    .heading {
        font-size: 26px;
    }
}

.complain_header_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.complain_Sort_By {
    /* padding-right: 30px; */
}

.complain_sortBy_txt {
    font-size: 18px;
    padding-right: 8px;
}

.complain_sortBy_select {
    border: 2px solid #3A3A3A;
    height: 44px;
    width: 140px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
}

.complain_sortBy_select option {
    color: #fff;
    background-color: #000;
    text-align: left !important;
}

.complain_search_div {
    position: relative;
    width: 12%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 150px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.addAdjestmentBtn {
    color: #FFFFFF;
    line-height: 24px;

}

.margin_top {
    margin-top: 20px;
}

/* -----------------------------------------------orders------------------------------------------------------ */
.table_heading_div {
    box-sizing: border-box;
    height: 68px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    /* padding: 0 40px; */
}

.table_heading_20 {
    width: 20%;
    text-align: center;
}

.table_heading_15 {
    width: 15%;
    text-align: center;
}

.table_heading_10 {
    width: 10%;
    text-align: center;
}

.table_content_div {
    height: 56px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 12px 0;
    /* padding: 0 40px; */
    cursor: pointer;
}

.table_txt_20 {
    width: 20%;
    text-align: center;
}

.table_txt_15 {
    width: 15%;
    text-align: center;
}

.table_txt_10 {
    width: 10%;
    text-align: center;
}

.table_txt_green {
    color: #05FF00;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}


.product_date {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-transform: uppercase;
    /* color: #9D9D9D; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    padding: 0 20px;
}

.itemCard_div {
    overflow-y: scroll;
    height: 420px;
    padding: 0 20px;
}

.itemCard {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #18191B;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemCard_light {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
}

.itemCard_left {
    display: flex;
    width: 40%;
}

.itemImage {
    width: 74px;
    height: 90px;
    margin: 0 12px;
}

.itemDetail_div {
    width: 100%;
    letter-spacing: 2px;
}

.itemDetail {
    display: flex;
}

.itemName_txt {
    width: 50%;
    font-size: 16px;
    font-weight: 400;
}

.itemName {
    width: 50%;
    /* padding-right: 8px; */
}

.itemCard_right {
    padding-right: 16px;
}

.modalFooter_div {
    width: 100%;
    height: 100px;
    background: #0f0f0f00;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalFooter_div_light {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}

.modalFooter_btn {
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

.modalFooter_GTotal {
    width: 45%;
    padding: 0 20px;
}

.borderBottom_div {
    border-bottom: 1px solid #6B6B6B;
    letter-spacing: 1px;
}

.total_div {
    display: flex;
    justify-content: space-between;
}

.total_rs {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.grandTotal {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    text-transform: capitalize;
    /* color: #FFFFFF;  */
}

.message {
    text-align: center;
    margin: 25px 0;
    font-size: 16px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-evenly;
    width: 300px;
    margin: auto;
    padding-bottom: 20px;
    /* gap: 1px; */
}


.dealer_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 214px;
    justify-content: center;
    padding: 1px 5px;
}

.dealer_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
    position: relative;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    position: relative;
}

.heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
    /* max-height: calc(100vh - 400px);
    min-height: calc(100vh - 400px); */
}

.heightFix_div::-webkit-scrollbar {
    display: none;
}

.dispatchHeightFix {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
}

.dispatchHeightFix::-webkit-scrollbar {
    display: none;
}

/* ------------------------------------Dispatch-Dashboard--------------------------------------------------- */
.DispatchMainDiv {
    color: #FFFFFF;
}

.Sort_By {
    padding-right: 30px;
}

.sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.sortBy_select {
    border: 1px solid #ffffff;
    height: 38px;
    width: 170px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.createPackerBtn {
    color: #FFFFFF;
}

.itemDescription_div {
    display: flex;
    /* width: 60%; */
    justify-content: space-between;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    /* border: 1px solid #1FFC33; */
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    /* border: 3px solid #00000045; */
    border-radius: 8px;
}

.view_Item_Image {
    width: 190px;
    height: 260px;
    display: flex;
    margin: 10px auto;
}

.demoImage_div {
    width: 86px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------------------------------------Create-Packers----------------------------------------------- */
.mainHeading {
    margin: 10px 0 16px 0;
    font-weight: 600;
    font-size: 30px;
}

.createPacker_container {
    background-color: #1E2022;
    border-radius: 10px;
    padding: 10px 0;
}

.radiobtn_div {
    display: flex;
    justify-content: left;
    align-items: center;
}

.radioBtn_heading {
    font-size: 18px;
    color: darkgray;
}

.packingVoucherDiv {
    /* width: 100%; */
    /* width: 90%; */
    /* margin: auto; */
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    gap: 50%;
}


.fixTableHead {
    overflow-y: auto;
    height: 38.5vh;
    color: #fff;
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead_packing {
    overflow-y: auto;
    height: 40.5vh;
    color: #fff;
    /* color: #fff; */
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead_packing thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    height: 46vh;
}

.fixTableHead_packing table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    /* height: 46vh; */
}

.fixTableHeadtd {
    padding: 4px 8px;
    border: 1px solid #fff;
}

.h10 {
    height: 10px;
}

.h10 td {
    padding: 4px 8px;
    border: 1px solid rgba(128, 128, 128, 0.366);
    color: #000;
    margin: 20px;
    /* gap: 1px; */
    /* border-bottom: none!important; */
}

.brderTr tr td {
    border: none !important;
}

.fixTableHead thead th {
    background: #000;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #fff;
}

.fixTableHead_packing thead th {
    background: #E4E7EB;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #E4E7EB;
    color: #000;
}

.packing_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 200px;
    justify-content: center;
    padding: 1px 5px;
}

.packing_days_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
}

.packing_days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}


.displayflexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enterPackets {
    width: 14%;
    height: 30px;
    outline: none;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 6px;
}

.checkbox {
    width: 15px;
    height: 15px;
}

.label_div {
    width: 44%;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
    line-height: 30px;
}

.packingImage {
    width: 80px;
    height: 50px;
    border: 0.441703px solid #D6D6D6;
    padding: 3px;
}

.packingDeletediv {
    width: 44px;
    padding: 3px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    border: 0.364683px solid #F93E3E;
}

.packingModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
}

.crossIcon {
    line-height: 42px;
    cursor: pointer;
}

.ordersNotiDiv {
    width: 100%;
    position: absolute;
    top: 0;
    color: #FFFFFF;
}

.ordersNoti {
    background: #2196F3;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 14px;
    margin: auto;
}

.disableBtn {
    color: gray;
    border: 1px solid gray;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.statusCard {
    box-sizing: border-box;
    width: 14%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 4px;
}

.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
    ;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusPacking {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
    height: 32px;
    padding: 0 2px;
}


.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.startEndDate_div {}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    margin-right: 16px;
    outline: none;
    text-align: center;
    border: 2px solid #3A3A3A;
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 1px dashed #FFFFFF; */
    border-radius: 16px;
    padding: 16px 0 8px 0;
    margin: 12px 0;
    /* border: 1px solid fuchsia; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}
.drag_txt1 {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
    color: #9C9797;
}

/* .border_OR_div {
    border: 1px solid #FFFFFF;
    width: 160px;
    margin: 0;
} */

.OR_css {
    position: absolute;
    margin-top: 36px;
    background: #1E2022;
    padding: 0 10px;
}

.browseFiles_btn {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}
.browseFiles_btn1 {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    /* background: red; */
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}

.checkBox_addImage {
    display: flex;
    justify-content: left;
    align-items: center;
}

.main_div {
    position: relative;
}

.dragAndDrop_div {
    height: 244px;
    position: absolute;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    margin-top: 12px;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
}

.source_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 250px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    margin: 0 5px;
    cursor: pointer;
}

.source_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.startEndDate_div {
    margin-left: 40px;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.order_heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 438px);
    min-height: calc(100vh - 438px);
}

.order_heightFix_div::-webkit-scrollbar {
    display: none;
}

.packing_image {
    background: transparent;
}
.packing_image1 {
    background: transparent;
    width: 100%;  /* Adjust width as needed (e.g., 100% to fill container, or fixed size like 500px) */
    height: auto; /* Keeps the aspect ratio, or set a specific height like 300px */
}

.view_packingImage {
    width: 120px;
    height: 46px;
    cursor: pointer;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    border-radius: 4px;
    /* border: 0.729367px solid #1FFC33; */
}

.packingStatus_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.packingImagefull {
    height: 100%;
}

.modal_close_button_div {
    display: flex;
    justify-content: right;
}

.modal_close_btn {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;

}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.manager_status {
    color: red !important;
}

.infoIcon {
    font-style: italic;
    border: 1px solid;
    border-radius: 50%;
    padding: 0 5px 0px 4px;
    font-size: 9px;
    color: red;
    text-transform: lowercase;
}

.editRequestButton {
    min-width: 216px;
}

.reqIssue {
    /* background-color: white; */
    /* border: 1px solid #FFFFFF; */
    width: 80%;
    padding: 7px 5px;
    border-radius: 5px;
    color: black;
    margin: 0px auto 40px;
    display: flex;
    text-align: center;
}
.mainCon{
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 65px);
    padding: 18px 10px 8px;
}


.packerdiv{align-items: center;}

.select_box {
    width: 100%;
    border-radius: 5px;
    border: none;
    text-align: left;
    outline: none;
    /* margin-left: 10px; */
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    font-size: 12px;
    font-weight: 400;
    display: flex;
  justify-content: flex-start;
  font-size: 16px;
}
.totalitemdiv{
    width: 100%;
}
.DropDown2div{
    width: 300px;
    margin-left: 10px;
}
.neworderbottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}


.warehousebtn{
    font-weight: 600;
}

.dropdowngap{
    gap: 10px;
}

/* Modal container */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .modal.show {
    opacity: 1;
  }
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal header */
  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Message and modal text */
  .modal .my-2 {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
  }
  
  /* Actions section (buttons) */
  .modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
  
  /* RedCancel button styles */
  .red-out-button {
    background-color: transparent;
    border: 2px solid #ff0000;
    color: #ff0000;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .red-out-button:hover {
    background-color: #ff0000;
    color: white;
  }
  
  /* GreenDelete button styles */
  .green-button {
    background-color: transparent;
    border: 2px solid #28a745;
    color: #28a745;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .green-button:hover {
    background-color: #28a745;
    color: white;
  }
  
  /* Specific styling for delete button */
  .deleteButton {
    margin-left: 10px;
    background-color: transparent;
    border: 2px solid #28a745;
    color: #28a745;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: #28a745;
    color: white;
  }
  .deletemodalbtndiv{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: center;

  }
  /* .deletemodalmaindiv{
    width: 20vh;
    border-radius: 20px;
  } */
  .deletemodalcontentheading{
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
  }
  .deletemodalcontentmsg{
    font-size: 18px;
    font-weight: 400;
    padding: 15px;
  }
  .mgx_manager_card {
    box-sizing: border-box;
  }

  .modalheading{
    font-size: 10px;
    font-weight: 500;
    text-align: center;
  }
  .mgx {
  height: calc(100vh - 80px);
  width: calc(100% - 20px);
  margin: 10px auto;
  background-color: #f8f9fc;
  border: 1px solid #e4e7eb;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  position: relative;
}

.mgx_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mgx_top p {
  margin: 0;
}

.mgx_top_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mgx_top_left_go_back {
  display: flex;
  cursor: pointer;
}

.mgx_top_left_go_back_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 0.3rem;
}

.mgx_top_left_path_text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

.mgx_top_right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.order_filter {
  /* width: 11%; */
  /* height: 110px; */
  /* min-width: 100px; */
  border-radius: 15px;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  transition: border-color 0.3s ease;
}

.order_filter_dark {
  border: 1px solid #1B1D21;
}

.order_filter:hover {
  border-color: #4164E3;
}

.order_filter_dark:hover {
  border-color: #FFFFFF;
}
.order_filter_dark {
  border: 1px solid #1B1D21;
}

.order_filter:hover {
  border-color: #4164E3;
}

.order_filter_dark:hover {
  border-color: #FFFFFF;
}

.order_filter_active {
  border-color: #4164E3;}
.mgx_middle {
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.mgx_middle>div {
  max-width: 200px;
  min-width: 150px;
}

.mgx_bottom {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  height: calc(100vh - 420px);
}

.mgx_data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
  padding: 5px 10px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0px 0px 20px 20px;
}

.mgx_manager_card {
  box-sizing: border-box;
}

.mgx_mob_filters {
  display: none;
}

.mgx_mob_filters_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin: 1rem auto;
}

.mgx_pagination {
  height: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  padding: 0px 10px 10px 10px;
}

/* superSHWARMA69@WALLICON.in */

@media (max-width: 700px) {
  .mgx {
    height: 100%;
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: auto;
    padding: 0;
  }

  .mgx_middle {
    display: none;
  }

  .mgx_top,
  .mgx_middle {
    border-radius: 0;
    border: none;
  }

  .mgx_top {
    display: none;
  }

  .mgx_bottom {
    border-radius: 0px;
    height: calc(100vh - 300px);
    padding: 1rem 0;
  }

  .mgx_data {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .mgx_mob_filters {
    display: block;
  }

  .empty_div_for_spacing {
    height: 50px;
    width: 100%;
    background-color: #f8f9fc;
  }

  .mgx_pagination {
    display: none;
  }
}

@media (max-width: 430px) {
  .mgx_mob_filters_top {
    width: 99%;
  }

  .mgx_manager_card {
    width: 98%;
  }
}
  .warehouse-form {
  color: white;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-btn {
  color: red;
  cursor: pointer;
}

.file-section {
  margin: 10px 0;
}

.file-input {
  display: none;
}

.form-content {
  display: flex;
  gap: 20px;
}

.form-left,
.form-right {
  flex: 1;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #1e1e1e;
  color: white;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.workday-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.workday-options {
  display: flex;
  gap: 10px;
}

.status-section {
  margin-top: 20px;
}

.work-status-options {
  display: flex;
  gap: 20px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: gray;
  color: white;
}

.submit-btn {
  background-color: blue;
  color: white;
}
/* status.css */

.status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .status-label {
    font-weight: 600;
    color: red;
  }
  
  .status-radio-group {
    display: flex;
    gap: 1rem;
  }
  
  .status-option {
    display: flex;
    align-items: center;
  }
  
  .status-radio {
    margin-right: 0.5rem;
  }
  /* status.css */

.status-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .status-label {
    color: #F93E3E;
    font-weight: 600;
  }
  
  .status-radio-group {
    display: flex;
    gap: 1rem;  /* Adds space between Active and Disable options */
  }
  
  .status-option {
    display: flex;
    align-items: center;
  }
  
  .status-radio {
    margin-right: 0.5rem;
    border: 2px solid transparent; /* Default border */
    border-radius: 4px;
    transition: border-color 0.3s ease; /* Smooth transition */
  }
  
  /* When the input is focused, change border color to blue */
  .status-radio:focus {
    outline: none;
    border-color: blue;
  }
  
  /* Optional: Change the border color to white when not focused */
  .status-radio:focus:not(:focus-visible) {
    border-color: white;
  }
  /*  */
  .nm_form {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    overflow-y: auto;
    width: 100%;
    padding: 0 10px;
}
.nm_form_section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
/* .nm_photo {
    position: relative;
    cursor: pointer;
    width: 100px;
    height: 100px;
    margin-right: auto;
} */


/* 
.nm_camera_icon {
    position: absolute;
    top: 50%;
    right: -15%;
}
.nm_photo {
    width: 150px; 
    height: 150px; 
    position: relative;
    overflow: hidden;
    border-radius: 50%; 
  }
  
  .nm_photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   */
   /* .nm_photo {
    position: relative; 
    width: 150px; 
    height: 150px;
    overflow: hidden;
    border-radius: 50%; 
  } */
  
  /* .nm_photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  } */
  
  .nm_camera_icon {
    position: absolute;
    bottom: -10px; 
    right: -10px;
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 50%; 
    padding: 5px;
    color: white; 
    display: flex;
    align-items: center;
    /* right: -15%; */
z-index: 99;
    /* justify-content: center; */
    cursor: pointer; 
/* margin-left: 10px;  */
 }
  
   .kyc_main_container_div {
   width: 100%;
   background-color: transparent;
   color: #ffffff;
   padding: 4px 16px 16px;
 }

 .d_flex_align_center {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .kyc_main_div {
   padding: 16px;
   width: 100%;
   color: #fff;
   height: calc(100vh - 116px);
 }

 .kyc_sub_main_div {
   padding: 10px;
   border-radius: 5px;
   color: #fff;
   max-width: 1920px;
   margin: auto !important;
   background-color: #1E2022;
 }

 .kyc_main_heading {
   font-size: 30px;
   text-align: center;
   margin: 16px;
 }

 .socialMedia_icons {
   position: absolute;
   /* display: inline; */
   border: 1px solid;
   border-right: none;
   text-decoration: none;
   height: 100%;
   width: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .kyc_scroll_div {
   width: 100%;
   overflow-y: scroll;
   max-height: 780px;
 }

 .kyc_scroll_div::-webkit-scrollbar {
   display: none;
 }

 .text_end {
   text-align: end;
 }

 .mlrb {
   margin: 0px 16px 24px 16px;
 }

 .store_Image_div {
   width: 100%;
   height: 100%;
 }

 .profileAndStore {
   display: flex;
   justify-content: space-between;
   color: #FFFFFF;
   margin-top: 10px;
 }

 .profile_bg {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 80%;
   height: 80%;
   font-size: 14px;
 }

 .profileImage {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed #FFFFFF;
   height: 300px;
   margin-right: 10px;
 }

 .storeImage {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed #FFFFFF;
   width: 100%;
   height: 320px;
 }

 .d_none {
   display: none;
 }

 .store_bg {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 90%;
   height: 80%;
   font-size: 28px;
 }

 .Catalogue {
   margin-top: 20px;
   margin-bottom: 8px;

 }

 .row_mt_20 {
   margin-top: 20px;
   display: flex;
   flex-wrap: wrap;
 }

 .bg_Transparent_w_85_border_p_10 {
   box-sizing: border-box;
   background-color: #1E2022 !important;
   color: #FFFFFF !important;
   width: 84%;
   border: 1px Solid #D3D3D3;
   outline: none;
   padding: 10px;
   text-align: left;
   max-height: 42px;
 }

 .bg_gray_dark {
   background-color: #1E2022;
   color: #fff;
 }

 .position_relative {
   position: relative;
 }

 .bg_Transparent_w_75_border_p_10_ml_30 {
   background-color: #1E2022 !important;
   border: 0.1px Solid #D3D3D3;
   width: 75%;
   padding: 8px 10px;
   margin-left: 40px;
   color: #ffffff;
   outline: none;
 }

 .row_br_5_bg_dark_p_10 {
   display: flex;
   flex-wrap: wrap;
   border-radius: 5px;
   background-color: #18191B;
   color: #fff;
   padding: 10px;
 }

 .tw_center {
   text-align: -webkit-center;
 }

 .text_right {
   text-align: right;
 }

 .productBtn {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid #1FFC33;
   border-radius: 5px;
   padding: 7px;
   color: #1FFC33;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .product_pending {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid yellow;
   border-radius: 5px;
   padding: 7px;
   color: yellow;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .productDisableBtn {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid white;
   border-radius: 5px;
   padding: 7px;
   color: #fff;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .bg_Transparent_Kyc_Mrgn {
   background-color: transparent;
   margin-left: 25px;
   margin-right: 25px;
 }

 .greenClr_fs_12 {
   color: #1FFC33;
   font-size: 12px;
 }

 .grayClr_fs_12 {
   color: #9A9A9A;
   font-size: 12px;
 }

 .fs_20_mt_10 {
   font-size: 17px;
   margin: 12px 0 6px 0;
 }

 .mt_0 {
   margin-top: 0px;
 }

 .margin {
   margin-top: 10px;
   margin-bottom: 8px;
 }

 .pl_5 {
   padding-left: 5px;
 }

 .bg_Transparent_w_98_border_p_maplink {
   box-sizing: border-box;
   background-color: transparent;
   width: 100%;
   border: 1.5px solid white;
   padding: 15px 10px;
   color: #ffffff;
 }

 .fs_14 {
   font-size: 14px;
 }

 .align_items_center {
   align-items: center;
 }

 .bg_Transparent_w_98_border_p_10 {
   background-color: transparent;
   width: -webkit-fill-available;
   padding: 10px;
   border: 1.5px solid white;
   color: #ffffff;
   outline: none;
 }

 .text_end_text_danger_fs_12 {
   text-align: end;
   color: #ED4F4F;
   font-size: 12px;
 }

 .kyc_sub_heading {
   text-align: center;
   margin-top: 20px;
   font-size: 30px;
 }

 .float_end {
   margin-top: 15px;
   text-align: right;
 }

 .row_fs_Kyc20_align_items_center {
   display: flex;
   flex-wrap: wrap;
   font-size: 20px;
   /* align-items: center; */
 }

 .col_10 {
   flex: 0 0 auto;
   width: 75.33333333%;
 }

 .row {
   display: flex;
   flex-wrap: wrap;
   margin-left: 0 !important;
   margin-right: 0 !important;
 }

 .demo {
   /* padding-left: 5px; */
   font-size: 30px;

 }

 .col6 {
   flex: 0 0 auto;
   width: 50%;
 }

 .order {
   flex: 0 0 auto;
   width: 41.66666667%;
   margin-bottom: 8px;
 }

 .col12 {
   flex: 0 0 auto;
   width: 100%;
 }

 .col3 {

   flex: 0 0 auto;
   width: 25%;

 }

 .col9 {
   flex: 0 0 auto;
   width: 75%;
 }

 .kyc_input {
   box-sizing: border-box;
   background-color: transparent;
   width: 100%;
   border: 1.5px solid white;
   padding: 5px;
   color: #ffffff;
   outline: none;
   font-size: 18px;
 }

 .kyc_input_select {
   background-color: transparent;
   width: 82%;
   border: 1.5px solid white;
   padding: 10px;
   color: #ffffff;
   outline: none;
   text-align: left;
 }

 .kyc_toggle_btn {
   width: 60px;
   height: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 24px;
   text-align: center;
   border: 1px solid;
   border-radius: 25px;
   margin-top: 46px;
   padding: 3px;
   font-size: 16px;
 }

 .kyc_toggle_btn_profile_img {
   flex: 0 0 auto;
   display: flex;
   text-align: center;
   padding: 1px;
   width: 60px;
   margin: auto;
   border: 1px solid;
   border-radius: 25px;
   justify-content: center;
   height: 28px;
   margin-top: 10px;
 }

 .mr_40 {
   margin-right: 40px;
 }

 .radio_Btn {
   width: 25px;
   height: 25px;
 }

 .toggle_btn {
   margin-left: 12px;
   margin-top: 37px;
   padding: 1px;
   width: 60px;
   border: 1px solid;
   border-radius: 25px;
   justify-content: center;
   height: 28px;
 }

 .toggle_btn_active {
   background: #1FFC33;
   width: 50%;
   border-radius: 25px;
 }

 .toggle_btn_deactive {
   background: #ED4F4F;
   width: 50%;
   border-radius: 25px;
 }

 .d_none {
   display: none;
 }

 .justify_space_between {
   justify-content: space-between;
 }

 .min_height_200 {
   min-height: 200px;
 }

 .kyc_w_50_mouse {
   cursor: pointer;
   width: 50%;
 }

 .kyc_dFlex_justifyCenter {
   display: flex;
   justify-content: center;
 }

 .kyc_col_2_text_center {
   text-align: center;
   flex: 0 0 auto;
   width: 16.66666667%;
   padding-top: 44px;
 }

 .productName_center {
   text-align: center;
   margin-top: 4px;
 }

 .kyc_upload_aadhaarImg_heading {
   background-color: #282c34;
   height: 200px;
   max-width: 164px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 18px;
   min-height: 200px;
   border: 2.2439px dashed #FFFFFF;
   cursor: pointer;
 }

 .kyc_upload_aadhaar_image {
   border: 2.2439px dashed #FFFFFF;
   width: 130px;
   height: 180px;
   max-height: 180px;
   margin-top: 30px;
   padding: 10px 25px 10px 25px;
 }

 .kyc_row_fs_Kyc20 {
   display: flex;
   flex-wrap: wrap;
   font-size: 20px;
   margin-bottom: 20px;
 }

 .kyc_col_2_item_Center {
   align-items: center;
   text-align: center;
   flex: 0 0 auto;
   width: 16.66666667%;
 }

 .kyc_radioBtn_div {
   display: flex;
   flex-wrap: wrap;
   margin-top: 35px;
 }

 .kyc_radioBtn_col_3 {
   flex: 0 0 auto;
   width: 25%;
   align-items: center;
   display: flex;
 }

 .kyc_mouse_radio_Btn {
   width: 25px;
   height: 25px;
   cursor: pointer;
 }

 .kyc_radioBtn_col_4 {
   flex: 0 0 auto;
   width: 33.33333333%;
   align-items: center;
   display: flex;
 }

 .kyc_col_9 {
   flex: 0 0 auto;
   width: 75%;
   align-items: center;
   display: flex;
 }

 .surface {
   margin-top: 19px;
 }

 .text_end {
   text-align: end;
 }

 .darkBtn {
   border: 1px solid #BABABA;
   background-color: transparent;
   color: #BABABA;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .successBtn {
   width: 155px;
   background-color: transparent;
   border: 1px solid #1FFC33;
   border-radius: 5px;
   padding: 10px 0;
   color: #1FFC33;
   font-size: 13px;
   cursor: pointer;
 }

 .greenBtn {
   border: 1px solid #1FFC33;
   background-color: #1FFC33;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .dangerBtn {
   border: 1px solid #F93E3E;
   background-color: transparent;
   color: #F93E3E;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .uploadLabel {
   border: 1px solid #1FFC33;
   width: 155px;
   padding: 10px 2px;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .enterUrlLabel {
   border: 1px solid #F3F3F3;
   width: 200px;
   padding: 13px;
   border-radius: 5px;
   cursor: pointer;
 }

 .trashBin {
   background: #1E2022;
   box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
   text-align: center;
   padding: 3px;
   border-radius: 6px;
   color: #F93E3E;
   cursor: pointer;
 }

 .caretDown {
   background: #1E2022;
   box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
   text-align: center;
   padding: 3px;
   margin-left: 20px;
   border-radius: 10px;
   color: #D0D0D0;
   cursor: pointer;
   cursor: pointer;
 }

 .col5 {
   flex: 0 0 auto;
   width: 41.66666667%;
 }

 .margin_LR {
   margin: 0 10px;
 }

 .dflex_space_between {
   display: flex;
   justify-content: space-between;
   /* margin-bottom: 10px; */
 }

 .map_div {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   margin-top: 10px;
 }

 .map_seacrh_div {
   width: 100%;
   position: relative;
 }

 .seacrh_inputField {
   box-sizing: border-box;
   width: 100%;
   display: flex;
   height: 40px;
   background-color: transparent;
   border: 1px solid #FFFFFF;
   color: #FFFFFF;
   padding: 0 4px;
   font-size: 15px;
   outline: none;
 }

 .seacrh_inputField1 {
   width: 100%;
   display: flex;
   height: 40px;
   padding: 0 10px;
   outline: none;
   border-radius: 4px;
   font-size: 12px;
 }

 .seacrh_inputField1::placeholder {
   font-size: 12px;
 }

 .search_Icon {
   position: absolute;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   padding-right: 1%;
   cursor: pointer;
 }

 .google_map {
   border: 0;
   width: 100%;
   height: 400px;
   margin-top: 10px;
 }

 .accept_modal {
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1;
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   overflow: auto;
   padding-top: 360px;
 }

 .accept_modal_content {
   width: 90%;
   max-width: 500px;
   background: #1E2022;
   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
   margin: auto;
   padding-top: 2px;
   color: #FFFFFF;
 }

 .close_btn {
   color: #aaaaaa;
   float: right;
   font-size: 25px;
   font-weight: bold;
   line-height: 1.5rem;
   padding-right: 6px;
 }

 .message {
   text-align: center;
   margin: 30px 0;
   font-size: 24px;
 }

 .massageModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: auto;
   padding-bottom: 30px;
 }

 .goBack {
   cursor: pointer;
   border-bottom: 1px solid #FFFFFF;
   /* text-decoration-line:  underline; */
 }

 .searchPlace_div {
   flex: 0 0 auto;
   width: 58.3%;
 }

 .map_heading_div {
   display: flex;
   /* justify-content: space-around; */
   flex-direction: column;
   width: 100%;
 }

 .map_txt {
   width: 20%;
   padding-bottom: 6px;
 }

 .OfficeUse_col {
   flex: 0 0 auto;
   width: 33.33%;
 }

 .leadModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: auto;
   padding-bottom: 30px;
   margin-top: 70px;
 }

 .rejectionList_div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #FFFFFF;
   padding: 6px 0 4px 0;
 }

 .rejectionList {
   min-width: 104px;
   width: 104px;
   max-width: 104px;
 }

 .rejectionList_text {
   width: 50%;
   /* text-align: center; */
 }

 .rejectionList_checkbox {
   /* width: 40px; */
   margin-right: 4px;
 }

 .rejectCheckbox {
   cursor: pointer;
   width: 20px;
   height: 20px;
 }

 .rejectListModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: 16px auto;
 }

 .input_field_alert {
   color: red;
 }

 .profileImage_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed red;
   height: 300px;
   margin-right: 10px;
 }

 .profile_bg_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 80%;
   height: 80%;
   font-size: 14px;
   color: red;
 }

 .storeImage_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed red;
   width: 100%;
   height: 300px;
 }

 .store_bg_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 90%;
   height: 80%;
   font-size: 28px;
   color: red;
 }

 .rowOffice {
   display: flex;
   align-items: center;
   /* justify-content: space-between; */
   flex-wrap: wrap;
 }

 .rowOffice .col3 {
   width: 33.333333%;
   margin-bottom: 20px;
 }

 .dfac {
   display: flex;
   align-items: center;
 }

 .dfac label {
   display: flex;
   align-items: center;
   margin-right: 20px;
 }

 .profile_Image_div {
   width: 100%;
   height: 100%;
 }

 .plus_product {
   font-weight: bolder;
   font-size: 14px;
   width: 20px;
 }

 @media (max-width:2560px) {
   .profileImage {
     width: 280px;
     height: 320px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }


 }

 @media (max-width: 1500px) {
   .profileImage {
     width: 180px;
     height: 220px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }

   .profileImage_alert {
     width: 180px;
     height: 220px;
   }

   .storeImage_alert {
     width: 600px;
     height: 250px;
   }
 }

 @media (max-width: 1200px) {
   .profileImage {
     width: 180px;
     height: 200px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }

   .profileImage_alert {
     width: 180px;
     height: 200px;
   }

   .storeImage_alert {
     width: 600px;
     height: 200px;
   }
 }

 @media only screen and (max-width: 1595px) {
   .Kyc_Mrgn {
     margin-left: 15px;
     margin-right: 15px;
   }

   .col_10 {
     flex: 0 0 auto;
     width: 75.33333333%;
   }


 }

 @media only screen and (max-width: 1350px) {
   .row_fs_Kyc20_align_items_center {
     font-size: 18px;
   }

   .profileImgHW {
     width: 200px;
     height: 200px;
   }

   .storeImgHW {
     width: 600px;
     height: 300px;
   }

   .qr_HW {
     width: 145px;
     height: 145px;
   }

   .storeImgHW {
     width: 635px;
     height: 250px;
   }

   .Kyc_Mrgn {
     margin-left: 15px;
     margin-right: 15px;
   }

   .productBtn {
     font-size: 12px;
     margin-bottom: 10px;
   }

   .productDisableBtn {
     font-size: 12px;
     margin-bottom: 10px;
   }
 }

 @media only screen and (max-width: 1220px) {
   .Kyc_Mrgn {
     margin-left: 10px;
     margin-right: 10px;
   }

   /* .productBtn {
    width: 90px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  } */

   /* .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 90px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  } */
 }

 @media only screen and (max-width: 1140px) {
   .storeImgHW {
     width: 535px;
     height: 250px;
   }

   .Kyc_Mrgn {
     margin-left: 7px;
     margin-right: 7px;
   }

   .col_10 {
     flex: 0 0 auto;
     width: 70.33333333%;
   }

   .qr_HW {
     width: 135px;
     height: 135px;
   }

   .productBtn {
     /* width: 85px; */
     font-size: 12px;
     cursor: pointer;
   }

   .productDisableBtn {
     font-size: 12px;
   }
 }

 /* -------------------------------------------------------------New-KYC------------------------------------------------------------------------ */

 .revertBack_btn {
   font-size: 16px;
   margin: 0 10px;
 }

 .kyc_btn_div {
   padding-top: 10px;
 }

 .billingDetails_heading {
   font-weight: 400;
   font-size: 24px;
   margin: 16px 0;
 }

 .profileImage_div {
   width: 280px;
 }

 .billingDetails_div {
   display: flex;
 }

 .accountSetting {
   background-color: rgba(27, 28, 29, 1);
   padding: 0 32px;
 }


 .mapHeading_txt {
   width: 100%;
   padding-bottom: 6px;
 }

 .accountSetting_div {
   display: flex;
   width: 100%;
   padding-bottom: 20px;
 }

 .accountSetting_left {
   width: 40%;
   display: flex;
   flex-direction: column;
 }

 .accountSetting_right {
   width: 60%;
   display: flex;
   flex-direction: column;
 }

 .Catalogue_ml {
   margin-bottom: 26px;
   margin-left: 20%;
 }

 .kyc_div_height {
   width: 100%;
   overflow-y: scroll;
   max-height: calc(100vh - 150px);
   min-height: calc(100vh - 150px);
 }

 .kyc_div_height::-webkit-scrollbar {
   display: none;
 }



















 /* new css for kyc */
 .main {
   width: 100%;
   height: calc(100vh - 65px);
   padding: 10px;
 }

 .continer {
   width: 100%;
   padding: 10px;
   height: calc(100vh - 75px);
   overflow-y: scroll;
 }

 .go_back {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .Personal_Info {
   width: 100%;
   margin-top: 10px;
   display: flex;
   gap: 10px;
 }

 .Personal {
   width: 30%;
   padding: 20px;
   border-radius: 10px;
 }

 .Company {
   width: 70%;
   padding: 20px;
   border-radius: 10px;
   display: flex;
   gap: 20px;
 }

 .Company_FristChile {
   width: 100%;
 }

 .Company_SeacandChile {
   width: 100%;
 }

 .profileContainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
 }

 .profileContainer p {
   font-weight: 600;
   line-height: 1;
 }

 .imageWrapper {
   position: relative;
   width: 100px;
   height: 100px;
   border-radius: 50%;
   overflow: hidden;
 }

 .profileImage {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 50%;
 }

 .fileInput {
   position: absolute;
   top: 0;
   width: 100px;
   height: 100px;
   cursor: pointer;
   z-index: 99;
 }

 .selectPik {
   width: 100px;
   border: 1px solid;
   z-index: 999;
 }

 /* .nm_photo {

   cursor: pointer;
   width: 100px;
   height: 100px;
   margin-right: auto;
   border-radius: 50%;
 } */

 /* .nm_photo img {
   height: 100%;
   width: 100%;
   border-radius: 50%;
   object-fit: cover;
   position: relative;
 } */

 /* .nm_camera_icon {
   position: absolute;
   top: 50%;
   right: -15%;
 } */

 .nm_info {
   width: 100%;
   margin-top: 10px;
 }

 .Company_SeacandChile p {
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
 }

 .Drag_drop {
   width: 100%;
   border: 1px dashed #D9D9D9;
   height: 140px;
   border-radius: 10px;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   gap: 10px;
   position: relative;
 }

 .aadherCard {
   position: absolute;
   top: 10px;
   left: 10px;
   font-size: 12px;
   font-weight: 600;

 }

 .aadherCard1 {
   position: absolute;
   top: 5px;
   right: 10px;
   font-size: 14px;
   font-weight: 600;
   cursor: pointer;
   color: red;
 }

 .textaline {
   text-align: center;
   color: #9C9797;
 }

 .textaline p {
   line-height: 0.5;
   font-size: 14px;
   font-weight: 600;
 }

 .textaline1 {
   text-align: center;
   color: #D9D9D9;
 }

 .textaline1 p {
   line-height: 0.5;
   font-size: 12px;
   font-weight: 400;
 }

 .browseSpan {
   color: #749AFF;
   border-bottom: 1px solid;
   cursor: pointer;
 }

 .imagePreview {
   display: flex;
   justify-content: center;
   align-items: center;
   /* margin-top: 10px; */
   width: 100%;
 }

 .uploadedImage {
   max-width: 100%;
   max-height: 140px;
   object-fit: cover;
   /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
 }

 .imagePreview1 {
   display: flex;
   justify-content: center;
   align-items: center;
   /* margin-top: 10px; */
   width: 100%;
 }

 .uploadedImage1 {
   max-width: 100%;
   object-fit: cover;
   /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
 }

 .Location {
   width: 100%;
   display: flex;
   gap: 10px;
   margin-top: 10px;
 }

 .LocationAdress {
   width: 25%;
   border-radius: 8px;
   padding: 20px;
 }

 .LocationMap {
   width: 50%;
   border-radius: 8px;
   padding: 20px;
 }

 .Account_Setting {
   width: 25%;
   border-radius: 8px;
   padding: 20px;
 }

 .LocationAdress p {
   font-size: 14px;
   font-weight: 600;
 }

 .Location_map {
   width: 100%;
   /* height: calc(100% - 75px); */
   /* border: 1px solid; */
   /* margin-top: 10px; */
   border-radius: 8px;
 }

 .Account_Setting p {
   font-size: 14px;
   font-weight: 600;
   /* line-height: 1; */
 }

 .redioBtnConintner {
   display: flex;
   flex-direction: column;
   margin-top: 20px;
 }

 .redioBtnConintner2 {
   display: flex;
   gap: 20px;
 }
 .redioBtnConintner3 {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

 .redioText {
   font-size: 13px;
   font-weight: 400;
 }

 .redio_Btn_Conintner_Status {
   margin-top: 20px;
 }

 .redio_Btn_Conintner_Status p {
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
 }

 .status_redio_btn {
   width: 100%;
   /* border: 1px solid; */
   display: flex;
   gap: 30px;
   /* margin-left: -40px; */
 }

 .redioCon {
   width: 130px;
   /* border: 1px solid; */
 }

 .sosal_Media {
   display: flex;
   width: 100%;
   margin-top: 10px;
   /* align-items: center; */
   gap: 10px;
 }

 .sosale_media_link {
   width: 25%;
   padding: 20px;
   border-radius: 8px;
 }

 .sosale_media_About {
   width: 75%;
   border-radius: 8px;
   padding: 20px;

 }

 .sosale_media_About p {
   font-size: 14px;
   font-weight: 400;
 }

 .kycchack {
   padding: 10px;
   cursor: pointer;
   font-size: 14px;
   border-radius: 8px;
 }

 .KycChack_coontiner {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
 }

 .KycChack_coontiner1 {
   display: flex;
   flex-direction: column;
   width: 100%;
 }

 .Description_textarea {
   width: 100%;
   outline: none;
   padding: 10px;
   font-size: 13px;
   font-weight: 400;
   border-radius: 8px;
 }

 .textarea_text {
   display: flex;
   justify-content: flex-end;
   margin-top: -5px;
   font-size: 12px;
   color: #F93E3E;
 }

 .upload_file {
   width: 100%;
   margin-top: 10px;
   border-radius: 8px;
   padding: 20px;
 }

 .upload_file_continer {
   border: 1px dashed #ccc;
   border-radius: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 10px;
   padding: 20px;
   font-size: 14px;
 }


 .dropdownContainer {
   position: relative;
   width: 100%;
 }

 .searchInput input {
   width: 100%;
   border-radius: 4px;
   outline: none;
   padding: 8px;
   font-size: 14px;
   font-weight: 400;
 }

 .dropdownList {
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;
   max-height: 200px;
   overflow-y: auto;
   border-radius: 10px;
   z-index: 100;
 }

 .dropdownItem {
   cursor: pointer;
   transition: background-color 0.5s;
   padding: 5px 10px;
   font-size: 14px;
   font-weight: 400;
 }

 /* .dropdownItem:hover {
  background-color: var(--hover-bg, #f0f0f0);
  color: var(--hover-color, #000);
  cursor: pointer;
} */

 .selectedItem {
   background-color: #d0eaff;
 }

 .noItems {
   padding: 8px;
   text-align: center;
   color: #999;
 }

 .filter_cantiner {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 10px;
 }

 .select_dealer {
   display: flex;
   gap: 10px;
 }

 .select_dealerCont {
   display: flex;
   align-items: center;
   gap: 10px;
 }

 .select_dealerCont :first-child {
   font-size: 14px;
   font-weight: 400;
 }

 .Transfer_loction {
   display: flex;
   gap: 10px;
   align-items: center;
 }

 .main2 {
   width: 100%;
   height: calc(100vh - 65px);
   padding: 10px;
   position: relative;
 }

 .pageContiner {
   position: absolute;
   bottom: 0;
   width: calc(100% - 26px);
   display: flex;
   align-items: center;
   padding: 5px;
   margin: 3px;
   border-radius: 5px 5px 0px 0px;
   font-size: 12px;
   font-weight: 400;
 }




 .tableContiner {
   width: 100%;
   height: calc(100% - 150px);
   overflow: hidden;
   margin-top: 10px;
 }

 .scrolllist {
   height: calc(100% - 45px);
   overflow-y: scroll;
 }


 .tableContiner2 {
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .tableContinerTable {
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .tableContiner1 {
   width: 100%;
   height: 71.5%;
   /* overflow-y: scroll; */
   margin-top: 5px;
 }

 .responsiveTable {
   list-style-type: none;
   padding: 0;

   .tableHeader {
     font-size: 14px;
     text-transform: uppercase;
     letter-spacing: 0.03em;
     display: flex;
     border-radius: 3px;
     padding: 13px 9px;
     margin-bottom: 5px;
   }

   .tablerow {
     background-color: #ffffff;
     box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
     display: flex;
     border-radius: 3px;
     padding: 10px;
     margin-bottom: 5px;
     align-items: center;
     /* cursor: pointer; */
     position: relative;
     /* overflow-y: scroll */

   }

   .col {
     flex: 1;
     padding: 0;
     margin: 0;
     font-size: 12px;
     font-weight: 400;
     line-height: 1;
     align-items: center;
     display: flex;
     /* border: 1px solid; */
   }

   .col0 {
     flex: 1/4;
     padding: 0;
     margin: 0;
     font-size: 12px;
     font-weight: 400;
     line-height: 1;
     align-items: center;
     display: flex;
     /* border: 1px solid; */
   }

   .coljustyfy {
     flex: 1;
     /* align-items: center;
justify-content: center;
display: flex; */
   }

   .large {
     flex: 2;
     /* pehle box ko bada karne ke liye */
   }

   .uperCase {
     text-transform: uppercase;
   }

   @media all and (max-width: 700px) {
     .tableHeader {
       display: none;
     }

     .TableHeadChackBox {
       width: 20px;
       height: 20px;
       border-radius: 5px;
     }

     .tablerow {
       display: block;
       display: flex;
       flex-direction: column;
       width: 100%;
       justify-content: center;
       border-radius: 5px;
     }

     .col {
       /* display: flex; */
       /* justify-content: space-between; */
       /* padding: 10px 0; */
       display: flex;
       justify-content: space-between;
       padding: 10px 0;

       &:before {
         color: #6C7A89;
         padding-right: 10px;
         content: attr(data-label);
         flex-basis: 50%;
         text-align: left;
       }
     }
   }

 }



 .cl_checkbox {
   margin-left: 5px;
 }

 .cl_checkbox {
   position: relative;
   display: inline-block;
 }

 /* Input */
 .cl_checkbox>input {
   appearance: none;
   -moz-appearance: none;
   -webkit-appearance: none;
   z-index: -1;
   position: absolute;
   left: -10px;
   top: -8px;
   display: block;
   margin: 0;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   /* background-color: rgba(0, 0, 0, 0.6); */
   box-shadow: none;
   outline: none;
   opacity: 0;
   transform: scale(1);
   pointer-events: none;
   transition: opacity 0.5s, transform 0.2s;
 }

 /* Span */
 .cl_checkbox>span {
   display: inline-block;
   width: 100%;
   cursor: pointer;
 }

 /* Box */
 .cl_checkbox>span::before {
   content: "";
   display: inline-block;
   box-sizing: border-box;
   margin: 2px 11px 3px 0px;
   border: solid 2px;
   /* Safari */
   border-color: #0065FF;
   border-radius: 5px;
   width: 18px;
   height: 18px;
   vertical-align: top;
   transition: border-color 0.2s, background-color 0.2s;
 }

 /* Checkmark */
 .cl_checkbox>span::after {
   content: "";
   display: block;
   position: absolute;
   top: 3px;
   left: 1px;
   width: 10px;
   height: 5px;
   border: solid 2px transparent;
   border-right: none;
   border-top: none;
   transform: translate(3px, 4px) rotate(-45deg);
 }

 /* Checked, Indeterminate */
 .cl_checkbox>input:checked,
 .cl_checkbox>input:indeterminate {
   background-color: #0065FF;
 }

 .cl_checkbox>input:checked+span::before,
 .cl_checkbox>input:indeterminate+span::before {
   border-color: #0065FF;
   background-color: #0065FF;
 }

 .cl_checkbox>input:checked+span::after,
 .cl_checkbox>input:indeterminate+span::after {
   border-color: #fff;
 }

 .cl_checkbox>input:indeterminate+span::after {
   border-left: none;
   transform: translate(4px, 3px);
 }

 /* Hover, Focus */
 .cl_checkbox:hover>input {
   opacity: 0.04;
 }

 .cl_checkbox>input:focus {
   opacity: 0.12;
 }

 .cl_checkbox:hover>input:focus {
   opacity: 0.16;
 }

 /* Active */
 .cl_checkbox>input:active {
   opacity: 1;
   transform: scale(0);
   transition: transform 0s, opacity 0s;
 }

 .cl_checkbox>input:active+span::before {
   border-color: #0065FF;
 }

 .cl_checkbox>input:checked:active+span::before {
   border-color: transparent;
   background-color: rgba(0, 0, 0, 0.6);
 }

 /* Disabled */
 .cl_checkbox>input:disabled {
   opacity: 0;
 }

 .cl_checkbox>input:disabled+span {
   color: rgba(0, 0, 0, 0.38);
   cursor: initial;
 }

 .cl_checkbox>input:disabled+span::before {
   border-color: currentColor;
 }

 .cl_checkbox>input:checked:disabled+span::before,
 .cl_checkbox>input:indeterminate:disabled+span::before {
   border-color: transparent;
   background-color: currentColor;
 }

 .DrawerWrapperContinetr {
   width: 100%;
   padding: 20px;
   z-index: 9999;
   background: rgba(44, 46, 51, 0.85);
   /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   border-radius: 5px 5px 0px 0px ;
   box-sizing: border-box;
   display: flex;
   justify-content: flex-end;
   gap: 10px;
   align-items: center;
 }

 .DrawerWrapperContinetr_light {
   width: 100%;
   padding: 20px;
   z-index: 9999;
   background: rgba(255, 255, 255, 0.7);
   /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
   backdrop-filter: blur(7px);
   -webkit-backdrop-filter: blur(7px);
   border-radius: 5px 5px 0px 0px ;
   display: flex;
   box-sizing: border-box;
   justify-content: flex-end;
   gap: 10px;
   align-items: center;
 }


 .mainCon1{
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
 }
 
.seandCon{
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  height: 100%;
  /* align-items: center; */
  /* justify-content: center; */
}
.redioBtnConintner_test{
  display: flex;
}

.redioBtnConintner_text{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.right_side{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  padding: 10px;
  border-radius: 10px;
}
.left_side{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.redText{
  float: right;
  font-size: 12px;
  color: red;
}



