 /* .pagination {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 2%;
   margin-right: 20px;
 }

 .pageButton {
   border: 0.5px solid #ccc;
   outline: none;
   background-color: white;
   cursor: pointer;
   min-width: 32px;
   width: 32px;
   height: 32px;
   border-radius: 8px;
   font-size: 12px;
   font-weight: 600;
 }

 .pageButton_dark {
   border: none;
   outline: none;
   background-color: #000;
   cursor: pointer;
   min-width: 32px;
   width: 32px;
   height: 32px;
   border-radius: 8px;
   font-size: 12px;
   font-weight: 600;
   color: white;
 }

 .pageButton:disabled {
   cursor: not-allowed;
   color: #232529;
 }

 .pageButton_dark:disabled{
  cursor: not-allowed;
  color: #FFFFFF;
 }

 .activeButton {
   background-color: #4164E3;
   color: #fff;
 }

 .ellipsis {
   border: 1px solid #ccc;
   background-color: white;
   cursor: pointer;
   min-width: 32px;
   width: 32px;
   height: 32px;
   border-radius: 8px;
   color: black;
   display: flex;
   justify-content: center;
   align-items: flex-end;
 }

 .ellipsis_dark {
   border: none;
   background-color: #232529;
   cursor: pointer;
   min-width: 32px;
   width: 32px;
   height: 32px;
   border-radius: 8px;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: flex-end;
 }

 .Go_to_page_continer {
   width: 100%;
   height: 4vh;
   gap: 10px;
   background-color: transparent;
   display: flex;
   align-items: center;
   font-size: 12px;
 }

 .pageText {
   color: #232529;
   text-wrap: nowrap;
 }

 .pageText_dark {
   color: #fff;
   text-wrap: nowrap;
 }

 .gotoInput {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
 }

 .gotoInputinput {
   width: 32px;
   padding: 4px;
   height: 32px;
   background-color: #fff;
   border: 0.5px solid #DDDDDD;
   border-radius: 5px;
   outline: none;
 }

 .gotoInputinput_dark {
   width: 32px;
   padding: 4px;
   height: 32px;
   background-color: #232529;
   border: 0.5px solid #808692;
   border-radius: 5px;
   outline: none;
   color: #fff;
 }

 .Go_to_page_continer button {
   border: none;
   border-radius: 8px;
   background-color: #4164E3;
   color: #fff;
   padding: 5px;
 } */




 .pagination {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 2%;
 }

 .pageButton {
   border: 0.5px solid #ccc;
   outline: none;
   background-color: white;
   cursor: pointer;
   min-width: 30px;
   width: 50px;
   height: 40px;
   border-radius: 10px;
 }

 .pageButton_dark {
   border: none;
   outline: none;
   background-color: #000;
   cursor: pointer;
   min-width: 30px;
   width: 50px;
   height: 40px;
   border-radius: 8px;
   color: white;
 }

 .pageButton:disabled {
   cursor: not-allowed;
   color: #232529;
 }

 .activeButton {
   background-color: #4164E3;
   color: #fff;
 }

 .ellipsis {
   border: 1px solid #ccc;
   background-color: white;
   cursor: pointer;
   min-width: 30px;
   width: 50px;
   min-height: 40px;
   border-radius: 10px;
   color: black;
   display: flex;
   justify-content: center;
   align-items: flex-end;
 }

 .ellipsis_dark {
   border: none;
   background-color: #232529;
   cursor: pointer;
   min-width: 30px;
   width: 50px;
   height: 40px;
   border-radius: 8px;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: flex-end;
 }

 .Go_to_page_continer {
   width: 100%;
   height: 4vh;
   gap: 10px;
   background-color: transparent;
   display: flex;
   align-items: center;
 }

 .pageText {
   color: #232529;
   margin-left: 10px;
   text-wrap: nowrap;
 }

 .pageText_dark {
   color: #fff;
   margin-left: 10px;
   text-wrap: nowrap;
 }

 .gotoInput {
   width: 50px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
 }

 .gotoInputinput {
   width: 45px;
   padding: 4px;
   height: 35px;
   background-color: #fff;
   border: 0.5px solid #DDDDDD;
   border-radius: 5px;
   outline: none;
   margin-left: 10px;
   text-align: center;
 }

 .gotoInputinput_dark {
   width: 45px;
   padding: 4px;
   height: 35px;
   background-color: #232529;
   border: 0.5px solid #808692;
   border-radius: 5px;
   outline: none;
   color: #fff;
   text-align: center;
 }

 .Go_to_page_continer button {
   width: 40px;
   height: 40px;
   border: none;
   border-radius: 8px;
   background-color: #4164E3;
   color: #fff;
 }