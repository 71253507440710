.inventory {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inventory_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.inventory_top_left,
.inventory_top_right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.responsiveTable {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.tableHeader {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.inventory_data_container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 260px);
}

.inventory_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

/* tax rate modal */

.add_tax_rate {
    background-color: #fff;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 10px;
    width: 350px;
    color: #000
}

.p {
    background-color: #2C2E33;
    color: #fff;
}

.add_tax_rate_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add_tax_rate_top p {
    color: #1B1D21;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.add_tax_rate_form,
.add_tax_rate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}

.add_tax_rate_form>div {
    display: flex;
    gap: 1rem;
}

.add_tax_rate_form>p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.add_tax_rate_form_element {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.1rem;
}

.add_tax_rate_form_element input {
    width: 100%;
    border: none;
    outline: none;
    border: 1px solid #e4e7eb;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    background-color: #fff;
    color: #1b1d21;
    margin-top: -1rem;
}

.add_tax_rate_form_element label {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
}

/* collection info */
.collection_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection_header_left,
.collection_header_right {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inventory_search_bar {
    width: 420px;
}


.collection_arrow_icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* .inventory_list, */
.collection_list {
    letter-spacing: 0.03em;
    border-radius: 5px;
    padding: 10px 0 10px 10px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    text-align: left;
    position: relative;
    user-select: text;
    background-color: #fff;
    border: 1px solid #FFFFFF;
    color: #646B88;
    outline: none;
}

.collection_list_FW {
    font-weight: 500;
}

.collection_list_dark {
    background-color: #1B1D21;
    border: 1px solid #1B1D21;
    color: #FAFAFA;
}

.collection_list_FOCUSED {
    border: 1px solid #4164E3;
    outline: none;
}

.col {
    flex: 1;
}

.collection_list_inp {
    border: 1px solid #E4E7EB;
    padding: 10px 12px;
    border-radius: 5px;
    min-width: 10px;
    max-width: 70px;
    max-height: 30px;
    margin-right: 0.5rem;
    background-color: transparent;
    outline: none;
    flex: 1;
    font-size: 12px;
}

.collection_list_inp_dark {
    border-color: #646B88;
    color: #fff;
}

.collection_data {
    display: flex;
    flex-direction: column;
    outline: none;
}

.collection_hidden_Data {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 10px;
    border-radius: 0 0 5px 5px;
    border-top: none;
    transition: 0.3s ease;
}

.collection_hidden_Data_dark {
    background-color: #1B1D21;
    color: #fafafa;
}

.drop_down {
    display: flex;
    align-items: center;
}

.drop_down p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}

.drop_down>div {
    width: 180px;
    margin-left: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
}

.drop_down_element_2 {
    display: flex;
    gap: 3px;
    font-size: 12px;
    flex: 1;
}

.drop_down_element {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    flex: 1;
}

.drop_down_element_2 p,
.drop_down_element p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.collection_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 360px);
}

.collection_item {
    position: relative;
    flex: 1;
}

.collection_item p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.3s ease;
}

.collection_item span {
    position: absolute;
    background-color: #4164E3;
    color: #fff;
    padding: 10px;
    transition: 0.5s ease-in;
    border-radius: 5px;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease 0.2s;
    text-wrap: none;
}

.collection_item:hover span {
    opacity: 1;
    transition: opacity 0.3s ease 0.2s;
}

.item_card_mob_det_con {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100vh - 300px);
    overflow: hidden;
    overflow-y: auto;
    display: none;
}

.item_card_mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 8px;
    padding-top: 1.5rem;
    position: relative;
}

.item_card_mob_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.item_card_mob>div>p {
    margin: 0;
    text-align: center;
}

.item_card_mob>div>p:nth-of-type(1) {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
}

.item_card_mob>div>p:nth-of-type(2) {
    font-weight: 300;
    font-size: 14px;
}

.item_card_mob_det {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
}

.item_card_mob_det>div>p:nth-of-type(1) {
    font-size: 14px;
    margin: 0;
}

.item_card_mob_det>div>p:nth-of-type(2) {
    font-size: 10px;
    font-weight: 600;
    margin: 0;
    background-color: #D9DCE5;
    padding: 0.4rem 0.1rem;
    height: 1.8rem;
    width: 3rem;
    margin: 0 auto;
}

.item_card_mob_os {
    position: absolute;
    right: 1%;
    top: 1%;
}

.unit_dd {
    background-color: transparent;
    max-width: 80px;
    border-radius: 5px;
    color: #000;
    height: 30px;
    text-align: left;
    border: 1px solid #E4E7EB;
}

.unit_dd_dark {
    border: 1px solid #646B88;
    color: #FFFFFF;
}

.unit_dd_option {
    background-color: #FFFFFF;
    text-align: left;
    border: none;
}

.unit_dd_option_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.update_all_multi {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    gap: 5px;
}

.warn {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.warn>span {
    font-size: 10px;
    color: #F93E3E;
}

@media (max-width:700px) {
    .inventory {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        overflow: auto;
        padding: 1rem 0.5rem;
    }

    .inventory_top_left {
        display: none;
    }

    .tax_rate_btn {
        border-radius: 5px;
        position: fixed;
        bottom: 2%;
        right: 5%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .tableHeader {
        padding: 10px 0;
    }

    .hide {
        display: none;
    }

    .drop_down {
        position: absolute;
        top: 7.5%;
        right: 2%;
    }

    .collection_header_left {
        width: 100%;
        margin-top: 3rem;
    }

    .inventory_search_bar {
        width: 100%;
    }

    .hidden_table {
        display: none;
    }

    .item_card_mob_det_con {
        display: flex;
    }
}