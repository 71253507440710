 .kyc_main_container_div {
   width: 100%;
   background-color: transparent;
   color: #ffffff;
   padding: 4px 16px 16px;
 }

 .d_flex_align_center {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .kyc_main_div {
   padding: 16px;
   width: 100%;
   color: #fff;
   height: calc(100vh - 116px);
 }

 .kyc_sub_main_div {
   padding: 10px;
   border-radius: 5px;
   color: #fff;
   max-width: 1920px;
   margin: auto !important;
   background-color: #1E2022;
 }

 .kyc_main_heading {
   font-size: 30px;
   text-align: center;
   margin: 16px;
 }

 .socialMedia_icons {
   position: absolute;
   /* display: inline; */
   border: 1px solid;
   border-right: none;
   text-decoration: none;
   height: 100%;
   width: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .kyc_scroll_div {
   width: 100%;
   overflow-y: scroll;
   max-height: 780px;
 }

 .kyc_scroll_div::-webkit-scrollbar {
   display: none;
 }

 .text_end {
   text-align: end;
 }

 .mlrb {
   margin: 0px 16px 24px 16px;
 }

 .store_Image_div {
   width: 100%;
   height: 100%;
 }

 .profileAndStore {
   display: flex;
   justify-content: space-between;
   color: #FFFFFF;
   margin-top: 10px;
 }

 .profile_bg {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 80%;
   height: 80%;
   font-size: 14px;
 }

 .profileImage {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed #FFFFFF;
   height: 300px;
   margin-right: 10px;
 }

 .storeImage {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed #FFFFFF;
   width: 100%;
   height: 320px;
 }

 .d_none {
   display: none;
 }

 .store_bg {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 90%;
   height: 80%;
   font-size: 28px;
 }

 .Catalogue {
   margin-top: 20px;
   margin-bottom: 8px;

 }

 .row_mt_20 {
   margin-top: 20px;
   display: flex;
   flex-wrap: wrap;
 }

 .bg_Transparent_w_85_border_p_10 {
   box-sizing: border-box;
   background-color: #1E2022 !important;
   color: #FFFFFF !important;
   width: 84%;
   border: 1px Solid #D3D3D3;
   outline: none;
   padding: 10px;
   text-align: left;
   max-height: 42px;
 }

 .bg_gray_dark {
   background-color: #1E2022;
   color: #fff;
 }

 .position_relative {
   position: relative;
 }

 .bg_Transparent_w_75_border_p_10_ml_30 {
   background-color: #1E2022 !important;
   border: 0.1px Solid #D3D3D3;
   width: 75%;
   padding: 8px 10px;
   margin-left: 40px;
   color: #ffffff;
   outline: none;
 }

 .row_br_5_bg_dark_p_10 {
   display: flex;
   flex-wrap: wrap;
   border-radius: 5px;
   background-color: #18191B;
   color: #fff;
   padding: 10px;
 }

 .tw_center {
   text-align: -webkit-center;
 }

 .text_right {
   text-align: right;
 }

 .productBtn {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid #1FFC33;
   border-radius: 5px;
   padding: 7px;
   color: #1FFC33;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .product_pending {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid yellow;
   border-radius: 5px;
   padding: 7px;
   color: yellow;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .productDisableBtn {
   /* min-width: 110px; */
   background-color: transparent;
   border: 1.5px solid white;
   border-radius: 5px;
   padding: 7px;
   color: #fff;
   font-size: 14px;
   cursor: pointer;
   margin: 2px 4px;
 }

 .bg_Transparent_Kyc_Mrgn {
   background-color: transparent;
   margin-left: 25px;
   margin-right: 25px;
 }

 .greenClr_fs_12 {
   color: #1FFC33;
   font-size: 12px;
 }

 .grayClr_fs_12 {
   color: #9A9A9A;
   font-size: 12px;
 }

 .fs_20_mt_10 {
   font-size: 17px;
   margin: 12px 0 6px 0;
 }

 .mt_0 {
   margin-top: 0px;
 }

 .margin {
   margin-top: 10px;
   margin-bottom: 8px;
 }

 .pl_5 {
   padding-left: 5px;
 }

 .bg_Transparent_w_98_border_p_maplink {
   box-sizing: border-box;
   background-color: transparent;
   width: 100%;
   border: 1.5px solid white;
   padding: 15px 10px;
   color: #ffffff;
 }

 .fs_14 {
   font-size: 14px;
 }

 .align_items_center {
   align-items: center;
 }

 .bg_Transparent_w_98_border_p_10 {
   background-color: transparent;
   width: -webkit-fill-available;
   padding: 10px;
   border: 1.5px solid white;
   color: #ffffff;
   outline: none;
 }

 .text_end_text_danger_fs_12 {
   text-align: end;
   color: #ED4F4F;
   font-size: 12px;
 }

 .kyc_sub_heading {
   text-align: center;
   margin-top: 20px;
   font-size: 30px;
 }

 .float_end {
   margin-top: 15px;
   text-align: right;
 }

 .row_fs_Kyc20_align_items_center {
   display: flex;
   flex-wrap: wrap;
   font-size: 20px;
   /* align-items: center; */
 }

 .col_10 {
   flex: 0 0 auto;
   width: 75.33333333%;
 }

 .row {
   display: flex;
   flex-wrap: wrap;
   margin-left: 0 !important;
   margin-right: 0 !important;
 }

 .demo {
   /* padding-left: 5px; */
   font-size: 30px;

 }

 .col6 {
   flex: 0 0 auto;
   width: 50%;
 }

 .order {
   flex: 0 0 auto;
   width: 41.66666667%;
   margin-bottom: 8px;
 }

 .col12 {
   flex: 0 0 auto;
   width: 100%;
 }

 .col3 {

   flex: 0 0 auto;
   width: 25%;

 }

 .col9 {
   flex: 0 0 auto;
   width: 75%;
 }

 .kyc_input {
   box-sizing: border-box;
   background-color: transparent;
   width: 100%;
   border: 1.5px solid white;
   padding: 5px;
   color: #ffffff;
   outline: none;
   font-size: 18px;
 }

 .kyc_input_select {
   background-color: transparent;
   width: 82%;
   border: 1.5px solid white;
   padding: 10px;
   color: #ffffff;
   outline: none;
   text-align: left;
 }

 .kyc_toggle_btn {
   width: 60px;
   height: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 24px;
   text-align: center;
   border: 1px solid;
   border-radius: 25px;
   margin-top: 46px;
   padding: 3px;
   font-size: 16px;
 }

 .kyc_toggle_btn_profile_img {
   flex: 0 0 auto;
   display: flex;
   text-align: center;
   padding: 1px;
   width: 60px;
   margin: auto;
   border: 1px solid;
   border-radius: 25px;
   justify-content: center;
   height: 28px;
   margin-top: 10px;
 }

 .mr_40 {
   margin-right: 40px;
 }

 .radio_Btn {
   width: 25px;
   height: 25px;
 }

 .toggle_btn {
   margin-left: 12px;
   margin-top: 37px;
   padding: 1px;
   width: 60px;
   border: 1px solid;
   border-radius: 25px;
   justify-content: center;
   height: 28px;
 }

 .toggle_btn_active {
   background: #1FFC33;
   width: 50%;
   border-radius: 25px;
 }

 .toggle_btn_deactive {
   background: #ED4F4F;
   width: 50%;
   border-radius: 25px;
 }

 .d_none {
   display: none;
 }

 .justify_space_between {
   justify-content: space-between;
 }

 .min_height_200 {
   min-height: 200px;
 }

 .kyc_w_50_mouse {
   cursor: pointer;
   width: 50%;
 }

 .kyc_dFlex_justifyCenter {
   display: flex;
   justify-content: center;
 }

 .kyc_col_2_text_center {
   text-align: center;
   flex: 0 0 auto;
   width: 16.66666667%;
   padding-top: 44px;
 }

 .productName_center {
   text-align: center;
   margin-top: 4px;
 }

 .kyc_upload_aadhaarImg_heading {
   background-color: #282c34;
   height: 200px;
   max-width: 164px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 18px;
   min-height: 200px;
   border: 2.2439px dashed #FFFFFF;
   cursor: pointer;
 }

 .kyc_upload_aadhaar_image {
   border: 2.2439px dashed #FFFFFF;
   width: 130px;
   height: 180px;
   max-height: 180px;
   margin-top: 30px;
   padding: 10px 25px 10px 25px;
 }

 .kyc_row_fs_Kyc20 {
   display: flex;
   flex-wrap: wrap;
   font-size: 20px;
   margin-bottom: 20px;
 }

 .kyc_col_2_item_Center {
   align-items: center;
   text-align: center;
   flex: 0 0 auto;
   width: 16.66666667%;
 }

 .kyc_radioBtn_div {
   display: flex;
   flex-wrap: wrap;
   margin-top: 35px;
 }

 .kyc_radioBtn_col_3 {
   flex: 0 0 auto;
   width: 25%;
   align-items: center;
   display: flex;
 }

 .kyc_mouse_radio_Btn {
   width: 25px;
   height: 25px;
   cursor: pointer;
 }

 .kyc_radioBtn_col_4 {
   flex: 0 0 auto;
   width: 33.33333333%;
   align-items: center;
   display: flex;
 }

 .kyc_col_9 {
   flex: 0 0 auto;
   width: 75%;
   align-items: center;
   display: flex;
 }

 .surface {
   margin-top: 19px;
 }

 .text_end {
   text-align: end;
 }

 .darkBtn {
   border: 1px solid #BABABA;
   background-color: transparent;
   color: #BABABA;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .successBtn {
   width: 155px;
   background-color: transparent;
   border: 1px solid #1FFC33;
   border-radius: 5px;
   padding: 10px 0;
   color: #1FFC33;
   font-size: 13px;
   cursor: pointer;
 }

 .greenBtn {
   border: 1px solid #1FFC33;
   background-color: #1FFC33;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .dangerBtn {
   border: 1px solid #F93E3E;
   background-color: transparent;
   color: #F93E3E;
   width: 155px;
   padding: 10px 0;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .uploadLabel {
   border: 1px solid #1FFC33;
   width: 155px;
   padding: 10px 2px;
   text-align: center;
   border-radius: 5px;
   cursor: pointer;
 }

 .enterUrlLabel {
   border: 1px solid #F3F3F3;
   width: 200px;
   padding: 13px;
   border-radius: 5px;
   cursor: pointer;
 }

 .trashBin {
   background: #1E2022;
   box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
   text-align: center;
   padding: 3px;
   border-radius: 6px;
   color: #F93E3E;
   cursor: pointer;
 }

 .caretDown {
   background: #1E2022;
   box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
   text-align: center;
   padding: 3px;
   margin-left: 20px;
   border-radius: 10px;
   color: #D0D0D0;
   cursor: pointer;
   cursor: pointer;
 }

 .col5 {
   flex: 0 0 auto;
   width: 41.66666667%;
 }

 .margin_LR {
   margin: 0 10px;
 }

 .dflex_space_between {
   display: flex;
   justify-content: space-between;
   /* margin-bottom: 10px; */
 }

 .map_div {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   margin-top: 10px;
 }

 .map_seacrh_div {
   width: 100%;
   position: relative;
 }

 .seacrh_inputField {
   box-sizing: border-box;
   width: 100%;
   display: flex;
   height: 40px;
   background-color: transparent;
   border: 1px solid #FFFFFF;
   color: #FFFFFF;
   padding: 0 4px;
   font-size: 15px;
   outline: none;
 }

 .seacrh_inputField1 {
   width: 100%;
   display: flex;
   height: 40px;
   padding: 0 10px;
   outline: none;
   border-radius: 4px;
   font-size: 12px;
 }

 .seacrh_inputField1::placeholder {
   font-size: 12px;
 }

 .search_Icon {
   position: absolute;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   padding-right: 1%;
   cursor: pointer;
 }

 .google_map {
   border: 0;
   width: 100%;
   height: 400px;
   margin-top: 10px;
 }

 .accept_modal {
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 1;
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   overflow: auto;
   padding-top: 360px;
 }

 .accept_modal_content {
   width: 90%;
   max-width: 500px;
   background: #1E2022;
   box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
   border-radius: 10px;
   margin: auto;
   padding-top: 2px;
   color: #FFFFFF;
 }

 .close_btn {
   color: #aaaaaa;
   float: right;
   font-size: 25px;
   font-weight: bold;
   line-height: 1.5rem;
   padding-right: 6px;
 }

 .message {
   text-align: center;
   margin: 30px 0;
   font-size: 24px;
 }

 .massageModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: auto;
   padding-bottom: 30px;
 }

 .goBack {
   cursor: pointer;
   border-bottom: 1px solid #FFFFFF;
   /* text-decoration-line:  underline; */
 }

 .searchPlace_div {
   flex: 0 0 auto;
   width: 58.3%;
 }

 .map_heading_div {
   display: flex;
   /* justify-content: space-around; */
   flex-direction: column;
   width: 100%;
 }

 .map_txt {
   width: 20%;
   padding-bottom: 6px;
 }

 .OfficeUse_col {
   flex: 0 0 auto;
   width: 33.33%;
 }

 .leadModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: auto;
   padding-bottom: 30px;
   margin-top: 70px;
 }

 .rejectionList_div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #FFFFFF;
   padding: 6px 0 4px 0;
 }

 .rejectionList {
   min-width: 104px;
   width: 104px;
   max-width: 104px;
 }

 .rejectionList_text {
   width: 50%;
   /* text-align: center; */
 }

 .rejectionList_checkbox {
   /* width: 40px; */
   margin-right: 4px;
 }

 .rejectCheckbox {
   cursor: pointer;
   width: 20px;
   height: 20px;
 }

 .rejectListModal_btn {
   display: flex;
   justify-content: space-between;
   width: 400px;
   margin: 16px auto;
 }

 .input_field_alert {
   color: red;
 }

 .profileImage_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed red;
   height: 300px;
   margin-right: 10px;
 }

 .profile_bg_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 80%;
   height: 80%;
   font-size: 14px;
   color: red;
 }

 .storeImage_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2.2439px dashed red;
   width: 100%;
   height: 300px;
 }

 .store_bg_alert {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #282c34;
   width: 90%;
   height: 80%;
   font-size: 28px;
   color: red;
 }

 .rowOffice {
   display: flex;
   align-items: center;
   /* justify-content: space-between; */
   flex-wrap: wrap;
 }

 .rowOffice .col3 {
   width: 33.333333%;
   margin-bottom: 20px;
 }

 .dfac {
   display: flex;
   align-items: center;
 }

 .dfac label {
   display: flex;
   align-items: center;
   margin-right: 20px;
 }

 .profile_Image_div {
   width: 100%;
   height: 100%;
 }

 .plus_product {
   font-weight: bolder;
   font-size: 14px;
   width: 20px;
 }

 @media (max-width:2560px) {
   .profileImage {
     width: 280px;
     height: 320px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }


 }

 @media (max-width: 1500px) {
   .profileImage {
     width: 180px;
     height: 220px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }

   .profileImage_alert {
     width: 180px;
     height: 220px;
   }

   .storeImage_alert {
     width: 600px;
     height: 250px;
   }
 }

 @media (max-width: 1200px) {
   .profileImage {
     width: 180px;
     height: 200px;
   }

   .storeImage {
     width: 100%;
     height: 320px;
   }

   .profileImage_alert {
     width: 180px;
     height: 200px;
   }

   .storeImage_alert {
     width: 600px;
     height: 200px;
   }
 }

 @media only screen and (max-width: 1595px) {
   .Kyc_Mrgn {
     margin-left: 15px;
     margin-right: 15px;
   }

   .col_10 {
     flex: 0 0 auto;
     width: 75.33333333%;
   }


 }

 @media only screen and (max-width: 1350px) {
   .row_fs_Kyc20_align_items_center {
     font-size: 18px;
   }

   .profileImgHW {
     width: 200px;
     height: 200px;
   }

   .storeImgHW {
     width: 600px;
     height: 300px;
   }

   .qr_HW {
     width: 145px;
     height: 145px;
   }

   .storeImgHW {
     width: 635px;
     height: 250px;
   }

   .Kyc_Mrgn {
     margin-left: 15px;
     margin-right: 15px;
   }

   .productBtn {
     font-size: 12px;
     margin-bottom: 10px;
   }

   .productDisableBtn {
     font-size: 12px;
     margin-bottom: 10px;
   }
 }

 @media only screen and (max-width: 1220px) {
   .Kyc_Mrgn {
     margin-left: 10px;
     margin-right: 10px;
   }

   /* .productBtn {
    width: 90px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  } */

   /* .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 90px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  } */
 }

 @media only screen and (max-width: 1140px) {
   .storeImgHW {
     width: 535px;
     height: 250px;
   }

   .Kyc_Mrgn {
     margin-left: 7px;
     margin-right: 7px;
   }

   .col_10 {
     flex: 0 0 auto;
     width: 70.33333333%;
   }

   .qr_HW {
     width: 135px;
     height: 135px;
   }

   .productBtn {
     /* width: 85px; */
     font-size: 12px;
     cursor: pointer;
   }

   .productDisableBtn {
     font-size: 12px;
   }
 }

 /* -------------------------------------------------------------New-KYC------------------------------------------------------------------------ */

 .revertBack_btn {
   font-size: 16px;
   margin: 0 10px;
 }

 .kyc_btn_div {
   padding-top: 10px;
 }

 .billingDetails_heading {
   font-weight: 400;
   font-size: 24px;
   margin: 16px 0;
 }

 .profileImage_div {
   width: 280px;
 }

 .billingDetails_div {
   display: flex;
 }

 .accountSetting {
   background-color: rgba(27, 28, 29, 1);
   padding: 0 32px;
 }


 .mapHeading_txt {
   width: 100%;
   padding-bottom: 6px;
 }

 .accountSetting_div {
   display: flex;
   width: 100%;
   padding-bottom: 20px;
 }

 .accountSetting_left {
   width: 40%;
   display: flex;
   flex-direction: column;
 }

 .accountSetting_right {
   width: 60%;
   display: flex;
   flex-direction: column;
 }

 .Catalogue_ml {
   margin-bottom: 26px;
   margin-left: 20%;
 }

 .kyc_div_height {
   width: 100%;
   overflow-y: scroll;
   max-height: calc(100vh - 150px);
   min-height: calc(100vh - 150px);
 }

 .kyc_div_height::-webkit-scrollbar {
   display: none;
 }



















 /* new css for kyc */
 .main {
   width: 100%;
   height: calc(100vh - 65px);
   padding: 10px;
 }

 .continer {
   width: 100%;
   padding: 10px;
   height: calc(100vh - 75px);
   overflow-y: scroll;
 }

 .go_back {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .Personal_Info {
   width: 100%;
   margin-top: 10px;
   display: flex;
   gap: 10px;
 }

 .Personal {
   width: 30%;
   padding: 20px;
   border-radius: 10px;
 }

 .Company {
   width: 70%;
   padding: 20px;
   border-radius: 10px;
   display: flex;
   gap: 20px;
 }

 .Company_FristChile {
   width: 100%;
 }

 .Company_SeacandChile {
   width: 100%;
 }

 .profileContainer {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
 }

 .profileContainer p {
   font-weight: 600;
   line-height: 1;
 }

 .imageWrapper {
   position: relative;
   width: 100px;
   height: 100px;
   border-radius: 50%;
   overflow: hidden;
 }

 .profileImage {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 50%;
 }

 .fileInput {
   position: absolute;
   top: 0;
   width: 100px;
   height: 100px;
   cursor: pointer;
   z-index: 99;
 }

 .selectPik {
   width: 100px;
   border: 1px solid;
   z-index: 999;
 }

 .nm_photo {
   position: relative;
   cursor: pointer;
   width: 100px;
   height: 100px;
   margin-right: auto;
   border-radius: 50%;
 }

 .nm_photo img {
   height: 100%;
   width: 100%;
   border-radius: 50%;
   object-fit: cover;
 }

 .nm_camera_icon {
   position: absolute;
   top: 50%;
   right: -15%;
 }

 .nm_info {
   width: 100%;
   margin-top: 10px;
 }

 .Company_SeacandChile p {
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
 }

 .Drag_drop {
   width: 100%;
   border: 1px dashed #D9D9D9;
   height: 140px;
   border-radius: 10px;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   gap: 10px;
   position: relative;
 }

 .aadherCard {
   position: absolute;
   top: 10px;
   left: 10px;
   font-size: 12px;
   font-weight: 600;

 }

 .aadherCard1 {
   position: absolute;
   top: 5px;
   right: 10px;
   font-size: 14px;
   font-weight: 600;
   cursor: pointer;
   color: red;
 }

 .textaline {
   text-align: center;
   color: #9C9797;
 }

 .textaline p {
   line-height: 0.5;
   font-size: 14px;
   font-weight: 600;
 }

 .textaline1 {
   text-align: center;
   color: #D9D9D9;
 }

 .textaline1 p {
   line-height: 0.5;
   font-size: 12px;
   font-weight: 400;
 }

 .browseSpan {
   color: #749AFF;
   border-bottom: 1px solid;
   cursor: pointer;
 }

 .imagePreview {
   display: flex;
   justify-content: center;
   align-items: center;
   /* margin-top: 10px; */
   width: 100%;
 }

 .uploadedImage {
   max-width: 100%;
   max-height: 140px;
   object-fit: cover;
   /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
 }

 .imagePreview1 {
   display: flex;
   justify-content: center;
   align-items: center;
   /* margin-top: 10px; */
   width: 100%;
 }

 .uploadedImage1 {
   max-width: 100%;
   object-fit: cover;
   /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
 }

 .Location {
   width: 100%;
   display: flex;
   gap: 10px;
   margin-top: 10px;
 }

 .LocationAdress {
   width: 25%;
   border-radius: 8px;
   padding: 20px;
 }

 .LocationMap {
   width: 50%;
   border-radius: 8px;
   padding: 20px;
 }

 .Account_Setting {
   width: 25%;
   border-radius: 8px;
   padding: 20px;
 }

 .LocationAdress p {
   font-size: 14px;
   font-weight: 600;
 }

 .Location_map {
   width: 100%;
   /* height: calc(100% - 75px); */
   /* border: 1px solid; */
   /* margin-top: 10px; */
   border-radius: 8px;
 }

 .Account_Setting p {
   font-size: 14px;
   font-weight: 600;
   /* line-height: 1; */
 }

 .redioBtnConintner {
   display: flex;
   flex-direction: column;
   margin-top: 20px;
 }

 .redioBtnConintner2 {
   display: flex;
   gap: 20px;
 }

 .redioText {
   font-size: 13px;
   font-weight: 400;
 }

 .redio_Btn_Conintner_Status {
   margin-top: 20px;
 }

 .redio_Btn_Conintner_Status p {
   font-size: 14px;
   font-weight: 600;
   line-height: 1;
 }

 .status_redio_btn {
   width: 100%;
   /* border: 1px solid; */
   display: flex;
   gap: 30px;
   /* margin-left: -40px; */
 }

 .redioCon {
   width: 130px;
   /* border: 1px solid; */
 }

 .sosal_Media {
   display: flex;
   width: 100%;
   margin-top: 10px;
   /* align-items: center; */
   gap: 10px;
 }

 .sosale_media_link {
   width: calc(25% - 2px);
   border-radius: 8px;
   padding: 20px;
 }

 .sosale_media_About {
   width: 75%;
   border-radius: 8px;
   padding: 20px;

 }

 .sosale_media_About p {
   font-size: 14px;
   font-weight: 400;
 }

 .kycchack {
   padding: 10px;
   cursor: pointer;
   font-size: 14px;
   border-radius: 8px;
 }

 .KycChack_coontiner {
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
 }

 .KycChack_coontiner1 {
   display: flex;
   flex-direction: column;
   width: 100%;
 }

 .Description_textarea {
   width: 100%;
   outline: none;
   padding: 10px;
   font-size: 13px;
   font-weight: 400;
   border-radius: 8px;
 }

 .textarea_text {
   display: flex;
   justify-content: flex-end;
   margin-top: -5px;
   font-size: 12px;
   color: #F93E3E;
 }

 .upload_file {
   width: 100%;
   margin-top: 10px;
   border-radius: 8px;
   padding: 20px;
 }

 .upload_file_continer {
   border: 1px dashed #ccc;
   border-radius: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 10px;
   padding: 20px;
   font-size: 14px;
 }


 .dropdownContainer {
   position: relative;
   width: 100%;
 }

 .searchInput input {
   width: 100%;
   border-radius: 4px;
   outline: none;
   padding: 8px;
   font-size: 14px;
   font-weight: 400;
 }

 .dropdownList {
   position: absolute;
   top: 100%;
   left: 0;
   right: 0;
   max-height: 200px;
   overflow-y: auto;
   border-radius: 10px;
   z-index: 100;
 }

 .dropdownItem {
   cursor: pointer;
   transition: background-color 0.5s;
   padding: 5px 10px;
   font-size: 14px;
   font-weight: 400;
 }

 /* .dropdownItem:hover {
  background-color: var(--hover-bg, #f0f0f0);
  color: var(--hover-color, #000);
  cursor: pointer;
} */

 .selectedItem {
   background-color: #d0eaff;
 }

 .noItems {
   padding: 8px;
   text-align: center;
   color: #999;
 }

 .filter_cantiner {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 10px;
 }

 .select_dealer {
   display: flex;
   gap: 10px;
 }

 .select_dealerCont {
   display: flex;
   align-items: center;
   gap: 10px;
 }

 .select_dealerCont :first-child {
   font-size: 14px;
   font-weight: 400;
 }

 .Transfer_loction {
   display: flex;
   gap: 10px;
   align-items: center;
 }

 .main2 {
   width: 100%;
   height: calc(100vh - 65px);
   padding: 10px;
   position: relative;
 }

 .pageContiner {
   position: absolute;
   bottom: 0;
   width: calc(100% - 26px);
   display: flex;
   align-items: center;
   padding: 5px;
   margin: 3px;
   border-radius: 5px 5px 0px 0px;
   font-size: 12px;
   font-weight: 400;
 }




 .tableContiner {
   width: 100%;
   height: calc(100% - 150px);
   overflow: hidden;
   margin-top: 10px;
 }

 .scrolllist {
   height: calc(100% - 45px);
   overflow-y: scroll;
 }


 .tableContiner2 {
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .tableContinerTable {
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .tableContiner1 {
   width: 100%;
   height: 71.5%;
   /* overflow-y: scroll; */
   margin-top: 5px;
 }

 .responsiveTable {
   list-style-type: none;
   padding: 0;

   .tableHeader {
     font-size: 14px;
     text-transform: uppercase;
     letter-spacing: 0.03em;
     display: flex;
     border-radius: 3px;
     padding: 13px 9px;
     margin-bottom: 5px;
   }

   .tablerow {
     background-color: #ffffff;
     box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
     display: flex;
     border-radius: 3px;
     padding: 10px;
     margin-bottom: 5px;
     align-items: center;
     /* cursor: pointer; */
     position: relative;
     /* overflow-y: scroll */

   }

   .col {
     flex: 1;
     padding: 0;
     margin: 0;
     font-size: 12px;
     font-weight: 400;
     line-height: 1;
     align-items: center;
     display: flex;
     /* border: 1px solid; */
   }

   .col0 {
     flex: 1/4;
     padding: 0;
     margin: 0;
     font-size: 12px;
     font-weight: 400;
     line-height: 1;
     align-items: center;
     display: flex;
     /* border: 1px solid; */
   }

   .coljustyfy {
     flex: 1;
     /* align-items: center;
justify-content: center;
display: flex; */
   }

   .large {
     flex: 2;
     /* pehle box ko bada karne ke liye */
   }

   .uperCase {
     text-transform: uppercase;
   }

   @media all and (max-width: 700px) {
     .tableHeader {
       display: none;
     }

     .TableHeadChackBox {
       width: 20px;
       height: 20px;
       border-radius: 5px;
     }

     .tablerow {
       display: block;
       display: flex;
       flex-direction: column;
       width: 100%;
       justify-content: center;
       border-radius: 5px;
     }

     .col {
       /* display: flex; */
       /* justify-content: space-between; */
       /* padding: 10px 0; */
       display: flex;
       justify-content: space-between;
       padding: 10px 0;

       &:before {
         color: #6C7A89;
         padding-right: 10px;
         content: attr(data-label);
         flex-basis: 50%;
         text-align: left;
       }
     }
   }

 }



 .cl_checkbox {
   margin-left: 5px;
 }

 .cl_checkbox {
   position: relative;
   display: inline-block;
 }

 /* Input */
 .cl_checkbox>input {
   appearance: none;
   -moz-appearance: none;
   -webkit-appearance: none;
   z-index: -1;
   position: absolute;
   left: -10px;
   top: -8px;
   display: block;
   margin: 0;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   /* background-color: rgba(0, 0, 0, 0.6); */
   box-shadow: none;
   outline: none;
   opacity: 0;
   transform: scale(1);
   pointer-events: none;
   transition: opacity 0.5s, transform 0.2s;
 }

 /* Span */
 .cl_checkbox>span {
   display: inline-block;
   width: 100%;
   cursor: pointer;
 }

 /* Box */
 .cl_checkbox>span::before {
   content: "";
   display: inline-block;
   box-sizing: border-box;
   margin: 2px 11px 3px 0px;
   border: solid 2px;
   /* Safari */
   border-color: #0065FF;
   border-radius: 5px;
   width: 18px;
   height: 18px;
   vertical-align: top;
   transition: border-color 0.2s, background-color 0.2s;
 }

 /* Checkmark */
 .cl_checkbox>span::after {
   content: "";
   display: block;
   position: absolute;
   top: 3px;
   left: 1px;
   width: 10px;
   height: 5px;
   border: solid 2px transparent;
   border-right: none;
   border-top: none;
   transform: translate(3px, 4px) rotate(-45deg);
 }

 /* Checked, Indeterminate */
 .cl_checkbox>input:checked,
 .cl_checkbox>input:indeterminate {
   background-color: #0065FF;
 }

 .cl_checkbox>input:checked+span::before,
 .cl_checkbox>input:indeterminate+span::before {
   border-color: #0065FF;
   background-color: #0065FF;
 }

 .cl_checkbox>input:checked+span::after,
 .cl_checkbox>input:indeterminate+span::after {
   border-color: #fff;
 }

 .cl_checkbox>input:indeterminate+span::after {
   border-left: none;
   transform: translate(4px, 3px);
 }

 /* Hover, Focus */
 .cl_checkbox:hover>input {
   opacity: 0.04;
 }

 .cl_checkbox>input:focus {
   opacity: 0.12;
 }

 .cl_checkbox:hover>input:focus {
   opacity: 0.16;
 }

 /* Active */
 .cl_checkbox>input:active {
   opacity: 1;
   transform: scale(0);
   transition: transform 0s, opacity 0s;
 }

 .cl_checkbox>input:active+span::before {
   border-color: #0065FF;
 }

 .cl_checkbox>input:checked:active+span::before {
   border-color: transparent;
   background-color: rgba(0, 0, 0, 0.6);
 }

 /* Disabled */
 .cl_checkbox>input:disabled {
   opacity: 0;
 }

 .cl_checkbox>input:disabled+span {
   color: rgba(0, 0, 0, 0.38);
   cursor: initial;
 }

 .cl_checkbox>input:disabled+span::before {
   border-color: currentColor;
 }

 .cl_checkbox>input:checked:disabled+span::before,
 .cl_checkbox>input:indeterminate:disabled+span::before {
   border-color: transparent;
   background-color: currentColor;
 }

 .DrawerWrapperContinetr {
   width: 100%;
   padding: 20px;
   z-index: 9999;
   background: rgba(44, 46, 51, 0.85);
   /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
   backdrop-filter: blur(10.5px);
   -webkit-backdrop-filter: blur(10.5px);
   border-radius: 5px 5px 0px 0px;
   box-sizing: border-box;
   display: flex;
   justify-content: flex-end;
   gap: 10px;
   align-items: center;
 }

 .DrawerWrapperContinetr_light {
   width: 100%;
   padding: 20px;
   z-index: 9999;
   background: rgba(255, 255, 255, 0.7);
   /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
   backdrop-filter: blur(7px);
   -webkit-backdrop-filter: blur(7px);
   border-radius: 5px 5px 0px 0px;
   display: flex;
   box-sizing: border-box;
   justify-content: flex-end;
   gap: 10px;
   align-items: center;
 }

 .select_main_Manager {
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
   width: 185px;
   border-radius: 5px;
 }

 .select_abslut_manager {
   position: absolute;
   top: 100%;
   left: 0;
   max-height: 200px;
   border: 1px solid red;
   z-index: 999;
   padding: 8px;
   box-sizing: border-box;
 }

 .select_abslut_manager1 {
   position: absolute;
   top: 100%;
   left: 0;
   max-height: 250px;
   z-index: 999;
   padding: 5px;
   border-radius: 5px;

 }

 .select_box_input {
   width: 100%;
   outline: none;
   padding: 5px 8px;
   font-size: 18px;
   font-weight: 400;
   border-radius: 5px;
 }

 .select_box_input::placeholder {
   font-size: 12px;
 }

 .select_manager {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 5px;
   cursor: pointer;
   width: 100%;

 }

 .Select_scroll_con {
   max-height: 190px;
   overflow-y: scroll;
   display: flex;
   flex-direction: column;
   position: relative;
 }

 .select_manger_dark {
   width: 100%;
   padding: 5px 10px;
   font-size: 12px;
   border-bottom: 1px solid #000;
 }

 .select_manger_dark:hover {
   background-color: #1D1F23;
   cursor: pointer;
 }

 .select_manger_light {
   width: 100%;
   padding: 5px 10px;
   font-size: 12px;
   border-bottom: 1px solid #ccc;
 }

 .select_manger_light:hover {
   background-color: #ccc;
   cursor: pointer;
 }
 .bottomTrue{
  position: absolute;
  bottom: 0;
  border: 1px solid rebeccapurple;
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
 }
