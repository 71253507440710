     /* .right_main {
   width: 100%;
   background-color: transparent;
   color: #ffffff;
   padding: 0 15px;
   box-sizing: border-box;
   min-height: calc(100vh - 200px);
   border: 1px solid red;
   overflow-y: hidden;
 } */

     .right_main {
       width: 100%;
       background-color: transparent;
       color: #ffffff;
       padding: 10px 10px 0px 10px;
       box-sizing: border-box;
       height: calc(100vh - 65px);
       overflow-y: hidden;
       box-sizing: border-box;
     }

     .sacandMainCon {
       overflow: hidden;
       height: 100%;
       position: relative;
     }

     .right_main1 {
       width: 100%;
       background-color: transparent;
       color: #ffffff;
     }

     .addDealers {
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
       box-sizing: border-box;
     }

     .dealers {
       font-weight: 600;
       font-size: 18px;
       margin: 10px;
       color: #ffffff;
     }

     .dealers_white {
       font-weight: 600;
       font-size: 18px;
       /* margin: 10px; */
       color: #000000;
     }

     .Dealer_main_container {
       background-color: #2C2E33;
       /* border-radius: 12px; */
       display: block;
       /* padding-bottom: 12px; */
       /* border: 1px solid black; */
       /* height: 100%; */
       padding: 10px;
     }

     .Dealer_main_container_white {
       background-color: #F8F9FC;
       /* border-radius: 12px; */
       /* border: 1px solid black; */
       /* height: 100%; */
       padding: 10px;
     }

     .pageContiner {
       display: flex;
       align-self: center;
       align-items: center;
       position: absolute;
       position: absolute;
       bottom: 0;
       width: calc(100% - 6px);
       /* padding: 10px; */
       /* border: 1px solid; */
       padding: 5px;
       border-radius: 8px 8px 0px 0px;
       font-size: 12px;
       margin: 3px;
     }

     .dealer_header {
       display: flex;
       flex-wrap: wrap;
       padding: 0.5%;
       line-height: 1.5rem;
       justify-content: space-between;
       /* border: 1px solid black; */
     }

     .dealer_header_right {
       display: flex;
       flex-direction: row;
     }

     .dealer_search {
       border-radius: 50px;
       height: 49px;
       padding-left: 30px;
       padding-right: 10px;
       background-color: transparent;
       border: 1px solid white;
       color: #ffffff;
       outline-offset: 0px !important;
       outline: none;
       width: 100%;
       min-width: 222px;
     }

     .dealer_search_div {
       position: relative;
       /* width: 20%; */
     }

     .dealer_search_icon {
       position: absolute;
       left: 8px;
       top: 15px;
     }

     .dealer_Sort_By {
       padding-right: 30px;
     }

     .dealer_sortBy_txt {
       font-size: 20px;
       padding-right: 12px;
     }

     .dealer_sortBy_select {
       border: 1px solid #ffffff;
       height: 49px;
       width: 172px;
       background-color: transparent;
       color: #ffffff;
       outline: none;
       padding-left: 12px;
     }

     .dealer_sortBy_color {
       color: #000000;
       background-color: #ffffff !important;
     }

     .dealer_header_select_days {
       max-height: 49px;
       font-size: 13px;
       color: black;
       background-color: #fff;
       display: flex;
       flex-direction: row;
       border-radius: 25px;
       align-items: center;
       width: 420px;
       justify-content: center;
     }

     .dealer_days_select {
       width: 102px;
       text-align: center;
       border-radius: 25px;
       padding: 8px 0px;
       cursor: pointer;
     }

     .days_active {
       background-color: #E3E7EF;
       cursor: pointer;
       font-weight: bold;
     }

     .dealer_main_div {
       display: flex;
       flex-wrap: wrap;
       line-height: 1.5rem;
       max-height: calc(100vh - 280px);
       min-height: calc(100vh - 280px);
       overflow-y: scroll;
     }

     .dealer_main_div::-webkit-scrollbar {
       display: none;
     }

     .dealer_card {
       position: relative;
       width: 16.66666%;
     }

     .dealer_card_div {
       margin: 10px;
       padding: 10px;
       background-color: #000000;
       border-radius: 24px;
       text-align: center;
       min-height: 230px;
     }

     .dealer_image {
       width: 90px;
       height: 90px;
       border-radius: 50px;
       display: block;
       margin: auto;
       object-fit: cover;
     }

     .dealer_name {
       font-weight: 600;
       margin-top: 6px;
       margin-bottom: 0px;
     }

     .dealeStore_owner {

       font-weight: 400;
       margin-top: 0px;
       margin-bottom: 0px;
       font-size: 14px;
       color: gray;
       line-height: 20px;

       overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 1;
       -webkit-box-orient: vertical;
       min-height: 1.5em !important;
     }

     .dealer_btn_div {
       display: flex;
       justify-content: space-between;
       width: 64%;
       margin: auto;
       margin-top: 10px;
     }

     .dealer_Btn {
       background-color: #1E2022;
       display: flex;
       justify-content: center;
       align-items: center;
       height: 32px;
       width: 32px;
       border-radius: 4px;
       border: none;
       cursor: pointer;
     }

     .dealer_status {
       position: absolute;
       right: 0;
       display: flex;
       justify-content: center;
       align-items: center;
       height: 24px;
       padding-right: 4px;
       cursor: pointer;
     }

     .dealer_status_icon {
       padding-right: 12px;
     }

     .storeName_hide {
       visibility: hidden;
       min-height: 40px;
     }

     .name_char {
       width: 90px;
       height: 90px;
       border-radius: 50px;
       display: flex;
       margin: auto;
       object-fit: cover;
       border: 3px solid #FFFFFF;
       justify-content: center;
       align-items: center;
       font-size: 62px;
     }

     .name_char_mb {
       padding-bottom: 8px;
     }

     .newIcon_div {
       padding-right: 12px;
     }

     .newIcon {
       background-color: #009effed;
       color: #FFFFFF;
       width: 32px;
       height: 31px;
       border-radius: 30px;
       font-size: 12px;
       font-weight: 600;
       line-height: 29px;
     }

     .cursor_pointer {
       cursor: pointer;
     }

     .pagination_div {
       bottom: 0;
     }

     .addDealerButton {
       color: #FFFFFF;
       height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
     }

     @media (max-width:1680px) {
       .dealer_card {
         width: 20%;
       }
     }

     @media (max-width:1400px) {
       .dealer_card {
         width: 25%;
       }
     }

     @media (max-width:1200px) {
       .dealer_header_select_days {
         width: 296px;
       }

       .dealer_days_select {
         width: 72px;
       }

       .dealer_sortBy_select {
         width: 160px;
       }

       .dealer_sortBy_txt {
         font-size: 16px;
         padding-right: 8px;
       }

       .dealer_days_select {
         font-size: 12px;
       }
     }

     @media (max-width:1100px) {
       .dealer_card {
         width: 33.333%;
       }
     }

     .modalMain_heading {
       text-align: center;
       font-weight: 500;
       font-size: 32px;
       line-height: 38px;
       color: #FFFFFF;
       font-weight: 400;
       margin-bottom: 0;
       margin-top: 0px;
     }

     .nameAndDesignation {
       display: flex;
       flex-direction: row;
       width: 85%;
       margin: auto;
       margin-top: 20px;
     }

     .inputFieldName {
       width: 100%;
     }

     .inputFields_heading {
       font-style: normal;
       font-weight: 400;
       font-size: 16px;
       line-height: 30px;
       color: #FFFFFF;
     }

     .inputField_name {
       box-sizing: border-box;
       background-color: transparent;
       border: 0.5px solid #F3F3F3;
       border-radius: 3px;
       color: #FFFFFF;
       line-height: 36px;
       font-size: 15px;
       font-weight: 100 !important;
       min-height: 40px;
       padding: 0 8px;
       outline: none;
       width: 100%;
     }

     .DesignationFields_div {
       margin-left: 10px;
       width: 100%;
     }

     .inputField_designation {
       box-sizing: border-box;
       background-color: transparent;
       border: 0.5px solid #F3F3F3;
       border-radius: 3px;
       color: #FFFFFF;
       line-height: 36px;
       font-size: 15px;
       font-weight: 100 !important;
       min-height: 40px;
       padding: 0 8px;
       outline: none;
       width: 100%;
     }

     .inputFields_div {
       width: 85%;
       display: flex;
       flex-direction: column;
       margin: auto;
       margin-top: 20px;
     }

     .modalButton_div {
       width: 372px;
       margin: auto;
       text-align: center;
       display: flex;
       justify-content: space-around;
       margin-top: 28px;
     }

     .message {
       font-size: 16px;
     }

     .massageModal_btn {
       display: flex;
       width: 100%;
       /* border: 1px solid; */
       gap: 18px;
       margin-top: 20px;
     }

     .managerName {
       margin: 14px 0;
     }

     .whatsappMessenger {
       border: 1px solid #FFFFFF;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-right: 20px;
       padding: 0 16px;
       border-radius: 6px;
       cursor: pointer;
     }

     .choose_template {
       border: 1px solid #FFFFFF;
       margin: 24px auto;
       width: 72%;
       padding: 8px;
       position: relative;
       cursor: pointer;
     }

     /* .choose_template select {
  background-color: #1E2022; 
} */

     .choose_template select option {
       background-color: #1E2022;
     }

     .all_contacts_div {
       display: flex;
       justify-content: space-between;
       width: 72%;
       margin: 24px auto;
     }

     .dealerNames_div {
       max-height: calc(100vh - 332px);
       overflow-y: scroll;
     }

     .dealerNames_div::-webkit-scrollbar {
       display: none;
     }

     .dealerNames {
       display: flex;
       justify-content: space-between;
       padding: 0 10px 0 4px;
     }

     .dealerNameModal_btn {
       display: flex;
       justify-content: center;
       width: 400px;
       margin: auto;
       margin-top: 20px;
     }

     .modal_close_button {
       font-size: 2rem;
       font-weight: 700;
       color: #FFFFFF;
       cursor: pointer;
       border: none;
       background: transparent;
       line-height: 24px;
     }

     .template_name {
       line-height: 24px;
       margin-bottom: 20px;
     }

     .success_color {
       color: #0fff01;
       font-weight: bold;
     }

     .input_delete {
       background: transparent;
       border: 1px solid #fff;
       height: 40px;
       border-radius: 4px;
       margin-bottom: 20px;
       display: flex;
       margin: auto;
       margin-bottom: 32px;
       color: #fff;
       font-size: 20px;
       max-width: 90px;
       line-height: 20px;
       text-align: center;
     }

     .optionPosion {
       position: absolute;
       top: 40px;
       left: 0px;
       width: 100%;
       border: 1px solid rgb(145, 143, 143);
       background-color: #000000;
       color: white;
       height: 200px;
       overflow: hidden;
       overflow-y: scroll;
       padding: 10px;
       /* box-shadow: 3px 11px 23px -4px rgba(107,101,107,1); */
     }

     /* Assuming you have a CSS module file named `styles.module.css` */
     .optionP {
       padding: 2px 6px;
       cursor: pointer;
       border-radius: 6px;
       background-color: transparent;
       color: black;
       /* default color */
       transition: background-color 0.2s, color 0.2s;
       /* smooth transition */
       color: white;
       line-height: 20px;
     }

     .optionP:hover {
       background-color: rgb(59, 59, 241);
       color: white;
     }

     .text_center {
       height: auto;
       width: 500px;
       border-radius: 8px;
       padding: 18px;
     }

     .btnn {
       width: 43%;
       outline: none;
       border: none;
       background: white;

     }

     .loaderContainer {
       display: flex;
       justify-content: center;
       align-items: center;
       height: 2vh;
       /* Optional to center vertically */
       color: white;
       background-color: rgb(0, 0, 0);
     }

     .loaderSpan {
       width: 20px;
       height: 20px;
       border-radius: 50%;
       background-color: #ffffff;
       animation: bounce 0.6s infinite alternate;
       color: white;
     }

     @keyframes bounce {
       0% {
         transform: translateY(0);
       }

       100% {
         transform: translateY(-20px);
       }
     }





     /* new css by mohit */
     /* .btnAdddelaer {
  min-width: 120px;
  width: 153px;
  height: 44px;
  border: none;
  border-radius: 5px;
  background: #4164E3;
  color: #ffffff;
} */
     .btnAdddelaer {
       min-width: 120px;
       max-width: 100%;
       width: 100%;
       height: 44px;
       border: none;
       border-radius: 5px;
       background: #4164E3;
       color: #ffffff;
       box-sizing: border-box;
     }

     .btnAdddelaer1 {
       max-width: 153px;
       width: 153px;
       height: 44px;
       border: 0.5px solid #E4E7EB;
       border-radius: 5px;
       background: #ffffff;
       color: #646B88;
       display: flex;
       gap: 10px;
       justify-content: center;
       align-items: center;
     }

     .btnContiner {
       display: flex;
       justify-content: center;
       align-items: center;
       gap: 10px;
       /* margin-right: 10px; */
       /* margin-top: 6px; */
       /* width: 100%; */
       /* border: 1px solid #ccc;; */
     }

     .mdScreenicon {
       width: 100%;
       /* border: 1px solid; */
       display: flex;
       justify-content: center;
       align-items: center;
       gap: 6px;
       margin: 0px 10px;

     }

     .test_top {
       display: flex;
       align-items: center;
       justify-content: space-between;
       gap: 10px;
     }

     /* .test_top {
    display: flex;
    align-items: center;
    gap: 10px;
  } */

     .test_top p {
       margin: 0;
     }

     .test_middle {
       background-color: #fff;
       border-radius: 10px;
       display: flex;
       align-items: center;
       position: relative;
       /* height: 140px; */
       overflow: hidden;
       width: 100%;
       padding: 10px;
       /* border: 1px solid; */
     }

     /* 

.test_bottom_bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
  max-height: 460px;
  width: 100%;
  justify-content: center;
}

.test_bottom_bottom::-webkit-scrollbar {
  width: 8px;
}
.test_bottom {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #e4e7eb;
  margin: 0px 10px 10px 10px;
  height: 62vh;
}
.test_bottom_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.test_bottom_top_right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
} */


     /* Container with the dealer info cards */
     .test_bottom_bottom1 {
       display: flex;
       flex-wrap: wrap;
       align-items: center;
       overflow-y: auto;
       max-height: 480px;
       width: 100%;
       justify-content: flex-start;
       gap: 12px;
       -ms-overflow-style: none;
     }

     .test_bottom_bottom {
       display: grid;
       grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
       gap: 8px;
       overflow-y: scroll;
       width: 100%;
       position: relative;
     }

     .mainIS {
       height: calc(100vh - 400px);
       width: 100%;
       overflow-y: scroll;
     }

     .mgx_manager_card {
       box-sizing: border-box;
     }

     /* .receipts_filter {
      border-radius: 15px;
      border: 1px solid #fff;
      cursor: pointer;
    }

    .receipts_filter_dark {
      border: 1px solid #1B1D21;
    }

    .receipts_filter_active {
      border-color: #4164E3;
    } */
     .receipts_filter {
       border-radius: 15px;
       border: 1px solid #fff;
       cursor: pointer;
       transition: border-color 0.3s ease;
     }

     .receipts_filter:hover {
       border-color: #A7A7A7;
       /* Change to desired hover color */
     }

     .receipts_filter:active {
       border-color: #A7A7A7;
       /* Active color */
     }

     .receipts_filter_dark {
       border: 1px solid #1B1D21;
     }

     .receipts_filter_dark:hover {
       border-color: #656565;
       /* Adjust hover color for dark mode */
     }

     .receipts_filter_active {
       border-color: #656565;
     }

     .test_bottom_bottom::-webkit-scrollbar {
       display: none;
     }

     .test_bottom_bottom::-webkit-scrollbar-thumb {
       background-color: #c21313;
       /* Color of the scrollbar thumb */
       border-radius: 4px;
       /* Round the corners of the scrollbar thumb */
     }

     .test_bottom_bottom::-webkit-scrollbar-thumb:hover {
       background-color: #350909;
       /* Color of the scrollbar thumb on hover */
     }

     .test_bottom1 {
       background-color: #F8F9FC;
       border-radius: 15px;
       display: flex;
       align-items: center;
       flex-direction: column;
       gap: 1rem;
       padding: 1rem;
       margin: 0px 10px 0px 10px;
       height: calc(100vh - 6vh - 10px - 1rem - 6px - 5vh - 70px);
       overflow-y: auto;
     }


     .test_bottom {
       background-color: #F8F9FC;
       /* border-radius: 15px; */
       display: flex;
       align-items: center;
       flex-direction: column;
       gap: 1rem;
       margin-top: 10px;
       /* background-color: #0fff01; */
       /* border: 1px solid; */
     }


     /* Top section styling */
     .test_bottom_top {
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: space-between;
     }

     /* Styling for the dropdowns and search bar container */
     .test_bottom_top_right {
       width: 70%;
       display: flex;
       align-items: center;
       justify-content: flex-end;
       gap: 10px;
       /* border: 1px solid rebeccapurple; */
     }

     .test_bottom_top_right1 {
       width: 90%;
       display: flex;
       align-items: center;
       justify-content: center;
       gap: 5px;
       align-self: center;

     }

     .arroIcon {
       min-width: 40px;
       min-height: 40px;
       /* border: 1px solid rgb(80, 79, 82); */
       width: 20%;
       height: 100%;
       padding: 5px;
     }

     @media (max-width: 700px) {
       .right_main {
         display: none;
       }

       .md_Main_continer {
         display: block;
         width: 100%;
         display: flex;
         flex-direction: column;
         background-color: #000;
       }

       .md_Main_continer_dark {
         display: block;
         width: 100%;
         display: flex;
         flex-direction: column;
         background-color: #F8F9FC;
         /* border: 1px solid; */

       }

     }

     @media (min-width: 700px) {
       .right_main {
         display: block;
       }

       .md_Main_continer {
         display: none;
         background-color: white;
         width: 100%;
       }

       .md_Main_continer_dark {
         display: none;
         width: 100%;
         border: 1px solid;
       }

     }

     .md_filterCard {
       min-width: 106px;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #1B1D21;
       border-radius: 10px;
       /* flex-wrap: wrap; */
       padding: 10px 5px;
     }

     .md_filterCardDark {
       min-width: 106px;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #ffffff;
       border-radius: 10px;
       /* flex-wrap: wrap; */
       padding: 10px 5px;
     }

     .md_filterIcon {
       width: 10vw;
       height: 10vw;
       max-width: 50px;
       max-height: 50px;
       border-radius: 50%;
       background-color: #F8F9FC;
       display: flex;
       align-items: center;
       justify-content: center;
       padding: 4px;
     }

     .md_filterIcon_dark {
       width: 10vw;
       height: 10vw;
       max-width: 50px;
       max-height: 50px;
       border-radius: 50%;
       background-color: rgb(0, 0, 0);
       display: flex;
       align-items: center;
       justify-content: center;
       padding: 4px;
     }


     .md_Filtertext {
       display: flex;
       flex-direction: column;
       width: 60%;
       /* border: 1px solid; */
       justify-content: center;
       align-items: center;
     }

     .md_textFiltername {
       font-size: 0.8rem;
       font-weight: 500;
       color: #646B88;
       margin-right: 10px;
     }

     .md_textFiltername_dark {
       font-size: 0.8rem;
       font-weight: 500;
       color: #dadada;
       margin-right: 0px;
     }

     .md_textFilternumber {
       font-size: 1rem;
       font-weight: 700;
       color: #646B88;
     }

     .md_textFilternumber_dark {
       font-size: 1rem;
       font-weight: 700;
       color: #dadada;
     }

     .Md_continer {
       display: flex;
       flex-wrap: wrap;
       gap: 6px;
       margin: 6px;
       background-color: #000;
     }

     .Md_continer_dark {
       display: flex;
       flex-wrap: wrap;
       gap: 6px;
       margin: 6px;
       background-color: #F8F9FC;
     }

     .SearchFilter {
       display: flex;
       flex-direction: column;
       width: 100%;
       padding: 6px;
     }

     .SearchCon {
       display: flex;
       flex-direction: row;
       width: 100%;
       gap: 10px;
       height: 43px;
     }

     .SearchInput {
       width: 50%;
     }

     .SearchInput input {
       width: 100%;
       height: 100%;
       border: 0.6px solid #E4E7EB;
       background-color: #E4E7EB;
       border-radius: 10px;
       padding-left: 10px;
       color: #000;
       outline: none;
       border: none;
     }

     .SearchInput_dark input {
       width: 100%;
       height: 100%;
       border: 0.6px solid #ffffff;
       background-color: #1B1D21;
       border-radius: 10px;
       padding-left: 10px;
       color: #f5f5f5;
       outline: none;
       border: none;
     }

     .inptage::placeholder {
       padding-left: 10px;
       color: rgb(3, 3, 3);
     }

     .inptage_dark::placeholder {
       padding-left: 10px;
       color: rgb(163, 163, 163);
     }

     .addbtnmd {
       width: 35%;
       background-color: #4164E3;
       font-size: 12px;
       font-weight: 600;
       color: #FFFFFF;
       border: none;
       outline: none;
       border-radius: 10px;
     }

     .md_card_Continer {
       display: flex;
       width: 100%;
       padding: 10px;
       flex-direction: column;
       gap: 10px;
     }

     .md_card_mainContiner {
       display: flex;
       width: 100%;
       border: 1px solid #ffffff;
       padding: 10px;
       background-color: #FFFFFF;
       border-radius: 15px;
       height: 141px;
     }

     .md_card_mainContiner_dark {
       display: flex;
       width: 100%;
       border: 1px solid #1B1D21;
       padding: 10px;
       background-color: #1B1D21;
       border-radius: 15px;
       height: 141px;
     }


     .md_imageCoit {
       width: 20%;
       /* border: 1px solid #000000;; */
     }

     .md_Image {
       width: 100%;
       object-fit: cover;
       border-radius: 50%;
     }

     .md_About_Continer {
       display: flex;
       flex-direction: column;
       /* position: relative; */
       width: 80%;
       /* border: 1px solid ; */
       margin-left: 20px;
     }

     .md_Nametext {
       font-size: 16px;
       font-weight: 600;
       color: #232529;
     }

     .md_Nametext_dark {
       font-size: 16px;
       font-weight: 600;
       color: #fff;
     }

     .md_text_Role {
       display: flex;
       flex-direction: column;
       /* margin-left: 20%; */
       width: 100%;
       /* border: 1px solid; */
     }

     /* .md_icon{
  border: 1px solid;
 } */
     .md_roleName {
       font-size: 14px;
       font-weight: 400;
       color: #646B88;
     }

     .md_storeName {
       font-size: 16px;
       font-weight: 600;
       color: #232529;
     }

     .md_roleName_dark {
       font-size: 14px;
       font-weight: 400;
       color: #dadada;
     }

     .md_storeName_dark {
       font-size: 16px;
       font-weight: 600;
       color: #dadada;
     }

     .md_dealer_Info_Card_Icons_Tray {
       display: flex;
       align-items: center;
       justify-content: flex-end;
       /* border: 1px solid ; */
       width: 100%;
       margin-top: 10px;
       margin-right: 10px;
       gap: 10px;
     }

     .md_dealer_Info_Card_Icons_Tray svg {
       cursor: pointer;
     }

     .filterContiner {
       width: 100%;
       display: flex;
       justify-content: space-between;
     }

     .filterserch {
       width: 20%;
     }

     .filterFix {
       width: 33%;
       background-color: yellow;
     }

     /* pagnative  */

     .pagination {
       display: flex;
       gap: 5px;
       margin-top: 20px;
     }

     .pageButton {
       padding: 5px 10px;
       border: 1px solid #ccc;
       background-color: white;
       cursor: pointer;
       transition: background-color 0.3s;
     }

     .pageButton:hover {
       background-color: #f0f0f0;
     }

     .activeButton {
       font-weight: bold;
       color: white;
       background-color: black;
     }

     .disabledButton {
       opacity: 0.5;
       pointer-events: none;
     }

     /* Custom Scrollbar Styles */
     .scrollable {
       width: 100%;
       display: flex;
       overflow-x: scroll;
       /* Custom scrollbar styles */
       /* scrollbar-width: thin; For Firefox */
       /* scrollbar-color: #ffffff transparent; For Firefox */
       -ms-overflow-style: none;
     }

     .scrollable::-webkit-scrollbar {
       /* height: 8px; Height of horizontal scrollbar */
       display: none;
     }

     /* .scrollable::-webkit-scrollbar-thumb {
  background-color: #007bff; 
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-track {
  background: transparent; 
} */











     /* =============== sent templet ==================== */
     .TempContiner {
       width: 23vw;
       min-width: 200px;
       /* border: 1px solid; */
       padding: 21px;
       box-sizing: border-box;
       border-radius: 6px;
     }

     .TempContiner1 {
       width: 100%;
       /* border: 1px solid; */
       display: flex;
       flex-direction: column;
       gap: 10px;
     }

     .inputContiner {
       width: 100%;
       border: 1px solid;
       align-self: center;
       height: 40px;
       border-radius: 4px;
       display: flex;
       justify-content: center;
       align-items: center;
       position: relative;
     }

     .inputContiner1 {
       cursor: pointer;
       width: 100%;
       justify-content: center;
       display: flex;
     }

     .totleContiner {
       width: 100%;
       margin-top: 20px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       margin: 0px 5px;
     }

     .AllContactes {
       display: flex;
       gap: 6px;
       margin: 10px 0px;
       justify-content: center;
       align-items: center;
     }

     .TempBtnContiner {
       width: 100%;
       display: flex;
       gap: 14px;
       margin: 8px 0px 20px 0px;
     }

     .TempContinerText {
       margin: 10px 0px;
       font-size: 18px;
       font-weight: 500;
     }

     .TempSelect {
       font-size: 17px;
       font-weight: 400;
     }

     .TempOptionContiner {
       width: 100%;
       border: 1px solid #ccc;
       position: absolute;
       top: 35px;
       /* height: 200px; */
       max-height: 200px;
       display: flex;
       flex-direction: column;
       z-index: 999;
       padding: 4px 10px;
       align-items: center;
       font-size: 14px;
       font-weight: 400;
       gap: 5px;
       overflow-y: auto;
     }

     .tempSingle {
       width: 100%;
       /* border: 1px solid; */
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 4px 0px;
       border-radius: 4px;
     }

     .tempSingle:hover {
       cursor: pointer;
     }

     .textContiner {
       width: 100%;
       /* border: 1px solid; */
       display: flex;
       flex-direction: column;
       line-height: 14px;
     }

     .AbortControllerCon {
       margin: 20px 0px;
     }

     .Spinner {
       width: 100%;
       z-index: 999;
       /* border: 1px solid #ccc; */
     }


     .no_Data_found {
       width: 100%;
       border: 1px solid;
     }
    




