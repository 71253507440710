    /* ProgressBar.module.css */
 .progressContainer {
     display: flex;
     align-items: center;
 }

 .progressBar {
     width: 200px;
     height: 8px;
     background-color: #e0e0e0;
     border-radius: 5px;
     position: relative;
     margin-right: 10px;
 }

 .progress {
     height: 100%;
     background-color: #5945ED;
     border-radius: 5px;
     position: relative;
 }

 .pointer {
     width: 16px;
     height: 16px;
     background-color: #5945ED;
     border-radius: 50%;
     border: 3px solid #5945ED;
     position: absolute;
     top: 50%;
     right: 0;
     transform: translate(50%, -50%);
 }

 .progressText {
     font-size: 14px;
     color: #333;
 }









 .accordion {
     width: 100%;
 }

 .accordionItem {
     height: auto;
     margin-top: 10px;
 }

 .accordionTrigger {
     cursor: pointer;
     transition: background-color 1s;
     width: 100%;
     position: relative;
 }

 .accordionTrigger:hover {
     background-color: unset;
 }

 .accordionContent {
     max-height: 0;
     overflow: hidden;
     transition: max-height 0.3s ease, padding 0.3s ease; 
    position: relative;
 }

 .accordionContent.active {
     background-color: #fafafa;
     transition: max-height 0.3s ease, padding 0.3s ease;
 }