.modal_backdrop {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background-color:transparent rgba(0, 0, 0, 0.4);
    z-index: 1090;
    height: 100%;
}
.modal_backdrop_height {
    position: fixed;
    /* top: 80px; */
    left: 0;
    right: 0;
    background-color:transparent rgba(0, 0, 0, 0.4);
    z-index: 1090;
    height: 100%;
}

.modal_content {
    border: 0.5px solid rgba(211, 211, 211, 0.7);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 95%;
    overflow-y: scroll;
    /* min-height: 200px; */
    background-color: #FFFFFF;
    color: #FFFFFF;
    margin: auto;

}

.modal_content::-webkit-scrollbar {
    display: none;
}

.packingImage_content {
    width: 90%;
    overflow-y: scroll;
    min-height: 150px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 10px 8px 24px 8px;
    margin-left: 66px;
}

.packingImage_content::-webkit-scrollbar {
    display: none;
}


.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal_header h3 {
    margin: 0;
}

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.main_search_div {
    position: relative;
}

.inputSearch {
    width: 100%;
    padding: 10px 10px 10px 30px;
    outline: none;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    /* position: relative;  */
}

.search_icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    width: 15px;
    height: 15px;
}

.main_search_div h3 {
    text-align: center;
    margin-top: 0;
}

.main_search_div p {
    text-align: center;
    color: darkgray;
}

.locations {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.locations h4 {
    margin: 0;
}

/* @media only screen and (max-width: 991px) {
    .modal_content {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .modal_content {
        width: 90%;
    }
}
*/

/* @media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 20px;
        position: absolute;
        bottom: 0;
    }
} */